import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";

import logo from "../../asset/IMG/logo.png";
import home from "../../asset/IMG/home2.png";
import dashboard from "../../asset/IMG/dashboard.png";
import wallet from "../../asset/IMG/wallet.png";
import history from "../../asset/IMG/history.png";
import supportCoin from "../../asset/IMG/support-coin.png";
import fees from "../../asset/IMG/fees.png";
import merchant from "../../asset/IMG/merchant.png";
import support from "../../asset/IMG/support.png";
import search from "../../asset/IMG/search.png";
import notification from "../../asset/IMG/notification.png";
import theme from "../../asset/IMG/theme-change.png";
import liteLogo from "../../asset/IMG/headerLogo.png";
import Offcanvas from "react-bootstrap/Offcanvas";

import "../../Styles/HeaderStyle.css";
import ForgotPassword from "../Pages/ForgotPassword";
import Register from "../Pages/Register";
import Login from "../Pages/Login";
import ResetPassword from '../Pages/ResetPassword'
import TwoFactor from "../Pages/TwoFactor";
import { AiOutlineClose } from "react-icons/ai";
import darkMode from "../../asset/IMG/dark_theme_change.png";
import menuImg from "../../asset/IMG/btn-icon.png";
import { useSelector } from "react-redux";
import { FRONTENDURL } from "../../config/env";
import { getuserdatabytoken, getuserdetailsbyname } from "../../hooks/UseUser";
import Accountmodal from "../Modals/accountmodal"

const MobileNavlinks = ({ darkTheme, setDarkTheme, setToggle, toggle }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [checkotp, setcheckotp] = useState("");
  const [resetPassword, setResetPassword] = useState(false)
  const [accountmodal, setAccountmodal] = useState(false);
  const [UserToken,setUserToken] = useState('')

  let location = useLocation()


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const otp = queryParams.get('code');
    console.log('queryParams', queryParams, otp, checkotp, checkotp == otp)
    getData()
    if (checkotp != null && checkotp != undefined) {
      if (checkotp == otp) {
        setResetPassword(true);
      } else {
        setResetPassword(false);
      }
    }
  }, [checkotp, resetPassword, !resetPassword]);

  const getData = async () => {
    try {
      var userdata = {}
      let pathname = location.pathname.split('/')[2];
      console.log("pathName", pathname)
      if (pathname != undefined) {
        userdata = await getuserdetailsbyname(pathname)
      }
      setcheckotp(userdata?.data?.record?.Otp)
    } catch (e) {
      console.log("getData_err", e)
    }
  }
  // const [themeset, assignThemeset] = useState("darktheme");

  // Search Bar Open

  const handleSearchOpen = () => {
    setSearchOpen(!searchOpen);
  };
  const handleResetClose = () => {
    setResetPassword(false)
  }
  const handleThemeChange = () => {
    // const themeValue = localStorage.getItem("theme");
    // setNavigations(false);

    setDarkTheme(!darkTheme);
    if (!darkTheme) {
      document.getElementsByTagName("body")[0].classList.remove("light_Theme");
      document.getElementsByTagName("body")[0].classList.add("dark_Theme");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("dark_Theme");
      document.getElementsByTagName("body")[0].classList.add("light_Theme");
    }

    setToggle(false);
    // if (themeValue === "darktheme" || themeValue === null) {
    //   localStorage.setItem("theme", "lighttheme");
    //   document.getElementsByTagName("body")[0].classList.remove("dark_Theme");
    //   document.getElementsByTagName("body")[0].classList.add("light_Theme");
    // } else {
    //   localStorage.setItem("theme", "darktheme");
    //   document.getElementsByTagName("body")[0].classList.remove("light_Theme");
    //   document.getElementsByTagName("body")[0].classList.add("dark_Theme");
    // }
  };

  const [register, setRegister] = useState(false);
  const [login, setLogin] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [twoFactor, setTwoFactor] = useState(false);
  const [navigations, setNavigations] = useState(true);

  // Register
  const handleRegisterClose = () => setRegister(false);
  const handleRegisterShow = () => {
    // setToggle(false);
    setLogin(false);
    setForgotPassword(false);
    setTwoFactor(false);
    setRegister(true);
  };
  //Login
  const handleLoginClose = () => setLogin(false);
  const handleLoginShow = () => {
    // setToggle(false);
    setNavigations(false);
    setForgotPassword(false);
    setRegister(false);
    setTwoFactor(false);
    setLogin(true);
  };

  //Forgot Password
  const handleForgotClose = () => setForgotPassword(false);
  const handleForgotShow = () => {
    setNavigations(false);

    setLogin(false);
    setRegister(false);
    setTwoFactor(false);
    setForgotPassword(true);
  };

  // Two Factor

  const handleTwoFactorClose = () => {
    setTwoFactor(false);
  };
  const handleTwoFactorShow = () => {
    setNavigations(false);
    setLogin(false);
    setRegister(false);
    setForgotPassword(false);
    setTwoFactor(true);
  };

  // const handleToggleChange = () => {
  //   console.log("initial", navigations);
  //   setNavigations(!navigations);
  //   console.log("after", navigations);
  // };

  // const handleNavigateChange = () => {
  //   console.log("navlinks", navigations);
  //   setNavigations(false);
  // };

  useEffect(() => {
    const windowWidth = document.body.clientWidth;

    if (windowWidth < 1200) {
      setNavigations(false);
    } else {
      setNavigations(true);
    }
  }, []);


  const UserLogout = async () => {
    try {
      sessionStorage.removeItem("signup");
      sessionStorage.removeItem("accessToken");
      window.location.href = FRONTENDURL;
    } catch (e) {
      console.log("UserLogout_err", e)
    }
  }


  useEffect(() => {

    let UserToken = sessionStorage.getItem("accessToken");
    console.log("hfhgdgfxgf",UserToken);
    setUserToken(UserToken);
    if (
      UserToken != "" &&
      UserToken != null &&
      UserToken != undefined &&
      UserToken != "undefined"
    ) {
      GetuserAccountType(UserToken);
    }
  }, [UserToken]);

const GetuserAccountType = async (token) => {
    try {
      let reqdata = { token: token, filter: "userAccountType" };
      let res = await getuserdatabytoken(reqdata);
      if (res?.data?.success == true) {
        let data = res?.data?.record;
      
       
        if(data?.isKYCVerify == null && data?.accounttype === "Merchant" && data?.KYCFile?.length < 0){
          setAccountmodal(true)
        }
      
      }
    } catch (e) {
      console.log("getData_err", e);
    }
  };

  const findWindowWidth = document.body.clientWidth;

  // const handleNavigationChange = () => {
  //   console.log("clickeddd");
  //   if (findWindowWidth < 1200) {
  //     setNavigations(true)
  //   }
  // };

  // useEffect(() => {
  //   // const setThemeInStorage = localStorage.geItem("theme", "");
  //   handleThemeChange();
  // }, [themeset]);

  // const handleThemeChange = () => {
  //   // setDarkTheme(!darkTheme);
  //   const storageTheme = localStorage.getItem("theme");

  //   if (storageTheme === "lighttheme") {
  //     document.getElementsByTagName("body")[0].classList.remove("dark_Theme");
  //     document.getElementsByTagName("body")[0].classList.add("light_Theme");
  //     localStorage.setItem("theme", "darktheme");
  //     assignThemeset("darktheme");
  //     // handleThemeChange();
  //   } else {
  //     document.getElementsByTagName("body")[0].classList.remove("dark_Theme");
  //     document.getElementsByTagName("body")[0].classList.add("light_Theme");
  //     localStorage.setItem("theme", "lighttheme");
  //     assignThemeset("lighttheme");

  //     // handleThemeChange();
  //   }

  //   console.log(storageTheme, "storageTheme1");

  //   console.log("Storage value from header.js", storageTheme);
  // };

  const handleToggleClose = () => {
    setToggle(false);
  };

  return (
    <>
      {/* New Header */}
      {accountmodal && <Accountmodal onDismiss={() => setAccountmodal(false)} />}
      <div className="mobile_navitems mt-3 mb-3 ">
        <div className="nav_links">
          <NavLink
            to="/dashboard"
            className="d-flex align-items-center "
            onClick={handleToggleClose}
          >
            {/* <img
              src={dashboard}
              alt="dashboard"
              className="img-fluid headerImg"
            /> */}
            <p className="headerP ">DASHBOARD</p>
          </NavLink>
          <NavLink
            to="/wallet"
            className="d-flex align-items-center"
            onClick={handleToggleClose}
          >
            {/* <img src={wallet} alt="dashboard" className="img-fluid headerImg" /> */}
            <p className="headerP">WALLET</p>
          </NavLink>
          <NavLink
            to="/history"
            className="d-flex align-items-center"
            onClick={handleToggleClose}
          >
            {/* <img src={history} alt="" className="img-fluid headerImg" /> */}
            <p className="headerP">HISTORY</p>
          </NavLink>
          <NavLink
            to="/support-coin "
            className="d-flex align-items-center"
            onClick={handleToggleClose}
          >
            {/* <img
              src={supportCoin}
              alt="supportCoin"
              className="img-fluid headerImg"
            /> */}
            <p className="headerP">SUPPORT COIN</p>
          </NavLink>
          <NavLink
            to="/fees"
            className="d-flex align-items-center "
            onClick={handleToggleClose}
          >
            {/* <img src={fees} alt="fees" className="img-fluid headerImg" /> */}
            <p className="headerP">FEES</p>
          </NavLink>
          <NavLink
            to="/merchant"
            className="d-flex align-items-center"
            onClick={handleToggleClose}
          >
            {/* <img
              src={merchant}
              alt="merchant"
              className="img-fluid headerImg"
            /> */}
            <p className="headerP">MERCHANT TOOLS</p>
          </NavLink>
          {/* <NavLink
            to="/"
            className="d-flex align-items-center support_right_border"
            onClick={handleToggleClose}
          >
            <p className="headerP">SUPPORT</p>
          </NavLink> */}
        </div>
        {/* <div className="mob_nav_search mt-3">
          {" "}
          <div className="search_paddings d-flex align-items-center">
            {searchOpen && (
              <div className="inputfield">
                <input type="text" placeholder="Search" />
              </div>
            )}
            <div className="header_searchIcon">
              <img src={search} alt="search" onClick={handleSearchOpen} />
            </div>
          </div>
        </div> */}
        <div className="mob_notication_theme">
          <div className="head_cred d-flex align-items-center gap-3">
            <div className="login_border">
              {(sessionStorage.getItem("accessToken") == null) && <button onClick={handleLoginShow}>Login</button>}
            </div>
            {(sessionStorage.getItem("signup") == null) && <div className="commonBtn">
              <button
                className="z-2"
                onClick={handleRegisterShow}
                style={{
                  cursor: "pointer",
                  textTransform: "none",
                  fontSize: "15px",
                  fontWeight: "700",
                }}
              >
                Sign Up
              </button>
            </div>}
          </div>
          {/* <div
            className="d-flex align-items-start gap-3 mt-3"
            style={{ height: "100%" }}
          >
            <div className="headerNotification">
              <img
                src={notification}
                alt="notification"
                className="headerImg img-fluid"
              />
            </div>
            <div
              className="headerRight header_themeChange"
              onClick={handleThemeChange}
            >
              <img
                src={darkTheme ? theme : darkMode}
                alt="themeChange"
                className="headerImg img-fluid"
              />
            </div>
          </div> */}
        </div>

        <div className="mob_notication_theme">
          <div className="head_cred d-flex align-items-center gap-3">
            <div className="login_border">
            </div>
            {(sessionStorage.getItem("accessToken") != null) && <div className="commonBtn">
              <button
                className="z-2"
                onClick={() => { UserLogout() }}
                style={{
                  cursor: "pointer",
                  textTransform: "none",
                  fontSize: "15px",
                  fontWeight: "700",
                }}
              >
                Log out
              </button>
            </div>}
          </div>
          {/* <div
            className="d-flex align-items-start gap-3 mt-3"
            style={{ height: "100%" }}
          >
            <div className="headerNotification">
              <img
                src={notification}
                alt="notification"
                className="headerImg img-fluid"
              />
            </div>
            <div
              className="headerRight header_themeChange"
              onClick={handleThemeChange}
            >
              <img
                src={darkTheme ? theme : darkMode}
                alt="themeChange"
                className="headerImg img-fluid"
              />
            </div>
          </div> */}
        </div>
      </div>

      <div className="offcanvas-section">
        {/* Register */}
        <Offcanvas
          show={register}
          // onHide={handleRegisterClose}
          placement="top"
          className="register-section canvas-section"
          style={{ minHeight: "450px", maxHeight: "450px" }}
        >
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body className="canvas-body">
            <button
              type="button"
              class="position-absolute canvas-closebtn close_btn_icon"
              aria-label="Close"
              onClick={handleRegisterClose}
            >
              <AiOutlineClose />
            </button>
            <Register onDismiss={() => handleRegisterClose()} />
          </Offcanvas.Body>
        </Offcanvas>
        {/* Login */}
        <Offcanvas
          show={login}
          // onHide={handleLoginClose}
          placement="top"
          className="login-section canvas-section"
        >
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body className="canvas-body">
            <button
              type="button"
              class=" position-absolute canvas-closebtn close_btn_icon"
              aria-label="Close"
              onClick={handleLoginClose}
            >
              <AiOutlineClose />
            </button>
            <Login
              handleForgotPassword={handleForgotShow}
              handleCreateAccount={handleRegisterShow}
              handleTwoFactorShow={handleTwoFactorShow}
              handleLoginClose={handleLoginClose}
            />
          </Offcanvas.Body>
        </Offcanvas>
        {/* Reset Password */}
        <Offcanvas
          show={resetPassword}
          // onHide={handleLoginClose}
          placement="top"
          className="login-section canvas-section"
        >
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body className="canvas-body">
            <button
              type="button"
              class=" position-absolute canvas-closebtn close_btn_icon"
              aria-label="Close"
              onClick={handleResetClose}
            >
              <AiOutlineClose />
            </button>
            <ResetPassword
              handleResetClose={handleResetClose}
              Otp={checkotp}
            />
          </Offcanvas.Body>
        </Offcanvas>
        {/* Two Factor */}
        <Offcanvas
          show={twoFactor}
          // onHide={handleLoginClose}
          placement="top"
          className="twoFactor-section canvas-section"
        >
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body className="canvas-body">
            <button
              type="button"
              class=" position-absolute canvas-closebtn close_btn_icon"
              aria-label="Close"
              onClick={handleTwoFactorClose}
            >
              <AiOutlineClose />
            </button>
            <TwoFactor onDismiss={() => handleTwoFactorClose()} onLogin={() => setLogin(true)} />
          </Offcanvas.Body>
        </Offcanvas>
        {/* Forgot Password */}
        <Offcanvas
          show={forgotPassword}
          // onHide={handleForgotClose}
          placement="top"
          className="forgot-password-section"
          style={{ minHeight: "315px" }}
        >
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body className="canvas-body">
            <button
              type="button"
              class="close_btn_icon position-absolute canvas-closebtn"
              aria-label="Close"
              onClick={handleForgotClose}
            >
              <AiOutlineClose />
            </button>
            <ForgotPassword />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default MobileNavlinks;
