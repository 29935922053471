import thunk from "redux-thunk";
import { SET_ACCOUNTTYPE,  SET_DASHBOARD_NAVIGATE, SET_SHOW_HEADER, SET_TWOFACTORS, SET_USERAPI_ACCESS, SET_USER_LOGIN_DETAILS} from "./action";
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';


const initialState = {
    userlogindetails: {},
    userapiaccessdata : {},
    usergetcurrentassetdata : false
};

const reducer = (state = initialState, action) => {
   

    switch (action.type) {
        case SET_USER_LOGIN_DETAILS: {
            return {
                ...state,
                userlogindetails: action.payload
            }
        } case SET_TWOFACTORS : {
            return {
                ...state,
                usertwofactor : action.payload
            }
        } case SET_ACCOUNTTYPE : {
            return {
                ...state,
                useraccounttype : action.payload
            }
        } case SET_DASHBOARD_NAVIGATE : {
            return {
                ...state,
                userdashboardnavigate : action.payload
            }
        } case SET_USERAPI_ACCESS : {
            return {
                ...state,
                userapiaccessdata : action.payload
            }
        } case SET_SHOW_HEADER : {
            return {
                ...state,
                usersetshowheader : action.payload
            }
        } 


        default:
            return state;

    }
}

const store = createStore(
    combineReducers({
        reducer         :   reducer
    }),applyMiddleware(thunk),
        
);

export default store;