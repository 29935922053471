import React from "react";
import logo from "../../asset/IMG/NewLanding/white_logo.png";

import Facebook from "../../asset/IMG/NewLanding/SocialIcons/Facebook.png";
import Instagram from "../../asset/IMG/NewLanding/SocialIcons/Instagram.png";
import LinkedIn from "../../asset/IMG/NewLanding/SocialIcons/LinkedIn.png";
import Pinterest from "../../asset/IMG/NewLanding/SocialIcons/Pinterest.png";
import Telegram from "../../asset/IMG/NewLanding/SocialIcons/Telegram.png";
import Twitter from "../../asset/IMG/NewLanding/SocialIcons/Twitter.png";
import Youtube from "../../asset/IMG/NewLanding/SocialIcons/Youtube.png";

const Footer = () => {
  return (
    // <div className="paddingCont py-5 footerContainer">
    //   <div className="container footerContent">
    //     <p>
    //       The standard chunk of Lorem Ipsum used since the 15OOs is reproduced
    //       below for those interested. Sections 1.10.32 and 1.10.33 from "de
    //       Finibus Bonorum et MalorumThe standard chunk of Lorem Ipsum used since
    //       the 1500s
    //     </p>
    //   </div>
    // </div>
    <>
      <div className="new_footer">
        <div className="container">
          <div className="row m-0">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mb-3 ">
              <div>
                <div className="d-flex align-items-center">
                  <img src={logo} alt="logo" />
                  <h5 className="ps-3">COINPAYMATE</h5>
                </div>
                <span className="description mt-4">
                  Take advantage of our global crypto payment gateway made easy
                  and accessible for everyone — whether you’re a business owner,
                  crypto user, or even from another planet.
                </span>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-3">
              <div>
                <h6>Cryptopaymate</h6>
                <p>Fees/Pricing</p>
                <p>Supported Coins</p>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-3">
              <div>
                <h6>Resources</h6>
                <p>Merchant Tools</p>
                <p>Integration Guide</p>
                <p>Store Directory</p>
                <p>Affiliate Information</p>
                <p>FAQ</p>
                <p>Blog</p>
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-4 col-sm-6 col-6 mb-3">
              <div>
                <h6>Contact</h6>
                <p>Support</p>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-3">
              <div>
                <h6>Legal</h6>
                <p>Restricted Jurisdictions</p>
                <p>User Agreement</p>
                <p>Privacy Policy</p>
                <p>Terms And Conditions</p>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-3">
              <div>
                <h6>Newsletter</h6>
                <input
                  type="email"
                  placeholder="Enter Your Email Address"
                  className="mt-3 mb-4"
                />
                <button className="subscribe">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container">
          <div className="copy-right-sec-footer">
            <div className="">
              <span>©2023 COINPAYMATE, Inc. All Rights Reserved</span>
            </div>
            <div className="d-flex align-items-center ms-0 ms-sm-3 ms-md-5 ms-lg-5 ms-xl-5">
              <img src={Twitter} alt='twitter' />
              <img src={Instagram} alt='instagram' />
              <img src={Facebook} alt='facebook' />
              <img src={Telegram} alt='telegram' />
              <img src={LinkedIn} alt='linkedin' />
              <img src={Youtube} alt='youtube' />
              <img src={Pinterest} alt='pinterest' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
