import React, { useEffect, useState } from "react";
import icon from "../../../../asset/IMG/Dashboard/icon.png";
import "../../../../Styles/ApiKeys.css";
import detaillogo from "../../../../asset/IMG/Dashboard/Feedback/support-coins-detail-logo.png";
import cnfmlogo from "../../../../asset/IMG/Dashboard/Feedback/flat-color-icons_ok.png";
import shortline from "../../../../asset/IMG/Dashboard/Feedback/secondbox-lite-top.png";
import bigline from "../../../../asset/IMG/Dashboard/Feedback/left-top-icon.png";

import { AiFillStar } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";

import accountIcon from "../../../../asset/IMG/Dashboard/Feedback/account-img.png";
import countryIcon from "../../../../asset/IMG/Dashboard/Feedback/country-icon.png";
import HeadIcon from "../../../../asset/IMG/Dashboard/Feedback/headicon.png";
import leftBottomPattern from "../../../../asset/IMG/Dashboard/Feedback/left-icon.png";
import memberIcon from "../../../../asset/IMG/Dashboard/Feedback/member-cion.png";
import verify from "../../../../asset/IMG/Dashboard/Feedback/verify.png";
import AddLogo from "../../../../asset/IMG/Dashboard/Feedback/add-icon.png";

import keynameIcon from "../../../../asset/IMG/Dashboard/Feedback/keyname.png";
import editIcon from "../../../../asset/IMG/Dashboard/Feedback/edit.png";
import ViewIcon from "../../../../asset/IMG/Dashboard/Feedback/view.png";
import { Link } from "react-router-dom";
import { generateuserapikeyhooks, getuserallapikeyshooks } from "../../../../hooks/UseUser";
import { DateTimeForm } from "../../../../hooks/UseValidation";
import { IoCloseCircle } from "react-icons/io5";
const 
ApiKeys = () => {
  const [userToken, setUserToken] = useState();
  const [userId, setUserId] = useState();
  const [records, setRecords] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let accessToken = sessionStorage.getItem("accessToken");
      if ((accessToken != '' && accessToken != null && accessToken != undefined && accessToken != "undefined")) {
        let reqdata = { token: accessToken }
        let res = await getuserallapikeyshooks(reqdata);
        console.log("res?.data?.records", res?.data?.records)
        setRecords(res?.data?.records)
        setStatus(false)
      }
    }
    fetchData();
  }, [status])


  const ApiKeys = [
    {
      date: 23,
      month: "OCT",
      time: "12 : 30 PM",
      timeLogo: detaillogo,
      status: "ENABLED ",
      statusLogo: cnfmlogo,
      keyicon: keynameIcon,
      keyname: "PEOPLE SAYS WARM",
      apikeyValue: "9362612a394e9e8c...",
    },
    {
      date: 23,
      month: "OCT",
      time: "12 : 30 PM",
      timeLogo: detaillogo,
      status: "ENABLED ",
      statusLogo: cnfmlogo,
      keyicon: keynameIcon,
      keyname: "PEOPLE SAYS WARM",
      apikeyValue: "9362612a394e9e8c...",
    },
  ];


  const GenerateNewKey = async () => {
    try {
      let accessToken = sessionStorage.getItem("accessToken");
      if (accessToken) {
        let reqdata = { token: accessToken }
        const res = await generateuserapikeyhooks(reqdata);
        if (res?.data?.success == true) {
          setStatus(true)
        }
        console.log("res generatekey", res?.data)
      }
    } catch (e) {
      console.log("GenerateNewKey_err", e)
    }
  }


  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const d = new Date();
  let name = month[d.getMonth()];

  return (
    <div className="feedback apikeys">
      <div className="head-section d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center head-section">
          <div className="img-section">
            <img src={HeadIcon} alt="icon" />
          </div>
          <h4 className="m-0 ps-3">API KEYS</h4>
        </div>
        <div className="d-flex justify-content-end">
          <button className="commonBtn p-0">
            <img src={AddLogo} alt="" className="common-inside-btn-img" />
            <span className="ps-2" onClick={() => GenerateNewKey()}>GENERATE NEW KEY</span>
          </button>
        </div>
      </div>

      <div>
        {records && records.length > 0 ?
          records?.map((item) => {

            return (
              <>
                {console.log(records, "records")}
                <div className="feedback-table overflow-hidden mb-4">
                  <div class="table-responsive">
                    <table
                      class="table m-0 p-0 position-relative table-line table-left-line fw-bolder"
                    // style={{ height: "150px" }}
                    >
                      {/* <div className="position-absolute">
                      <img src={item.shortline} />
                    </div> */}

                      <tr className="z-2 position-relative">
                        <td
                          rowspan="3"
                          className="bdr-right text-white"
                          style={{ minWidth: "230px" }}
                        >
                          <div className="fst-sec-border d-flex justify-content-center">
                            <div className="recent-date-section d-flex align-items-center justify-content-center bitcoin-bg">
                              <div className="first-sec position-relative">
                                <div className="dot-icon"></div>
                                {/* <h5 className="date px-3 pt-2">{item.date}</h5> */}
                                <h5 className="date px-3 pt-2">{(DateTimeForm(item?.createdAt, true, false, false)).split('/')[0]}</h5>
                                <p className="py-0 px-1 month">{month[new Date(item?.createdAt)?.getMonth()]}</p>
                              </div>

                              <div>
                                <div className="d-flex justify-content-center align-items-center px-3 py-2">
                                  <img
                                    src={ApiKeys[0].timeLogo}
                                    className="time-img-logo"
                                  />
                                  <p className="ps-1 pb-1 time">{DateTimeForm(new Date(item?.createdAt), false, true, true)}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="head-title bdr-right border-bottom-border">
                          KEYNAME
                        </td>
                        <td className="head-title bdr-right border-bottom-border">
                          API KEYS
                        </td>
                        <td className="head-title bdr-right border-bottom-border">
                          STATUS
                        </td>
                        <td className="head-title bdr-right border-bottom-border">
                          ACTION
                        </td>
                      </tr>
                      <tr className="z-2 position-relative">
                        <td className="bdr-right">
                          <div className="d-flex justify-content-center align-items-center">
                            <img src={keynameIcon} alt="keyname" />{" "}
                            <sapn className="keyname-text ms-2">
                              {item?.KeyName}
                            </sapn>
                          </div>
                        </td>
                        <td className="bdr-right">
                          <div>
                            <p>
                              <span className="public-key-name">
                                PUBLIC KEY:{" "}
                              </span>
                              <span className="public-key-value">
                                {item?.PublicKey}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td className="bdr-right">
                          <div>
                            {item?.Status == true && <img src={cnfmlogo} alt="status-logo" />}{" "}
                            {item?.Status == false && <IoCloseCircle color="red" fontSize={23}/>}{" "}
                            <span className="status-value">{item?.Status == true ? "ENABLED" : "DISABLED"}</span>
                          </div>
                        </td>
                        <td className="bdr-right">
                          <div className="d-flex justify-content-around">
                            <div>
                              <Link
                                to={`/edit-api/${item?._id}`}
                                className="text-decoration-none"
                              >
                                <div className="lst-count-btn commonSecondaryBtn">
                                  <button className="z-2 edit_view_btn position-relative">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <span className="edit-icon-bg">
                                        <img
                                          src={editIcon}
                                          alt="editicon"
                                          className="edit-icon-img"
                                        />
                                      </span>
                                      <span className="ps-2 edit-text-name">
                                        EDIT
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </Link>
                            </div>
                            <div>
                              {/* <div className="lst-count-btn commonSecondaryBtn">
                              <button className="z-2 edit_view_btn position-relative">
                                <div className="d-flex align-items-center justify-content-center">
                                  <span className="edit-icon-bg">
                                    <img
                                      src={ViewIcon}
                                      alt="editicon"
                                      className="edit-icon-img"
                                    />
                                  </span>
                                  <span className="ps-2 edit-text-name">
                                    VIEW
                                  </span>
                                </div>
                              </button>
                            </div> */}
                            </div>
                          </div>
                        </td>
                      </tr>
                      {/* <div className="botton-right-logo position-absolute overflow-hidden z-1">
                      <img src={bigline} />
                    </div> */}
                    </table>
                  </div>
                </div>
              </>
            );
          })

          :
          <div className="empty_rec">
            <p >No Records</p>
          </div>



        }

      </div>
      {/* <div className="d-flex justify-content-end">
        <div className="save-btn p-0" style={{ width: "120px" }}>
          <p
            className="z-2 d-flex justify-content-center align-items-center address-click-btn"
            style={{
              cursor: "pointer",
              textTransform: "none",
              // fontSize: "14px",
              fontWeight: "500",
            }}
          >
            <img src={AddLogo} alt="" /> SAVE ADDRESS
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default ApiKeys;
