import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";

import logo from "../../asset/IMG/logo.png";
import home from "../../asset/IMG/home2.png";
import dashboard from "../../asset/IMG/dashboard.png";
import wallet from "../../asset/IMG/wallet.png";
import history from "../../asset/IMG/history.png";
import supportCoin from "../../asset/IMG/support-coin.png";
import fees from "../../asset/IMG/fees.png";
import merchant from "../../asset/IMG/merchant.png";
import support from "../../asset/IMG/support.png";
import search from "../../asset/IMG/search.png";
import notification from "../../asset/IMG/notification.png";
import theme from "../../asset/IMG/theme-change.png";
import liteLogo from "../../asset/IMG/headerLogo.png";
import Offcanvas from "react-bootstrap/Offcanvas";

import "../../Styles/HeaderStyle.css";
import ForgotPassword from "../Pages/ForgotPassword";
import Register from "../Pages/Register";
import Login from "../Pages/Login";
// import Login from "../Pages/Login";
import ResetPassword from "../Pages/ResetPassword";
import TwoFactor from "../Pages/TwoFactor";
import { AiOutlineClose } from "react-icons/ai";
import darkMode from "../../asset/IMG/dark_theme_change.png";
import menuImg from "../../asset/IMG/btn-icon.png";
import NewLogo from "../../asset/IMG/NewLanding/HeaderLogo.png";
import CryptoJS from "crypto-js";
import { isEmpty } from "../../Helper/isEmpty";
import { FRONTENDURL } from "../../config/env";
import { getuserdatabytoken, getuserdetailsbyname, getusermerchantId } from "../../hooks/UseUser";
import { useSelector } from "react-redux";
import Accountmodal from "../Modals/accountmodal"
const Header = ({ darkTheme, setDarkTheme, setToggle, toggle }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [checkotp, setcheckotp] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [showpage, setShowPage] = useState(true);
  let location = useLocation();
  let { merchantId } = useParams();

let usersetshowheaderdata = useSelector((state) => state.reducer.usersetshowheader);

  useEffect(() => {
    async function fetchdata() {
      if(usersetshowheaderdata != undefined && usersetshowheaderdata != null){
        setShowPage(usersetshowheaderdata)
      }
    }
    fetchdata()
  }, [usersetshowheaderdata])



  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const otp = queryParams.get("code");
    console.log("queryParams", queryParams, otp, checkotp, checkotp == otp);
    getData();
    if (checkotp != null && checkotp != undefined) {
      if (checkotp == otp) {
        setResetPassword(true);
      } else {
        setResetPassword(false);
      }
    }
  }, [checkotp, resetPassword, !resetPassword]);

  const getData = async () => {
    try {
      var userdata = {};
      const queryParams = new URLSearchParams(window.location.search);
      const otp = queryParams.get("code");
      if(otp != null && otp != undefined) {
        let pathname = location.pathname.split("/")[2];
        let decodeUserName = atob(pathname)
        console.log("pathName", pathname, decodeUserName);
        if (pathname != undefined) {
          userdata = await getuserdetailsbyname(decodeUserName);
        }
        setcheckotp(userdata?.data?.record?.Otp);
      }

    } catch (e) {
      console.log("getData_err", e);
    }
  };

  // Search Bar Open
  const handleSearchOpen = () => {
    setSearchOpen(!searchOpen);
  };

  const handleThemeChange = () => {
    // const themeValue = localStorage.getItem("theme");
    // setNavigations(false);

    setDarkTheme(!darkTheme);
    // if (!darkTheme) {
    if (darkTheme) {
      document.getElementsByTagName("body")[0].classList.remove("light_Theme");
      document.getElementsByTagName("body")[0].classList.add("dark_Theme");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("dark_Theme");
      document.getElementsByTagName("body")[0].classList.add("light_Theme");
    }
    // if (themeValue === "darktheme" || themeValue === null) {
    //   localStorage.setItem("theme", "lighttheme");
    //   document.getElementsByTagName("body")[0].classList.remove("dark_Theme");
    //   document.getElementsByTagName("body")[0].classList.add("light_Theme");
    // } else {
    //   localStorage.setItem("theme", "darktheme");
    //   document.getElementsByTagName("body")[0].classList.remove("light_Theme");
    //   document.getElementsByTagName("body")[0].classList.add("dark_Theme");
    // }
  };

  const [register, setRegister] = useState(false);
  const [login, setLogin] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [twoFactor, setTwoFactor] = useState(false);
  const [navigations, setNavigations] = useState(true);
  const [reset, setReset] = useState(false);
  const [accountmodal, setAccountmodal] = useState(false);
  const [UserToken,setUserToken] = useState('')

  // console.log("navigations", navigations);
  // Register
  const handleRegisterClose = () => setRegister(false);
  const handleRegisterShow = () => {
    setNavigations(false);

    setLogin(false);
    setForgotPassword(false);
    setTwoFactor(false);
    setRegister(true);
  };
  //Login
  const handleLoginClose = () => setLogin(false);

  const handleLoginShow = () => {
    setNavigations(false);
    setForgotPassword(false);
    setRegister(false);
    setTwoFactor(false);
    setLogin(true);
  };

  //Forgot Password
  const handleForgotClose = () => setForgotPassword(false);
  const handleForgotShow = () => {
    setNavigations(false);

    setLogin(false);
    setRegister(false);
    setTwoFactor(false);
    setForgotPassword(true);
  };

  // Two Factor

  const handleTwoFactorClose = () => {
    console.log("handleTwo propsdatasgal");
    setTwoFactor(false);
  };
  const handleTwoFactorShow = () => {
    setNavigations(false);
    setLogin(false);
    setRegister(false);
    setForgotPassword(false);
    setTwoFactor(true);
  };

  const handleResetShow = () => {
    setResetPassword(true);
  };

  const handleResetClose = () => {
    setResetPassword(false);
  };
 
  const UserLogout = async () => {
    try {
      sessionStorage.removeItem("signup");
      sessionStorage.removeItem("accessToken");
      window.location.href = FRONTENDURL;
    } catch (e) {
      console.log("UserLogout_err", e);
    }
  };

  useEffect(() => {
    const windowWidth = document.body.clientWidth;
    console.log(windowWidth, "windowWidth");
    if (windowWidth < 1200) {
      setNavigations(false);
    } else {
      setNavigations(true);
    }
  }, []);


  useEffect(() => {
    let UserToken = sessionStorage.getItem("accessToken");
    setUserToken(UserToken);
    if (
      UserToken != "" &&
      UserToken != null &&
      UserToken != undefined &&
      UserToken != "undefined"
    ) {
      GetuserAccountType(UserToken);
    }
  }, [UserToken]);

const GetuserAccountType = async (token) => {
    try {
      let reqdata = { token: token, filter: "userAccountType" };
      let res = await getuserdatabytoken(reqdata);
      if (res?.data?.success == true) {
        let data = res?.data?.record;
      
       
        if(data?.isKYCVerify == null && data?.accounttype === "Merchant" && data?.KYCFile?.length < 0){
          setAccountmodal(true)
        }
      
      }
    } catch (e) {
      console.log("getData_err", e);
    }
  };

  const handleToggleChange = () => {
    setToggle(!toggle);
    console.log("togle", toggle);
  };

  const handleNewClassAdd = (e) => {
    e.target.classList.add("active_Clas");
  };

  return (
    <>
      {accountmodal && <Accountmodal onDismiss={() => setAccountmodal(false)} />}
     {showpage ? <div className="header d-flex ">
        <div
          className="container d-none d-xl-flex align-items-center justify-content-around"
          style={{ width: "100%"}}
        >
          <div className="first_div">
            <NavLink to="/" className="text-decoration-none">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100%" }}
              >
                <img
                  // src={darkTheme ? logo : liteLogo}
                  src={NewLogo}
                  alt="logo"
                  className="img-fluid"
                />
                <span className="ms-3">COINPAYMATE</span>
              </div>
            </NavLink>
          </div>

          {/* <div className="second_div custom_container"> */}
          <div className="left_section d-flex align-items-center">
            {/* <NavLink to="/" className="home_img">
                <img
                  src={home}
                  alt="home"
                  className="img-fluid header_home_Img"
                />
                <span>HOME</span>
              </NavLink> */}
            <div className="nav_link_items d-flex align-items-center">
              <NavLink to="/" className="home_img">
                {/* <img
                  src={home}
                  alt="home"
                  className="img-fluid header_home_Img"
                /> */}
                <p className="headerP">Home</p>
              </NavLink>
              <NavLink
                to="/dashboard"
                className="d-flex align-items-center act_link"
                onClick={handleNewClassAdd}
              >
                {/* <img
                  src={dashboard}
                  alt="dashboard"
                  className="img-fluid headerImg"
                /> */}
                <p className="headerP ">Dashboard</p>
              </NavLink>
              <NavLink
                to="/wallet"
                className="d-flex align-items-center act_link"
                onClick={handleNewClassAdd}
              >
                {/* <img
                  src={wallet}
                  alt="dashboard"
                  className="img-fluid headerImg"
                /> */}
                <p className="headerP">Wallet</p>
              </NavLink>
              <NavLink
                to="/history"
                className="d-flex align-items-center act_link"
                onClick={handleNewClassAdd}
              >
                {/* <img src={history} alt="" className="img-fluid headerImg" /> */}
                <p className="headerP">History</p>
              </NavLink>
              <NavLink
                to="/support-coin "
                className="d-flex align-items-center act_link "
                onClick={handleNewClassAdd}
              >
                {/* <img
                  src={supportCoin}
                  alt="supportCoin"
                  className="img-fluid headerImg"
                /> */}
                <p className="headerP ">Support Coin</p>
              </NavLink>
              <NavLink
                to="/fees"
                className="d-flex align-items-center act_link"
                onClick={handleNewClassAdd}
              >
                {/* <img src={fees} alt="fees" className="img-fluid headerImg" /> */}
                <p className="headerP">Fees</p>
              </NavLink>
              <NavLink
                to="/merchant"
                className="d-flex align-items-center act_link"
                onClick={handleNewClassAdd}
              >
                {/* <img
                  src={merchant}
                  alt="merchant"
                  className="img-fluid headerImg"
                /> */}
                <p className="headerP">Merchant Tools</p>
              </NavLink>
              {/* <NavLink
                to="/"
                className="d-flex align-items-center act_link"
                onClick={handleNewClassAdd}
              >
               
                <p className="headerP support_headerP ">Support</p>
              </NavLink> */}
            </div>
          </div>

          {/* <div className="right_section d-flex align-items-center gap-2">
              {searchOpen && (
                <div className="inputfield">
                  <input type="text" placeholder="Search" />
                </div>
              )}
              <div className="search_icon">
                <img
                  src={search}
                  alt="search"
                  className="img-fluid"
                  onClick={handleSearchOpen}
                />
              </div>
            </div> */}
          {/* </div> */}

          <div className="third_div header_credentials d-flex align-items-center">
            <div className="auth_credentials d-flex align-items-center ">
              {sessionStorage.getItem("accessToken") == null &&
                !resetPassword && (
                  <button onClick={handleLoginShow}>Login</button>
                )}
              {sessionStorage.getItem("signup") == null && !resetPassword && (
                <div className="commonBtn register_btn">
                  <button
                    className="z-2"
                    onClick={handleRegisterShow}
                    style={{
                      cursor: "pointer",
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Sign Up
                  </button>
                </div>
              )}
          
              {sessionStorage.getItem("signup") != null &&
                sessionStorage.getItem("accessToken") != null && (
                  <div className="commonBtn">
                    <button
                      className="z-2"
                      onClick={() => {
                        UserLogout();
                      }}
                      style={{
                        cursor: "pointer",
                        textTransform: "none",
                        fontSize: "15px",
                        fontWeight: "700",
                      }}
                    >
                      Log out
                    </button>
                  </div>
                )}
            </div>
            <div className="notifications_themeChange d-flex align-items-center">
              {/* <div className="notification_img">
                <img
                  src={notification}
                  alt="notification"
                  className="headerImg img-fluid"
                />
              </div> */}
              {/* <div className="themeChange_border" onClick={handleThemeChange}>
                <img
                  // src={darkTheme ? theme : darkMode}
                  src={darkTheme ? darkMode : theme}
                  alt="themeChange"
                  className="headerImg img-fluid light_dark_icon"
                />
              </div> */}
            </div>
          </div>
        </div>

        <div
          className="d-xl-none d-flex align-items-center mobile-view justify-content-between px-2 px-md-3 px-lg-4"
          style={{ width: "100%", height: "61px" }}
        >
          <div className="headerLogo" style={{ height: "100%" }}>
            <NavLink to="/" className="text-decoration-none">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100%" }}
              >
                <img
                  // src={darkTheme ? logo : liteLogo}
                  src={NewLogo}
                  alt="logo"
                  className="img-fluid"
                />
                <span className="ms-2">COINPAYMATE</span>
              </div>
            </NavLink>
          </div>
          <div className="header_menu" onClick={handleToggleChange}>
            <img src={menuImg} alt="menu" className="img-fluid ll" />
          </div>
        </div>
      </div> : <></>}
     {showpage ? <div className="offcanvas-section">
        {/* Register */}
        <Offcanvas
          show={register}
          // onHide={handleRegisterClose}
          placement="top"
          className="register-section canvas-section"
          style={{ minHeight: "470px" }}
        >
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body className="canvas-body">
            <button
              type="button"
              class="position-absolute canvas-closebtn close_btn_icon"
              aria-label="Close"
              onClick={handleRegisterClose}
            >
              <AiOutlineClose />
            </button>
            <Register onDismiss={() => handleRegisterClose()} />
          </Offcanvas.Body>
        </Offcanvas>
        {/* Login */}
        <Offcanvas
          show={login}
          // onHide={handleLoginClose}
          placement="top"
          className="login-section canvas-section"
        >
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body className="canvas-body">
            <button
              type="button"
              class=" position-absolute canvas-closebtn close_btn_icon"
              aria-label="Close"
              onClick={handleLoginClose}
            >
              <AiOutlineClose />
            </button>
            <Login
              handleForgotPassword={handleForgotShow}
              handleCreateAccount={handleRegisterShow}
              handleTwoFactorShow={handleTwoFactorShow}
              handleLoginClose={handleLoginClose}
            />
          </Offcanvas.Body>
        </Offcanvas>
        {/* Reset Password */}
        <Offcanvas
          show={resetPassword}
          // onHide={handleLoginClose}
          placement="top"
          className="login-section canvas-section"
        >
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body className="canvas-body">
            <button
              type="button"
              class=" position-absolute canvas-closebtn close_btn_icon"
              aria-label="Close"
              onClick={handleResetClose}
            >
              <AiOutlineClose />
            </button>
            <ResetPassword handleResetClose={handleResetClose} Otp={checkotp} />
          </Offcanvas.Body>
        </Offcanvas>
        {/* Two Factor */}
        <Offcanvas
          show={twoFactor}
          // onHide={handleLoginClose}
          placement="top"
          className="twoFactor-section canvas-section"
        >
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body className="canvas-body">
            <button
              type="button"
              class=" position-absolute canvas-closebtn close_btn_icon"
              aria-label="Close"
              onClick={handleTwoFactorClose}
            >
              <AiOutlineClose />
            </button>
            <TwoFactor
              onDismiss={() => handleTwoFactorClose()}
              onLogin={() => setLogin(true)}
            />
          </Offcanvas.Body>
        </Offcanvas>
        {/* Forgot Password */}
        <Offcanvas
          show={forgotPassword}
          // onHide={handleForgotClose}
          placement="top"
          className="forgot-password-section"
          style={{ minHeight: "315px" }}
        >
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body className="canvas-body">
            <button
              type="button"
              class="close_btn_icon position-absolute canvas-closebtn"
              aria-label="Close"
              onClick={handleForgotClose}
            >
              <AiOutlineClose />
            </button>
            <ForgotPassword />
          </Offcanvas.Body>
        </Offcanvas>
      </div> : <></>}
    </>
  );
};

export default Header;
