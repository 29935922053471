import React, { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import { useLocation, useParams } from "react-router-dom";

import { Dropdown } from "react-bootstrap";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";

import qrcode from "../../asset/IMG/NewLanding/qrcode.png"
import { getAssetsymbol, getuserparticularwalletdatahooks } from "../../hooks/UseUser";
import Login from "./Login";
import { UseUserWithdraw, UseUserWithdrawBTC } from "../../hooks/UseBackend";
import { position, iconTheme, style } from "../../hooks/UseToast";
import toast from "react-hot-toast";

const Withdrawal = () => {

  const [depositdata, setDepositData] = useState({});
  const [assetdata, setassetdata] = useState({});
  const [fromaddress, setfromaddress] = useState("");
  const [toaddress, settoaddress] = useState("");
  const [currency, setcurrency] = useState("");
  const [amount, setamount] = useState("")
  const [issubmit, setissubmit] = useState(true)

  let location = useLocation();




  useEffect(() => {
    async function fetchData() {
      let reqdata = { _id: location?.pathname?.split("/")[2], accessToken: sessionStorage.getItem("accessToken"), filter: "assetwalletdata" }
      let res = await getuserparticularwalletdatahooks(reqdata);
     
      


      setDepositData(res?.data?.rec)
      setfromaddress(res?.data?.rec?.addressInfo[0].address)

      let assetdata = await getAssetsymbol(res?.data?.rec?.symbol);
      setcurrency(res?.data?.rec?.symbol)

      setassetdata(assetdata)
    }
    fetchData()
  }, [location]);




  const onSubmit = async () => {

    const Data = {
      fromAddress: fromaddress,
      toAddress: toaddress,
      amount: amount,
      currency: currency
    };
if(currency === "BNB"){
  if ((parseFloat(assetdata?.minimumWithdraw) <= parseFloat(amount)) == false) {

    toast.error(`please check the minimum withdraw amount in ${currency}`, {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
  })
  } else if ((parseFloat(depositdata?.balance) >= parseFloat(amount))== false) {
    toast.error(`Your Withdraw amount ${amount}${currency} exist your balance`, {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
  })

  }else{

    await UseUserWithdraw(Data)
  }

}
else{
  
  if((parseFloat(assetdata?.minimumWithdraw) <= parseFloat(amount))== false) {
    toast.error(`please check the minimum withdraw amount in ${currency}`, {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
  })


  }else if ((parseFloat(depositdata?.balance) >= parseFloat(amount))== false) {
    toast.error(`Your Withdraw amount" ${amount}${currency} exist your balance`, {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
  })

  }else{

    await UseUserWithdrawBTC(Data)
  }
}
 





    
  }

  return (
    <>
      <section className="paddingY walletSection ">
        <div className="wallet_top_container position-relative">
          <div className="top_bg">
            <div className="container">
              <div className="walletTop">
                <p className="titleP">Withdraw</p>
                <p className="subtitleP">
                  One wallet. Countless features on the <br /> go through{" "}
                  <span>COINPAYMATE</span>{" "}
                </p>
              </div>

         
            </div>
          </div>
        </div>
        <div
          className="container-fluid  walletBottom wallet_margin_top background_img"
          style={{ zIndex: "100" }}
        >
          <div className="container tab-content">
            <div className="heading pt-5">
              <div className="left">
                <p>Withdraw Coins - {depositdata?.name}</p>

              </div>
            </div>

            <div className="account-setting-section">
              <div className="basic-settings-content pt-5 pb-3">
                <div className="row public-info">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="row mb-4">
                      <label
                        for="colFormLabel"
                        class="col-lg-6 col-form-label"
                      >
                        Minimum Withdrawal:
                      </label>
                      <div class="col-lg-8">

                        <p>
                          {assetdata?.minimumWithdraw} {depositdata?.name}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="row mb-4">
                      <label
                        for="colFormLabel"
                        class="col-lg-6 col-form-label"
                      >
                        Your Current Balance:
                      </label>
                      <div class="col-lg-8">

                        <p>
                          {depositdata?.balance} {depositdata?.name}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="row mb-4">
                      <label
                        for="colFormLabel"
                        class="col-lg-12 col-form-label"
                      >
                        Withdraw Amount in {depositdata?.name}
                      </label>
                      <div class="col-lg-8">
                        <div class="col-lg-12">



                          <div className="input-group input_grp_withdraw">

                            <input type="text"   onChange={(e) => {  setamount(e.target.value) }}className="form-control"></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="row mb-4">
                      <label
                        for="colFormLabel"
                        class="col-lg-12 col-form-label"
                      >
                        Withdraw wallet Address
                      </label>
                      <div class="col-lg-8">
                        <div class="col-lg-12">



                          <div className="input-group input_grp_withdraw">

                            <input type="text"   onChange={(e) => {  settoaddress(e.target.value) }} className="form-control"></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="row mb-4">
                      <label
                        for="colFormLabel"
                        class="col-lg-6 col-form-label"
                      >
                        Transaction Fee (est.):
                      </label>
                      <div class="col-lg-8">

                        <p>
                          {assetdata?.withdrawFee} {depositdata?.name}
                        </p>
                      </div>
                    </div>
                  </div>

                 






                </div>
              </div>
            </div>



            <div className="text-center my-4">
          
              <button className="bluebtn_theme"  onClick={() => onSubmit()}> Withdrawal/Send</button>
            </div>

            <div className="notes_card mb-5">
              <b>Notes:</b> For security we will send a confirmation email to you; you must click the link within before your send/withdrawal will be completed.
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Withdrawal;
