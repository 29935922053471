import { useEffect, useState } from "react";
import Home from "./components/Pages/Home";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Wallet from "./components/Pages/Wallet";
import "./App.css";
import History from "./components/Pages/History";
import SupportCoin from "./components/Pages/SupportCoin";
import Merchant from "./components/Pages/Merchant";
import Fees from "./components/Pages/Fees";
import Login from "./components/Pages/Login";
import Register from "./components/Pages/Register";

import Dashboard from "./components/Pages/Dashboard/Dashboard";
import AddAddress from "./components/Pages/Dashboard/DashboardSubContant/AddAddress";
import EditApi from "./components/Pages/Dashboard/DashboardSubContant/ApiKeys/EditApi";
import ApiKeyPermissions from "./components/Pages/Dashboard/DashboardSubContant/ApiKeys/ApiKeyPermissions";
import EmailVerification from "./components/Pages/EmailVerification";
import MobileNavlinks from "./components/utils/MobileNavlinks";
import LandingPage from "./components/Pages/LandingPage";
import toast, { Toaster } from "react-hot-toast";
import SocketContext from "./content/socketContent";
import { socket } from "./config/socketConnectivity";
import HelperRoutes from "./helper";
import Deposits from "./components/Pages/Deposits";
import Withdrawal from "./components/Pages/Withdrawal";
import PaymentGateway from "./components/Pages/PaymentGateway";
function App() {
  const [darkTheme, setDarkTheme] = useState(true);
  const [toggle, setToggle] = useState(false);

  // console.log = () => { };
  // console.debug = () => { };
  // console.info = () => { };
  // console.warn = () => { };
  // useEffect(() => {
  //   setDarkTheme(!darkTheme);
  //   if (!darkTheme) {
  //     document.getElementsByTagName("body")[0].classList.remove("dark_Theme");

  //     document.getElementsByTagName("body")[0].classList.add("light_Theme");
  //   } else {
  //     document.getElementsByTagName("body")[0].classList.remove("light_Theme");

  //     document.getElementsByTagName("body")[0].classList.add("dark_Theme");
  //   }
  // }, []);

  // useEffect(() => {
  //   // const setThemeInStorage = localStorage.geItem("theme", "");
  //   const storageThemeValue = localStorage.getItem("theme");
  //   console.log("storageThemeValue is", storageThemeValue);
  //   if (storageThemeValue === null) {
  //     localStorage.setItem("theme", "darktheme");
  //   }
  // }, []);
  // const handleToggleChange = () => {
  //   setToggle(!toggle);
  //   console.log("toggle", toggle);
  // };

  // console.log("toggle", toggle);

  console.log("windowslocation", window.location.pathname);

  const windowLocation = window.location.pathname;

  return (
    <>
      <div>
        <Toaster />
      </div>
      <BrowserRouter>
        <SocketContext.Provider value={{ socket }}>
         
          {windowLocation !== "/paymentgateway" && (
            <Header
              darkTheme={darkTheme}
              setDarkTheme={setDarkTheme}
              toggle={toggle}
              setToggle={setToggle}
            />
          )}
          {toggle && (
            <div className="position-fixed mob_navs px-2 px-md-3 px-lg-4 d-flex d-xl-none">
              <MobileNavlinks
                setToggle={setToggle}
                toggle={toggle}
                darkTheme={darkTheme}
                setDarkTheme={setDarkTheme}
              />
            </div>
          )}
 <HelperRoutes />
          <Routes>
            {/* <Route
          path="/"
          element={<Home darkTheme={darkTheme} setDarkTheme={setDarkTheme} />}
        /> */}

            <Route path="/" element={<LandingPage />} />
            <Route path="/reset/:otp" element={<LandingPage />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/history" element={<History />} />
            <Route path="/support-coin" element={<SupportCoin />} />
            <Route path="/merchant" element={<Merchant />} />
            <Route path="/fees" element={<Fees />} />
            {/* <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} /> */}
            <Route path="/dashboard" element={<Dashboard />} />

            <Route path="/add-address" element={<AddAddress />} />
            {/* /activation/U2FsdGVkX1+LQzWzTLn4RHkuh29zcTznzIrvNJNlLHY= */}
            {/* <Route path="/activation" element={}/> */}
            <Route path="/activation" element={<EmailVerification />} />
            <Route path="/edit-api/:id" element={<EditApi />} />
            <Route
              path="/api-key-permissions/:id"
              element={<ApiKeyPermissions />}
            />
            <Route path="/deposits/:id" element={<Deposits />} />
            <Route path="/withdraw/:id" element={<Withdrawal />} />
            <Route path="/:merchantId" element={<LandingPage />} />
            {/* <Route
              path="/paymentgateway"
              element={<PaymentGateway windowLocation={windowLocation} />}
            /> */}
            <Route
              path="/paymentgateway/:orderid"

              // path="/paymentgateway/:orderid/:amount/:url/:currency/:email/:key"
              element={<PaymentGateway windowLocation={windowLocation} />}
            />

            {/* <Home darkTheme={darkTheme} setDarkTheme={setDarkTheme} /> */}
          </Routes>
        </SocketContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
