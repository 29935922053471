import React, { useEffect, useState } from "react";
import "../../../../../Styles/ApiKeys.css";
import icon from "../../../../../asset/IMG/Dashboard/Feedback/headicon.png";
import lineLogo from "../../../../../asset/IMG/Dashboard/line_logo.png";
import Star from "../../../../../asset/IMG/Dashboard/Star 1(1).png";
import arrow from "../../../../../asset/IMG/Dashboard/maki_arrow.png";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../../Layout/Footer";
import halfPoly from "../../../../../asset/IMG/Dashboard/half-poly.png";
import fullPolySmall from "../../../../../asset/IMG/Dashboard/Polygon-tab-logo.png";
import formStarIcon from "../../../../../asset/IMG/Dashboard/little-star.png";
import formPolyIcon from "../../../../../asset/IMG/Dashboard/key-permission-poly.png";
import { SET_USERAPI_ACCESS } from "../../../../../redux/action";
import { useDispatch } from "react-redux";
function ApiKeyPermissions() {

  const InitialState =
  {
    "get_basic_info": false, "create_withdrawal": false, "set_basic_info": false, "Allow_auto_confirm_in_create_withdrawal": false,
    "create_transaction": false, "create_mass_withdrawal": false, "get_tx_info": false, "get_withdrawal_info": false, "get_callback_address": false,
    "cancel_withdrawal": false, "rates": false, "convert": false, "balances": false, "convert_limits": false, "get_deposit_address": false,
    "get_conversion_info": false, "create_transfer": false, "get_pbn_info": false, "Allow_auto_confirm_in_create_transfer": false, "manage_pbn": false,
  }

  const [allowaccess, setAllowAccess] = useState(InitialState);
  console.log("allowaceesssss accessdtatatat", allowaccess)
  const navigate = useNavigate();
  const location = useLocation();
  const [pathName, setPathName] = useState("");
  const handleBack = () => {
    navigate(-1);
  };
  let dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name == "Allow auto_confirm = 1 in create_withdrawal") {
      setAllowAccess({ ...allowaccess, ...{ "Allow_auto_confirm_in_create_withdrawal": checked } })
    } else if (name == "Allow auto_confirm = 1 in create_transfer") {
      setAllowAccess({ ...allowaccess, ...{ "Allow_auto_confirm_in_create_transfer": checked } })
    } else {
      setAllowAccess({ ...allowaccess, ...{ [name]: checked } })
    }

  }

  useEffect(() => {
    console.log("locaaaaaaaa", location, location?.pathname?.split('/')[2])
    let pathName = location?.pathname?.split('/')[2];
    setPathName(pathName);
    if(location?.state) {
      setAllowAccess(location?.state)
    }
  }, [location]);


  const AccesPermissionKeys = [
    {
      name: "get_basic_info",
    },
    {
      name: "create_withdrawal",
    },
    {
      name: "set_basic_info",
    },
    {
      name: "Allow auto_confirm = 1 in create_withdrawal",
      subname: "(not recommended unless you absolutely need it)",
    },
    {
      name: "create_transaction",
    },
    {
      name: "create_mass_withdrawal",
    },
    {
      name: "get_tx_info",
    },
    {
      name: "get_withdrawal_info",
    },
    {
      name: "get_callback_address",
    },
    {
      name: "cancel_withdrawal",
    },
    {
      name: "rates",
    },
    {
      name: "convert",
    },
    {
      name: "balances",
    },
    {
      name: "convert_limits",
    },
    {
      name: "get_deposit_address",
    },
    {
      name: "get_conversion_info",
    },
    {
      name: "create_transfer",
    },
    {
      name: "get_pbn_info",
    },
    {
      name: "Allow auto_confirm = 1 in create_transfer",
      subname: "(not recommended unless you absolutely need it)",
    },
    {
      name: "manage_pbn",
    },
  ];

  const UpdatePermission = async () => {
    try {

      navigate(`/edit-api/${pathName}`)
      allowaccess['_id'] = pathName;
      dispatch({ type: SET_USERAPI_ACCESS, payload: allowaccess })
    } catch (e) {

    }
  }
  return (
    <>
      <div className="api-key-permission edit-api-page position-relative overflow-hidden">
        {/* <div className="line-logo position-absolute">
          <img src={lineLogo} />
        </div> */}
        {/* <div className="star-logo position-absolute">
          <img src={Star} />
        </div> */}
        {/* <div className="hal-polygon-img-sec">
          <img src={halfPoly} alt="polygon" />
        </div> */}
        {/* <div className="full-polygon-img-sec">
          <img src={fullPolySmall} alt="polygon" />
        </div> */}
        <div className="header-section py-5 overflow-hidden">
          <div className="position-relative">
            <div className="position-relative container d-flex flex-column align-items-start dashboard-section">
              <div>
                <h2>API Key Permissions</h2>
                <h4>
                  TRADE, SELL AND HOLD 10+ CRYPTOCURRENCIES ON <br></br>
                  <span className="youpay">COINPAYMATE</span>
                </h4>
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                onClick={handleBack}
                style={{ cursor: "pointer" }}
              >
                <img src={arrow} className="left-arrow-custom" />{" "}
                <span className="back ms-2">BACK</span>
              </div>
            </div>
            {/* <div className="full-polygon-img-sec">
              <img src={fullPolySmall} alt="polygon" />
            </div> */}
          </div>
        </div>

        <div className="form-section z-2 position-relative">
          <div className="edit-form-section container py-3">
            <div className="recent-sections">
              <div className="position-relative mb-2 mb-xl-5 mb-lg-5 mb-md-4 mb-sm-3 d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="img-section">
                    <img src={icon} />
                  </div>
                  <h4 className="m-0 ps-3">ALLOW ACCESS</h4>
                </div>
              </div>
            </div>

            <div className="basic-settings-content mx-0 z-2 position-relative">
              <div className="row public-info">
                {allowaccess && Object.keys(allowaccess).map((item, index) => {
                  {console.log("itemvaluesssss", Object.values(allowaccess)[index],  item)}
                  return (
                    <>
                      <div
                        className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3"
                        key={index}
                      >
                        <div class="row">
                          <label
                            for={item == "Allow_auto_confirm_in_create_withdrawal" ? "Allow auto_confirm = 1 in create_withdrawal" : item}
                            className="col-xl-9 d-flex flex-wrap col-lg-9 col-md-9 col-sm-9 col-9 col-form-label key-per-label text-uppercase"
                          >
                            <p>{item == "Allow_auto_confirm_in_create_withdrawal" ? "Allow auto_confirm = 1 in create_transfer" : item == "Allow_auto_confirm_in_create_transfer" ? "Allow auto_confirm = 1 in create_transfer" : item}</p>

                            <span className="subname-label">{(item == "Allow_auto_confirm_in_create_withdrawal" || item == "Allow_auto_confirm_in_create_transfer")? "not recommended unless you absolutely need it" : ""}</span>
                          </label>
                          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                            <div className="key-enabled d-flex justify-content-center">
                              <input
                                class="form-check-input custom-input custom-checkbox"
                                type="checkbox"
                                id={item}
                                name={item}
                                value={item}
                                checked={Object.values(allowaccess)[index]}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="d-flex justify-content-end">
                <button className="key-permission commonBtn p-0" onClick={() => UpdatePermission()} >
                  UPDATE PERMISSION </button>
              </div>
            </div>
          </div>
          {/* <div className="full-polygon-inside-form" style={{ bottom: "26%" }}>
            <img src={fullPolySmall} alt="polygon" />
          </div> */}
          {/* <div
            className="position-absolute"
            style={{ bottom: "-90px", left: "36%" }}
          >
            <img src={formStarIcon} alt="star" />
          </div> */}
          {/* <div
            className="position-absolute"
            style={{ bottom: "-90px", left: "0px" }}
          >
            <img src={formPolyIcon} alt="polygon" />
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ApiKeyPermissions;
