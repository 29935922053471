import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { userupdatelabelhooks } from "../../hooks/UseUser";
import { useDispatch } from "react-redux";


function EditnewAddress(props) {

  const [showEdit, setShowEdit] = useState(true);
  const [rec, setRec] = useState({});
  const [label, setLabel] = useState("");
  const [error, setError] = useState("");



  useEffect(() => {
    setRec(props?.label)
    setLabel(props?.label?.addressInfo[props?.index]?.label)
  }, [props])


  const UpdateLabel = async () => {
    try {
      let reqdata = { _id: rec?._id, address: rec?.addressInfo[props?.index]?.address, label: label, accessToken: sessionStorage.getItem("accessToken"), filter: "walletlabelupdate" };
      console.log("updatedata", reqdata)
      if (label != "" && label != "") {
        let res = await userupdatelabelhooks(reqdata);
        console.log("res?.data?.succeesss", res?.data?.success)
        if (res?.data?.success == true) {
          props.onDismissedit()
          props?.onCheck()
        }
      } else {
        setError({ label: "Label is required" })
      }

    } catch (e) {
      console.log("UpdateLabel_err", e)
    }
  }

  return (
    <div>
      {/* Modal Popup */}
      <Modal
        show={showEdit}
        onHide={() => { props.onDismissedit() }}
        centered
        className="get_demo_popup"
      >
        <Modal.Body>
          <button className="btn_close_cross" onClick={() => { props.onDismissedit() }}>
            x
          </button>
          <div className="get_demo_modal_popup">
            <div className="d-flex flex-column">
              <p className="mb-2">Edit Label</p>

            </div>
            <div className="d-flex flex-column mt-4">

              <div className="otp_input mb-3">
                Edit Label
              </div>
              <input
                type="text"
                class="form-control"
                id="labeledit"
                name="labeledit"
                value={label}
                onChange={(e) => setLabel(e?.target?.value)}

              />
            </div>
            <span className="text-danger">{error?.label}</span>
          </div>
          <div className="mt-4 d-flex justify-content-center align-items-center mt-3">
            <Button
              onClick={() => { UpdateLabel() }}
              disabled={((props?.label?.addressInfo[props?.index]?.label).toLowerCase() == label.toLowerCase()) ? true : false}
              className="new_common_btn modal_submit_btn me-2"
            >
              Update
            </Button>

            <Button
              onClick={() => { props.onDismissedit() }}
              className="new_common_btn modal_submit_btn modal_btn_cancelk"
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EditnewAddress;
