import axios from "axios";
import { BACKURL, FRONTENDURL } from "../config/env";
import { position, iconTheme, style } from "./UseToast";
import toast from "react-hot-toast";
import isEmpty from "is-empty";
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));



export const ConvertusdPrice = async (fromsymbol,to) => {
    let respData = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=${fromsymbol}&tsyms=${to}`);
   
    return respData?.data
  };



export const Useuploadfile = (param) => {

    var KycFiledata = new FormData();
    if (!isEmpty(param.assessToken)) KycFiledata.append('assessToken', param.assessToken);
    if (!isEmpty(param.FileType)) KycFiledata.append('FileType', param.FileType);
    if (!isEmpty(param.country)) KycFiledata.append('country', param.country);
    if (!isEmpty(param.file)) {
        KycFiledata.append('file', param.file);

    }

    axios
        .post(BACKURL + "/api/kyc/upload", KycFiledata)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {


                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = FRONTENDURL + "/dashboard"

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};



export const UsergetInfobyname = async(UserName) => {
    const token = sessionStorage.getItem('Token');
    const userinfo =  await axios.get(`${BACKURL}/api/user/infobyname/${UserName}`,{headers: {authorization : `Bearer ${token}`} })
    return userinfo.data;
 }


 export const useCreateOrder = async(data)=>{

    const createOrder =  await axios.post(`${BACKURL}/api/User/create-order`,data,{headers: {authorization :data?.key} });
    return createOrder.data
    


 }


 export const UseUserWithdraw = async(param) => {


    console.log("paramparamparam",param);

  let responsedata =  await axios.post(BACKURL + "/api/User/userBNBWithdraw", param);
      
console.log("responsedata",responsedata);

            // if (responsedata.status === 200 && responsedata.data.success === true) {


            //     toast.success(`${responsedata.data.data}`, {
            //         position: position.position,
            //         style: style,
            //         iconTheme: iconTheme,
            //     })
            //     await sleep(1000)
            //     window.location.href = FRONTENDURL + "/wallet"

            // } else {
            //     toast.error(`${responsedata.data.data}`, {
            //         position: position.position,
            //         style: style,
            //         iconTheme: iconTheme,
            //     })
            // }
        
    
        
};


export const UseUserWithdrawBTC = async(param) => {

    let responsedata =  axios.post(BACKURL + "/api/User/withDraw_BTC", param);
        
  
  
              if (responsedata.status === 200 && responsedata.data.success === true) {
  
  
                  toast.success(`${responsedata.data.data}`, {
                      position: position.position,
                      style: style,
                      iconTheme: iconTheme,
                  })
                  await sleep(1000)
                  window.location.href = FRONTENDURL + "/wallet"
  
              } else {
                  toast.error(`${responsedata.data.data}`, {
                      position: position.position,
                      style: style,
                      iconTheme: iconTheme,
                  })
              }
          
      
          
  };



  export const UseCoinAcceptance = async(param) => {
   

    let responsedata =  await axios.post(BACKURL + "/api/User/coin-settings", param);
    
        
  
  
              if (responsedata?.status === 200 && responsedata?.data?.status === true) {
  

                  toast.success(`${responsedata?.data?.message}`, {
                      position: position.position,
                      style: style,
                      iconTheme: iconTheme,
                  })
                  await sleep(1000)
                  window.location.href = FRONTENDURL + "/wallet"
  
              } else { 
                  toast.error(`${responsedata?.data?.message}`, {
                      position: position.position,
                      style: style,
                      iconTheme: iconTheme,
                  })
              }
          
      
          
  };

 