// import React from "react";
import React, { useEffect, useState } from "react";
import "../../Styles/Register.css";
import captchalogo from "../../asset/IMG/logos_hcaptcha.svg";

import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Captchaa from "../Recaptch";
import { userValidation } from "../../hooks/UseValidation";
import { style, position, iconTheme } from "../../hooks/UseToast";
import { createuserhooks } from "../../hooks/UseUser";
import toast from "react-hot-toast";
import { BiHide, BiShow } from "react-icons/bi";
import { sleep } from "../../hooks/UseUser";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useLocation } from "react-router-dom";
function Register(props) {
  const InitialState = {
    user_name: "",
    email: "",
    retypeemail: "",
    mobile_no: "",
    password: "",
    retypepassword: "",
    recaptcha: "",
    check1: "",
    check2: "",
    filter: "register",
    merchantId : ""
  };
  let location = useLocation();
  const [passwordShow, setPasswordshow] = useState(false);
  const [retypePasswordShow, setRetypePasswordshow] = useState(false);

  const [value, onChange] = useState("10:00");
  const [startDate, setStartDate] = useState(new Date());
  const [userdetails, setUserDetails] = useState(InitialState);
  const [errors, setErrors] = useState({});
  const [dialcode, setDialCode] = useState('1');




  useEffect(() => {
    let splitdata = location?.pathname?.split("/")[1];
    console.log("splitdata_splitdata", splitdata, splitdata != undefined && splitdata != "")
    if (splitdata != undefined && splitdata != "") {
      setUserDetails({ ...userdetails, ...{ "merchantId": splitdata } })
    }
  }, [location]);


  let regex = /^[0-9]+$/;
  const handleChange = (e) => {

    const { value, id, checked } = e.target;
    if (id == "check1") {
      setUserDetails({ ...userdetails, ...{ [id]: checked } });
    } else if (id == "check2") {
      setUserDetails({ ...userdetails, ...{ [id]: checked } });
    } else {
      setUserDetails({ ...userdetails, ...{ [id]: value } });
    }
  };

  const handlePhoneNumber = async (value, country) => {
    console.log('value jeje', value, country)
    const { dialCode } = country;
    let newPhoneNo = value;
    if (dialCode) {
      setDialCode(dialCode)
      setUserDetails({...userdetails, ...{"mobile_no" : newPhoneNo.slice(dialCode.length)}})
    } else if(value) {
      setUserDetails({...userdetails, ...{"mobile_no" : value}})
    }
  }
  const UserRegister = async () => {
    try {

      const { errors, isValid } = await userValidation(userdetails);
      setErrors(errors);


      if (Object.keys(errors).length == 0 && isValid === true) {
        const { user_name, email, mobile_no, password, merchantId} = userdetails;
        let reqData = {
          user_name: user_name?.toLowerCase(),
          email: email,
          mobile_no:  "+" + dialcode + "." + mobile_no,
          password: password,
          merchantId : merchantId,
          filter: "register",
        };
        console.log("reqData", reqData)
        let res = await createuserhooks(reqData);
        if (res?.data?.success == true) {
          sessionStorage.setItem("signup", true)
          props?.onDismiss()
          toast.success(res?.data?.data, {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
          });
          await sleep(1000)
        } else if (res?.data?.success == "success") {
          setErrors(res?.data?.errors);
        } else if(res?.data?.success == false){
          toast.error(res?.data?.data, {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
          });
          await sleep(1000)
        }

      }
    } catch (e) {
      console.log("UserRegister_errrr", e);
    }
  };

  return (
    <div className="register-page">
      <div className="container second_div">
        <div className="row">
          <div className="col-lg-6 col-md-6 p-0 first-col-section">
            <div className="first-section">
              <div className="create-account-section my-3">
                <h2>
                  <span className="create">CREATE</span> ACCOUNT
                </h2>
                <p>
                  LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE{" "}
                  <span className="printing">PRINTING</span>
                </p>
              </div>
              <div className="mt-4" style={{ padding: "14px" }}>
                <div className="row mb-3">
                  <label
                    for="inputEmail3"
                    className="col-sm-3 col-md-12 col-lg-3 col-form-label"
                  >
                    EMAIL
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control custom-input"
                      id="email"
                      onChange={(e) => handleChange(e)}
                    />
                     <span className="text-danger">{errors?.email} </span>
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    for="inputretypeemail3"
                    className="col-sm-3 col-md-12 col-lg-3 col-form-label"
                  >
                    RETYPE - EMAIL
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control custom-input"
                      id="retypeemail"
                      onChange={(e) => handleChange(e)}
                    />
                     <span className="text-danger">{errors?.retypeemail} </span>
                  </div>
                </div>
               
                <div className="row mb-3">
                  <label
                    for="inputEmail3"
                    className="col-sm-3 col-md-12 col-lg-3 col-form-label"
                  >
                    MOBILE NUMBER
                  </label>
                  <div className="col-sm-9">
                    {/* <input
                      type="text"
                      className="form-control custom-input"
                      value={userdetails?.mobile_no}
                      id="mobile_no"
                      onChange={(e) => {
                        if (
                          e.target.value == "" ||
                          regex.test(e.target.value)
                        ) {
                          if (e.target.value.length < 13) {
                            handleChange(e);
                          }
                        }
                      }}
                    /> */}
                     <PhoneInput
                                            country='us'
                                            placeholder=''
                                            value={dialcode + userdetails?.mobile_no}
                                            onChange={handlePhoneNumber}
                                            // autoComplete="new-password"
                                            specialLabel={false}
                                        />
                                         <span className="text-danger">{errors?.mobile_no} </span>
                  </div>
                </div>
               
                {/* <div className="row mb-3">
                  <label
                    for="inputdate3"
                    // className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 col-form-label mb-lg-4 mb-xl-0 mb-md-0 mb-sm-0 mb-4"
                    className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 col-form-label mb-xl-4 mb-lg-4 mb-xl-0 mb-md-0 mb-sm-0 mb-4"
                  >
                    SELECT DATE
                  </label>
                  <div
                    // className="col-xl-3 col-lg-9 col-md-9 col-sm-3 col-6"
                    className="col-xl-9 col-xxl-3 col-lg-9 col-md-9 col-sm-3 col-6"
                  >
                    <div className="date-picker">
                      <DatePicker
                        className="custom-date-picker"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                  </div>

                  <label
                    for="inputtime3"
                    // className="col-sm-2 col-lg-3 col-xl-2 col-md-3 col-6 col-form-label"
                    className="col-sm-2 col-lg-3 col-xl-3 col-xxl-2 col-md-3 col-5 col-form-label mb-lg-4"
                  >
                    SELECT TIME
                  </label>
                  <div
                    // className="col-sm-4 col-lg-4 col-md-3 col-xl-4 col-6"
                    className="col-sm-4 col-xxl-3 col-lg-9 col-md-3 col-xl-9 col-6"
                  >
                    <TimePicker
                      className="custom-time-picker"
                      onChange={onChange}
                      value={value}
                    />
                  </div>
                </div> */}
              </div>

              <div className="" style={{ padding: "14px" }}>
                <div class="form-check">
                  <input
                    class="form-check-input custom-input custom-checkbox"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label class="form-check-label ">
                    <p className="ptxt ps-2">
                      Lorem ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </p>
                    <span className="text-danger">{errors?.check1} </span>
                  </label>
                 
                </div>
              
                <div class="form-check">
                  <input
                    class="form-check-input custom-input custom-checkbox"
                    type="checkbox"
                    id="check2"
                    name="option2"
                    value="something"
                    onChange={(e) => handleChange(e)}
                  />
                  <label class="form-check-label">
                    <p className="ptxt ps-2">
                      Lorem ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </p>
                    <span className="text-danger">{errors?.check2} </span>
                  </label>
                </div>
               
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 p-0 second-col-section">
            <div style={{ padding: "14px" }} className="second_section">
              <div className="row mb-4 mt-5 snd-section">
                <label for="inputusername3" className="col-sm-3 col-form-label">
                  USERNAME
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control custom-input"
                    id="user_name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                    <span className="text-danger">{errors?.user_name} </span>
                </div>
              </div>
            
              <div className="row mb-3 mt-4 pt-3">
                <label for="inputPassword3" className="col-sm-3 col-form-label">
                  PASSWORD
                </label>
                <div className="col-sm-9">
                  <div className="password_show_hide">
                    <input
                      type={passwordShow ? "text" : "password"}
                      className="form-control custom-input"
                      id="password"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="text-danger">{errors?.password} </span>
                    <div className="icon_sec">
                      {passwordShow ? (
                        <BiShow
                          onClick={() => setPasswordshow(!passwordShow)}
                        />
                      ) : (
                        <BiHide
                          onClick={() => setPasswordshow(!passwordShow)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="row mb-3">
                <label
                  for="inputretypepassword3"
                  className="col-sm-3 col-form-label "
                >
                  RETYPE - PASSWORD
                </label>
                <div className="col-sm-9">
                  <div className="password_show_hide">
                    <input
                      type={retypePasswordShow ? "text" : "password"}
                      className="form-control custom-input"
                      id="retypepassword"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                      <span className="text-danger">{errors?.retypepassword} </span>
                    <div className="icon_sec">
                      {retypePasswordShow ? (
                        <BiShow
                          onClick={() =>
                            setRetypePasswordshow(!retypePasswordShow)
                          }
                        />
                      ) : (
                        <BiHide
                          onClick={() =>
                            setRetypePasswordshow(!retypePasswordShow)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            

              <div className="row captcha-section">
                <div className="col-lg-8 col-md-8 col-sm-8 col-12">
                  <div className="d-flex register-submit-section justify-content-between align-items-center border-0 p-0">
                    <div class="form-check d-flex align-items-center ps-0">
                      {/* <input
                        class="form-check-input custom-input custom-checkbox"
                        type="checkbox"
                        id="check3"
                        name="option3"
                        value="something"
                      /> */}
                      <Captchaa
                        onchange={(e) => {
                          console.log(e, "Captchaaa");
                          setUserDetails({
                            ...userdetails,
                            ...{ recaptcha: e },
                          });
                        }}
                      />

                      {/* <label class="form-check-label i-am-human-text">
                        i am human
                      </label> */}
                    </div>

                    {/* <div className="captcha-div">
                      <img src={captchalogo} alt="captchalogo" />
                    </div> */}
                  </div>
                  <span className="text-danger">{errors?.recaptcha} </span>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4 col-12 register-button-sec">
                  <div className="commonBtn">
                    <button
                      className="z-2"
                      style={{
                        cursor: "pointer",
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                      onClick={() => UserRegister()}
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
