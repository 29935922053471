import React from "react";

import { p2pTable } from "../../Data/data.js";
import tableTop from "../../asset/IMG/table-top.png";
import dollar from "../../asset/IMG/dollar.png";
import clock from "../../asset/IMG/clock.png";
import view from "../../asset/IMG/view.png";
import deleteImg from "../../asset/IMG/delete.png";
import tableBot from "../../asset/IMG/table-bot.png";

const P2PHistory = () => {
  return (
    <div className="tableContainer">
      {p2pTable.map((item) => (
        <div key={item.id}>
          <div className="table-responsive">
            <table class="table table-bordered table_wallet">
              <tbody>
                <tr className="headerRow">
                  <td rowSpan={2} className="firstTd">
                    {/* <div className="position-absolute table_toprec">
                      <img
                        src={tableTop}
                        alt="rectangle"
                        className="img-fluid"
                      />
                    </div> */}
                    <div className="coin-type d-flex align-items-center justify-content-center">
                      <div className="d-flex align-items-center coin-div">
                        <div className="time-div">
                          <div className="date">
                            <div className="red-dot"></div>
                            <p>{item.date}</p>
                          </div>
                          <div className="month">
                            <p>{item.month}</p>
                          </div>
                        </div>
                        <div className="text-div">
                          <div className="clock">
                            <img
                              src={clock}
                              alt="clock"
                              className="img-fluid"
                            />
                          </div>
                          <div className="time">
                            <p>{item.time}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="tableTitle">Order type</td>

                  <td className="tableTitle">order progress</td>
                  <td className="tableTitle">Status</td>
                  <td className="tableTitle">Action</td>
                </tr>
                <tr className="contentRow">
                  <td>
                    <div className="tableContent">
                      <img src={dollar} alt="dollar badge" />
                      <p>
                        $<span> {item.amount}</span>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="tableContent">
                      <img src={dollar} alt="dollar badge" />
                      <p>
                        $<span> {item.amount}</span>
                      </p>
                    </div>
                  </td>

                  <td>
                    <div className="tableContent">
                      <div>
                        <img
                          src={item.statusImg}
                          alt="success"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <p className="statusColor">{item.status}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tableContent">
                      {/* <div className="rectangleBottom">
                        <img
                          src={tableBot}
                          alt="Rectangle"
                          className="img-fluid"
                        />
                      </div> */}
                      <div
                        className="view d-flex align-items-center gap-2 "
                        style={{ cursor: "pointer" }}
                      >
                        <img src={view} alt="" />
                        <p>View</p>
                      </div>
                      <div
                        className="view d-flex align-items-center gap-2  "
                        style={{ cursor: "pointer" }}
                      >
                        <img src={deleteImg} alt="" />
                        <p>Delete</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default P2PHistory;
