import React from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
  { value: "vanilla", label: "Vanilla" },
];

const MassWithdrawal = () => {
  const InitialForm = {
    FilterCoin: "Select",
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [Formdata, setFormdata] = useState(InitialForm);

  const handleSelect = (eventKey, event) => {
    const id = event.target.id;

    var Form = Formdata;
    if (id == "FilterCoin") {
      Form = { ...Form, ...{ [id]: eventKey } };
      setFormdata(Form);
    } else if (id == "vaccine") {
      Form = { ...Form, ...{ [id]: eventKey } };
      setFormdata(Form);
    }

  };
  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#06038D"
          : isSelected
          ? "#06038D"
          : isFocused
          ? "#06038D"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid lightgray",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  return (
    <div className="massWithdraw">
      <div className="tableContainer">
        <div className="table-responsive massWithdraw_tableHeight">
          <table class="table table-bordered table_wallet">
            <tbody>
              <tr className="headerRow">
                <td className="tableTitle" style={{ textAlign: "start" }}>
                  Mass Withdrawal File (CSV)
                </td>

                <td className="tableTitle" style={{ textAlign: "start" }}>
                  Fee Mode
                </td>
                <td className="tableTitle" style={{ textAlign: "start" }}>
                  Submit
                </td>
              </tr>
              <tr className="contentRow">
                {/* <td style={{ textAlign: "start", padding: "20px" }}>
                  <div className="d-flex align-items-center gap-3 upload-btn">
                    <buton className="upload-btn-img">
                      <input
                        type="file"
                        id="customFile"
                        style={{ opacity: "0" }}
                      />
                      <p style={{ fontSize: "10.5px", fontWeight: "600" }}>
                        upload
                      </p>
                    </buton>
                  </div>
                </td> */}
                <td style={{ textAlign: "start", padding: "20px" }}>
                  <div className="d-flex align-items-center gap-3 upload-btn">
                    <input
                      type="file"
                      id="customFile"
                      placeholder="Upload File"
                    />
                  </div>
                </td>
                <td style={{ textAlign: "start", padding: "20px" }}>
                  {/* <div className="dropdown">
                    <div
                      className=" dropdown-toggle d-flex align-items-center z-2 dropDownNew"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <p className="d-none d-lg-block">Filter coins</p>
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="/">
                          Show all coins with a Balance
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Show all coins Except ERC20
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Show all coins
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  {/* <Form.Select
                    aria-label="Default select example"
                    // disabled={true}
                  >
                    <option>
                      Send amount specified in file (add TX fee to amount)
                    </option>
                    <option value="1">
                      Send amount specified in file (add TX fee to amount) one
                    </option>
                    <option value="2">
                      Send amount specified in file (add TX fee to amount) Two
                    </option>
                  </Form.Select> */}
                  {/* <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    styles={stylesgraybg}
                    menuIsOpen={true}
                    className="react_select"
                  /> */}
                  <Dropdown className="allselectmenu" onSelect={handleSelect}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="dropdwon_slt"
                    >
                      {Formdata.FilterCoin ? Formdata.FilterCoin : "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="drpdwn_menu">
                      <Dropdown.Item
                        id="FilterCoin"
                        eventKey="Send amount specified in file (add TX fee to amount) one"
                      >
                        <p>
                          Send amount specified in file (add TX fee to amount)
                          One
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item
                        id="FilterCoin"
                        eventKey="Send amount specified in file (add TX fee to amount) two"
                      >
                        <p>
                          Send amount specified in file (add TX fee to amount)
                          two
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item
                        id="FilterCoin"
                        eventKey="Send amount specified in file (add TX fee to amount) Three"
                      >
                        <p>
                          Send amount specified in file (add TX fee to amount)
                          three
                        </p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td style={{ textAlign: "start", padding: "20px" }}>
                  {" "}
                  {/* <div className="d-flex align-items-center gap-3 upload-btn">
                    <buton className="upload-btn-img">
                      <p style={{ fontSize: "10.5px", fontWeight: "600" }}>
                        upload File
                      </p>
                    </buton>
                    <p className="upload_txt">Lorem Ipsum</p>
                  </div> */}
                  <div className="d-flex align-items-center position-relative gap-3 upload-btn">
                    <input
                      type="file"
                      id="customFile"
                      placeholder="Upload File"
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ textAlign: "center" }}>
                <td colSpan={3}>
                  <p className="bot_txt">
                    File should be a CSV file in <span>this format</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MassWithdrawal;
