import { isEmpty } from "../Helper/isEmpty";

export const userValidation = async (data) => {
    try {
        let alphabetOnly = /^[A-Za-z]+$/
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
        let isValid = true;
        let errors = {};
        const { filter } = data;
        if (filter == "resetpassword") {

            if (isEmpty(data?.user_name)) {
                isValid = false;
                errors.user_name = "Name field is required"
            }

            if (isEmpty(data?.password)) {
                isValid = false;
                errors.password = "Password field is required"
            } else if (!passwordRegex.test(data?.password)) {
                errors.password = "6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
            }

         
            if (isEmpty(data?.confirmpassword)) {
                isValid = false;
                errors.confirmpassword = "Confirmpassword field is required"
            } else if (!passwordRegex.test(data?.confirmpassword)) {
                errors.confirmpassword = "6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
            }

            if (!isEmpty(data?.password) && !isEmpty(data?.confirmpassword)) {
                if (data?.password != data?.confirmpassword) {
                    errors.confirmpassword = "Both password must be same"
                }
            }

        } else {
            if (isEmpty(data?.email)) {
                errors.email = "Email field is required"
            } else if (!emailRegex.test(data?.email)) {
                errors.email = "Invalid email Address!"
            }

            if (isEmpty(data?.retypeemail)) {
                errors.retypeemail = "Retype-email field is required"
            } else if (!emailRegex.test(data?.retypeemail)) {
                errors.retypeemail = "Invalid retypeemail Address!"
            }

            if (!isEmpty(data?.email) && !isEmpty(data?.retypeemail)) {
                if (data?.email != data?.retypeemail) {
                    errors.retypeemail = "Both email must be same"
                }
            }
            if (isEmpty(data?.mobile_no)) {
                isValid = false;
                errors.mobile_no = "Mobile Number field is required"
            }


            if (isEmpty(data?.password)) {
                isValid = false;
                errors.password = "Password field is required"
            } else if (!passwordRegex.test(data?.password)) {
                errors.password = "6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
            }


            if (isEmpty(data?.retypepassword)) {
                isValid = false;
                errors.retypepassword = "Retype-password field is required"
            } else if (!passwordRegex.test(data?.retypepassword)) {
                errors.retypepassword = "6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
            }

            if (!isEmpty(data?.password) && !isEmpty(data?.retypepassword)) {
                if (data?.password != data?.retypepassword) {
                    errors.retypepassword = "Both password must be same"
                }
            }

            if (isEmpty(data?.recaptcha)) {
                isValid = false;
                errors.recaptcha = "Recaptcha field is required"
            }

            if (isEmpty(data?.user_name)) {
                isValid = false;
                errors.user_name = "Name field is required"
            }

            if (!(data?.check1) || isEmpty(data?.check1)) {
                isValid = false;
                errors.check1 = "Please tick the required filled"
            }

            if (!(data?.check2) || isEmpty(data?.check2)) {
                isValid = false;
                errors.check2 = "Please tick the required filled"
            }
            if (data?.filter == "otp") {
                if (isEmpty(data?.otp)) {
                    isValid = false;
                    errors.otp = "OTP  is required"
                } else if (alphabetOnly.test(data?.otp)) {
                    isValid = false;
                    errors.otp = "OTP is required"
                } else {
                    if ((data?.otp)?.length < 4) {
                        isValid = false;
                        errors.otp = "Enter 4 digits"
                    }
                }
            }

        }

        return {
            errors,
            isValid: isEmpty(errors)
        };
    } catch (e) {
        console.log("UserRegisterValidation_err", e)
    }
}


export const profileUpdateValidation = async (data) => {
    try {
        let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
        const { email, confirmemail, gender, timezone, dateformat, timeformat, username, firstName, lastName, mobileNo, logoURI, filter, currentpassword, newpassword, confirmpassword, 
            addressname, address, address2, country, state, city, zipcode, defaultaddress, recoveryemail,
            ipnsecret, ipnurl, ipnfiatcurrency, receiveemail, logemail,
            publicname, publicemail, publicurl, usegravata} = data;
            console.log("recoveryemaildata", recoveryemail, !(isEmpty(recoveryemail)))

            console.log("asdfasdfsad", mobileNo, String(mobileNo)?.length <= 9)
            console.log("mobileNO", mobileNo)
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        let isValid = true;
        let errors = {};

        if(filter == "profileupdate") {
            if (isEmpty(username)) {
                isValid = false;
                errors.username = "User Name field is required"
            }
    
            if (isEmpty(firstName)) {
                isValid = false;
                errors.firstName = "First Name field is required"
            }
    
            if (isEmpty(lastName)) {
                isValid = false;
                errors.lastName = "Last Name field is required"
            }
    
            if (isEmpty(email)) {
                errors.email = "Email field is required"
            } else if (!emailRegex.test(email)) {
                errors.email = "Invalid email Address!"
            }
    
            if (isEmpty(confirmemail)) {
                errors.confirmemail = "ConfrimEmail field is required"
            } else if (!emailRegex.test(confirmemail)) {
                errors.confirmemail = "Invalid confrimemail Address!"
            }
    
            if (!isEmpty(email) && !isEmpty(confirmemail)) {
                if (email != confirmemail) {
                    errors.confirmemail = "Both email must be same"
                }
            }

            if (isEmpty(mobileNo)) {
                isValid = false;
                errors.mobileNo = "Mobile Number field is required"
            } else if ((String(mobileNo)?.length <= 6)) {
                console.log("asdfasdfsad", mobileNo, String(mobileNo)?.length <= 9)
                isValid = false;
                errors.mobileNo = "Enter Atleast 10 digit"
            }
    
            if (isEmpty(gender)) {
                isValid = false;
                errors.gender = "Gender field is required"
            }
    
    
            if (isEmpty(timezone)) {
                isValid = false;
                errors.timezone = "Timezone field is required"
            }
    
            if (isEmpty(dateformat)) {
                isValid = false;
                errors.dateformat = "DateFormat field is required"
            }
    
            if (isEmpty(timeformat)) {
                isValid = false;
                errors.timeformat = "TimeFormat field is required"
            }
    
            if (typeof logoURI == "string" && (logoURI == "" || logoURI == null || logoURI == undefined)) {
                isValid = false;
                errors.logoURI = "Image is required"
            } else if (typeof logoURI == "object") {
                if(logoURI && Object.values(logoURI)?.length > 0) {
                    let fileName = logoURI?.name;
                    var idxDot = fileName.lastIndexOf(".") + 1;
                    var extFile = fileName.substr(idxDot, fileName?.length).toLowerCase();
                    if (!(extFile == "jpg" || extFile == "jpeg" || extFile == "png")) {
                        errors.logoURI = "Allowed Only jpeg, jpeg, png"
                    }
                }
            } 
            console.log("logoURIlogoURI",logoURI?.name,Object.values(logoURI)?.length, errors)
        } else if(filter == "passwordupdate"){

            if(!(isEmpty(currentpassword)) || !(isEmpty(newpassword)) || !(isEmpty(confirmpassword))) {
                if (isEmpty(currentpassword)) {
                    isValid = false;
                    errors.currentpassword = "Current password field is required"
                } else if (!passwordRegex.test(currentpassword)) {
                    errors.currentpassword = "6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
                }
    
                if (isEmpty(newpassword)) {
                    isValid = false;
                    errors.newpassword = "Password field is required"
                } else if (!passwordRegex.test(newpassword)) {
                    errors.newpassword = "6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
                }
    
                if (isEmpty(confirmpassword)) {
                    isValid = false;
                    errors.confirmpassword = "Confirm password field is required"
                } else if (!passwordRegex.test(confirmpassword)) {
                    errors.confirmpassword = "6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
                }
                if (!isEmpty(newpassword) && !isEmpty(confirmpassword)) {
                    console.log("newpassword != confirmpassword)", newpassword, confirmpassword)
                    if (newpassword != confirmpassword) {
                        errors.confirmpassword = "Both password must be same"
                    }
                }
            } else if (!(isEmpty(recoveryemail))) {
                if (isEmpty(recoveryemail)) {
                    errors.recoveryemail = "RecoveryEmail field is required"
                } else if (!emailRegex.test(recoveryemail)) {
                    errors.recoveryemail = "Invalid email Address!"
                }
            }

        } else if(filter == "addressupdate") {
            if (isEmpty(addressname)) {
                isValid = false;
                errors.addressname = "AddressName field is required"
            }
    
            if (isEmpty(address)) {
                isValid = false;
                errors.address = "Address field is required"
            }
    
    
            if (isEmpty(address2)) {
                isValid = false;
                errors.address2 = "Address field is required"
            }
    
            if (isEmpty(country)) {
                isValid = false;
                errors.country = "Country field is required"
            }

            if (isEmpty(state)) {
                isValid = false;
                errors.state = "State field is required"
            }

            if (isEmpty(city)) {
                isValid = false;
                errors.city = "City field is required"
            }

            if (isEmpty(zipcode)) {
                isValid = false;
                errors.zipcode = "Zipcode field is required"
            }

            if (isEmpty(defaultaddress)) {
                isValid = false;
                errors.defaultaddress = "DefaultAddress field is required"
            }

            if (isEmpty(mobileNo)) {
                isValid = false;
                errors.mobileNo = "Mobile Number field is required"
            }

            if (isEmpty(firstName)) {
                isValid = false;
                errors.firstName = "First Name field is required"
            }
    
            if (isEmpty(lastName)) {
                isValid = false;
                errors.lastName = "Last Name field is required"
            }


        } else if (filter == "merchantupdate") {
            if (isEmpty(ipnsecret)) {
                isValid = false;
                errors.ipnsecret = "IpnSecret field is required"
            }

            if (isEmpty(ipnurl)) {
                isValid = false;
                errors.ipnurl = "IpnUrl field is required"
            }

            if (isEmpty(ipnfiatcurrency)) {
                isValid = false;
                errors.ipnfiatcurrency = "FiatCurrency field is required"
            }

            if (receiveemail) {
                if (Object.values(receiveemail)?.length > 0) {
                    console.log("Object.values(receiveemail)[0]", Object.values(receiveemail), Object.values(receiveemail)[0])
                    if (!(Object.values(receiveemail)[0] || Object.values(receiveemail)[1] || Object.values(receiveemail)[2] || Object.values(receiveemail)[3] || Object.values(receiveemail)[4])) {
                        isValid = false;
                        errors.receiveemail = "Choose Any ReceiveEmail field is required"
                    }
                }

            }

            if (isEmpty(logemail)) {
                errors.logemail = "Email field is required"
            } else if (!emailRegex.test(logemail)) {
                errors.logemail = "Invalid email Address!"
            }
            
        } else if (filter == "publicinfoupdate") {

            if (isEmpty(publicname)) {
                isValid = false;
                errors.publicname = "PublicName field is required"
            }

            if (isEmpty(publicemail)) {
                errors.publicemail = "PublicEmail field is required"
            } else if (!emailRegex.test(publicemail)) {
                errors.publicemail = "Invalid email Address!"
            }

            if(!(usegravata)) {
                isValid = false;
                errors.usegravata = "UseGravata field is required"
            }

            if (isEmpty(publicurl)) {
                isValid = false;
                errors.publicurl = "PublicUrl field is required"
            }

        }

        return {
            errors,
            isValid: isEmpty(errors)
        };
    } catch (e) {
        console.log("profileUpdateValidation_err", e)
    }
}



export const FeedBackValidation = async (data) => {
    try {

        let isValid = true;
        let errors = {};

        const { comments, country, ratings } = data;
        console.log("feedback data", data)
      

        if (isEmpty(comments)) {
            isValid = false;
            errors.comments = "Comments  is required"
        }


        if (isEmpty(country)) {
            isValid = false;
            errors.country = "Country  is required"
        }

        if (ratings <= 0) {
            isValid = false;
            errors.ratings = "Ratings  is required"
        }
        console.log("errorsdatsgal", errors)
        return {
            errors,
            isValid: isEmpty(errors)
        };
    } catch (e) {
        console.log("FeedBackValidation_Err", e)
    }
}

const MinuteHourFormat = (data) => {
    return ((Number(isEmpty(data) ? 0 : data ) < 10 ? '0' : '')+data)
  }

  
  export const DateTimeForm = (date,datealone,timealone,ampm) => {
    try{
      if(datealone){
        return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}`
      }
      else if(timealone){
        if(ampm){
          return `${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours()) }:${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? 'PM' : 'AM' }`
        }
        else{
          return `${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
        }
      }
      else if(ampm){
        return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}, ${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes()) } ${new Date(date)?.getHours() >= 12 ? 'PM' : 'AM'} `
      }
      return `${MinuteHourFormat(new Date(date)?.getDate())}:${MinuteHourFormat(new Date(date)?.getMonth()+1)}:${new Date(date)?.getFullYear()},${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
    }
    catch(err){
      console.log('dskhfskfs',err)
      return "No Date"
    }
  }
export const DateTimeFormat1 =  (currentdate, ampm) => {
    if(ampm) {
        return `${MinuteHourFormat(currentdate?.getHours() > 12 ? currentdate?.getHours() - 12 : currentdate?.getHours()) }:${MinuteHourFormat(currentdate.getMinutes())}:${MinuteHourFormat(currentdate.getSeconds())}`
    } else {
        return `${MinuteHourFormat(currentdate.getHours())}:${MinuteHourFormat(currentdate.getMinutes())}:${MinuteHourFormat(currentdate.getSeconds())} ${new Date(currentdate)?.getHours() >= 12 ? 'PM' : 'AM'}`
    }
}








var aryIanaTimeZones = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
  ];
export const countryList = [
    {
        "country": "Afghanistan",
        "currency_code": "AFN"
    },
    {
        "country": "Albania",
        "currency_code": "ALL"
    },
    {
        "country": "Algeria",
        "currency_code": "DZD"
    },
    {
        "country": "American Samoa",
        "currency_code": "USD"
    },
    {
        "country": "Andorra",
        "currency_code": "EUR"
    },
    {
        "country": "Angola",
        "currency_code": "AOA"
    },
    {
        "country": "Anguilla",
        "currency_code": "XCD"
    },
    {
        "country": "Antarctica",
        "currency_code": "XCD"
    },
    {
        "country": "Antigua and Barbuda",
        "currency_code": "XCD"
    },
    {
        "country": "Argentina",
        "currency_code": "ARS"
    },
    {
        "country": "Armenia",
        "currency_code": "AMD"
    },
    {
        "country": "Aruba",
        "currency_code": "AWG"
    },
    {
        "country": "Australia",
        "currency_code": "AUD"
    },
    {
        "country": "Austria",
        "currency_code": "EUR"
    },
    {
        "country": "Azerbaijan",
        "currency_code": "AZN"
    },
    {
        "country": "Bahamas",
        "currency_code": "BSD"
    },
    {
        "country": "Bahrain",
        "currency_code": "BHD"
    },
    {
        "country": "Bangladesh",
        "currency_code": "BDT"
    },
    {
        "country": "Barbados",
        "currency_code": "BBD"
    },
    {
        "country": "Belarus",
        "currency_code": "BYR"
    },
    {
        "country": "Belgium",
        "currency_code": "EUR"
    },
    {
        "country": "Belize",
        "currency_code": "BZD"
    },
    {
        "country": "Benin",
        "currency_code": "XOF"
    },
    {
        "country": "Bermuda",
        "currency_code": "BMD"
    },
    {
        "country": "Bhutan",
        "currency_code": "BTN"
    },
    {
        "country": "Bolivia",
        "currency_code": "BOB"
    },
    {
        "country": "Bosnia and Herzegovina",
        "currency_code": "BAM"
    },
    {
        "country": "Botswana",
        "currency_code": "BWP"
    },
    {
        "country": "Bouvet Island",
        "currency_code": "NOK"
    },
    {
        "country": "Brazil",
        "currency_code": "BRL"
    },
    {
        "country": "British Indian Ocean Territory",
        "currency_code": "USD"
    },
    {
        "country": "Brunei",
        "currency_code": "BND"
    },
    {
        "country": "Bulgaria",
        "currency_code": "BGN"
    },
    {
        "country": "Burkina Faso",
        "currency_code": "XOF"
    },
    {
        "country": "Burundi",
        "currency_code": "BIF"
    },
    {
        "country": "Cambodia",
        "currency_code": "KHR"
    },
    {
        "country": "Cameroon",
        "currency_code": "XAF"
    },
    {
        "country": "Canada",
        "currency_code": "CAD"
    },
    {
        "country": "Cape Verde",
        "currency_code": "CVE"
    },
    {
        "country": "Cayman Islands",
        "currency_code": "KYD"
    },
    {
        "country": "Central African Republic",
        "currency_code": "XAF"
    },
    {
        "country": "Chad",
        "currency_code": "XAF"
    },
    {
        "country": "Chile",
        "currency_code": "CLP"
    },
    {
        "country": "China",
        "currency_code": "CNY"
    },
    {
        "country": "Christmas Island",
        "currency_code": "AUD"
    },
    {
        "country": "Cocos (Keeling) Islands",
        "currency_code": "AUD"
    },
    {
        "country": "Colombia",
        "currency_code": "COP"
    },
    {
        "country": "Comoros",
        "currency_code": "KMF"
    },
    {
        "country": "Congo",
        "currency_code": "XAF"
    },
    {
        "country": "Cook Islands",
        "currency_code": "NZD"
    },
    {
        "country": "Costa Rica",
        "currency_code": "CRC"
    },
    {
        "country": "Croatia",
        "currency_code": "EUR"
    },
    {
        "country": "Cuba",
        "currency_code": "CUP"
    },
    {
        "country": "Cyprus",
        "currency_code": "EUR"
    },
    {
        "country": "Czech Republic",
        "currency_code": "CZK"
    },
    {
        "country": "Denmark",
        "currency_code": "DKK"
    },
    {
        "country": "Djibouti",
        "currency_code": "DJF"
    },
    {
        "country": "Dominica",
        "currency_code": "XCD"
    },
    {
        "country": "Dominican Republic",
        "currency_code": "DOP"
    },
    {
        "country": "East Timor",
        "currency_code": "USD"
    },
    {
        "country": "Ecuador",
        "currency_code": "ECS"
    },
    {
        "country": "Egypt",
        "currency_code": "EGP"
    },
    {
        "country": "El Salvador",
        "currency_code": "SVC"
    },
    {
        "country": "England",
        "currency_code": "GBP"
    },
    {
        "country": "Equatorial Guinea",
        "currency_code": "XAF"
    },
    {
        "country": "Eritrea",
        "currency_code": "ERN"
    },
    {
        "country": "Estonia",
        "currency_code": "EUR"
    },
    {
        "country": "Ethiopia",
        "currency_code": "ETB"
    },
    {
        "country": "Falkland Islands",
        "currency_code": "FKP"
    },
    {
        "country": "Faroe Islands",
        "currency_code": "DKK"
    },
    {
        "country": "Fiji Islands",
        "currency_code": "FJD"
    },
    {
        "country": "Finland",
        "currency_code": "EUR"
    },
    {
        "country": "France",
        "currency_code": "EUR"
    },
    {
        "country": "French Guiana",
        "currency_code": "EUR"
    },
    {
        "country": "French Polynesia",
        "currency_code": "XPF"
    },
    {
        "country": "French Southern territories",
        "currency_code": "EUR"
    },
    {
        "country": "Gabon",
        "currency_code": "XAF"
    },
    {
        "country": "Gambia",
        "currency_code": "GMD"
    },
    {
        "country": "Georgia",
        "currency_code": "GEL"
    },
    {
        "country": "Germany",
        "currency_code": "EUR"
    },
    {
        "country": "Ghana",
        "currency_code": "GHS"
    },
    {
        "country": "Gibraltar",
        "currency_code": "GIP"
    },
    {
        "country": "Greece",
        "currency_code": "EUR"
    },
    {
        "country": "Greenland",
        "currency_code": "DKK"
    },
    {
        "country": "Grenada",
        "currency_code": "XCD"
    },
    {
        "country": "Guadeloupe",
        "currency_code": "EUR"
    },
    {
        "country": "Guam",
        "currency_code": "USD"
    },
    {
        "country": "Guatemala",
        "currency_code": "QTQ"
    },
    {
        "country": "Guinea",
        "currency_code": "GNF"
    },
    {
        "country": "Guinea-Bissau",
        "currency_code": "CFA"
    },
    {
        "country": "Guyana",
        "currency_code": "GYD"
    },
    {
        "country": "Haiti",
        "currency_code": "HTG"
    },
    {
        "country": "Heard Island and McDonald Islands",
        "currency_code": "AUD"
    },
    {
        "country": "Holy See (Vatican City State)",
        "currency_code": "EUR"
    },
    {
        "country": "Honduras",
        "currency_code": "HNL"
    },
    {
        "country": "Hong Kong",
        "currency_code": "HKD"
    },
    {
        "country": "Hungary",
        "currency_code": "HUF"
    },
    {
        "country": "Iceland",
        "currency_code": "ISK"
    },
    {
        "country": "India",
        "currency_code": "INR"
    },
    {
        "country": "Indonesia",
        "currency_code": "IDR"
    },
    {
        "country": "Iran",
        "currency_code": "IRR"
    },
    {
        "country": "Iraq",
        "currency_code": "IQD"
    },
    {
        "country": "Ireland",
        "currency_code": "EUR"
    },
    {
        "country": "Israel",
        "currency_code": "ILS"
    },
    {
        "country": "Italy",
        "currency_code": "EUR"
    },
    {
        "country": "Ivory Coast",
        "currency_code": "XOF"
    },
    {
        "country": "Jamaica",
        "currency_code": "JMD"
    },
    {
        "country": "Japan",
        "currency_code": "JPY"
    },
    {
        "country": "Jordan",
        "currency_code": "JOD"
    },
    {
        "country": "Kazakhstan",
        "currency_code": "KZT"
    },
    {
        "country": "Kenya",
        "currency_code": "KES"
    },
    {
        "country": "Kiribati",
        "currency_code": "AUD"
    },
    {
        "country": "Kuwait",
        "currency_code": "KWD"
    },
    {
        "country": "Kyrgyzstan",
        "currency_code": "KGS"
    },
    {
        "country": "Laos",
        "currency_code": "LAK"
    },
    {
        "country": "Latvia",
        "currency_code": "EUR"
    },
    {
        "country": "Lebanon",
        "currency_code": "LBP"
    },
    {
        "country": "Lesotho",
        "currency_code": "LSL"
    },
    {
        "country": "Liberia",
        "currency_code": "LRD"
    },
    {
        "country": "Libyan Arab Jamahiriya",
        "currency_code": "LYD"
    },
    {
        "country": "Liechtenstein",
        "currency_code": "CHF"
    },
    {
        "country": "Lithuania",
        "currency_code": "EUR"
    },
    {
        "country": "Luxembourg",
        "currency_code": "EUR"
    },
    {
        "country": "Macau",
        "currency_code": "MOP"
    },
    {
        "country": "North Macedonia",
        "currency_code": "MKD"
    },
    {
        "country": "Madagascar",
        "currency_code": "MGF"
    },
    {
        "country": "Malawi",
        "currency_code": "MWK"
    },
    {
        "country": "Malaysia",
        "currency_code": "MYR"
    },
    {
        "country": "Maldives",
        "currency_code": "MVR"
    },
    {
        "country": "Mali",
        "currency_code": "XOF"
    },
    {
        "country": "Malta",
        "currency_code": "EUR"
    },
    {
        "country": "Marshall Islands",
        "currency_code": "USD"
    },
    {
        "country": "Martinique",
        "currency_code": "EUR"
    },
    {
        "country": "Mauritania",
        "currency_code": "MRO"
    },
    {
        "country": "Mauritius",
        "currency_code": "MUR"
    },
    {
        "country": "Mayotte",
        "currency_code": "EUR"
    },
    {
        "country": "Mexico",
        "currency_code": "MXN"
    },
    {
        "country": "Micronesia, Federated States of",
        "currency_code": "USD"
    },
    {
        "country": "Moldova",
        "currency_code": "MDL"
    },
    {
        "country": "Monaco",
        "currency_code": "EUR"
    },
    {
        "country": "Mongolia",
        "currency_code": "MNT"
    },
    {
        "country": "Montserrat",
        "currency_code": "XCD"
    },
    {
        "country": "Morocco",
        "currency_code": "MAD"
    },
    {
        "country": "Mozambique",
        "currency_code": "MZN"
    },
    {
        "country": "Myanmar",
        "currency_code": "MMR"
    },
    {
        "country": "Namibia",
        "currency_code": "NAD"
    },
    {
        "country": "Nauru",
        "currency_code": "AUD"
    },
    {
        "country": "Nepal",
        "currency_code": "NPR"
    },
    {
        "country": "Netherlands",
        "currency_code": "EUR"
    },
    {
        "country": "Netherlands Antilles",
        "currency_code": "ANG"
    },
    {
        "country": "New Caledonia",
        "currency_code": "XPF"
    },
    {
        "country": "New Zealand",
        "currency_code": "NZD"
    },
    {
        "country": "Nicaragua",
        "currency_code": "NIO"
    },
    {
        "country": "Niger",
        "currency_code": "XOF"
    },
    {
        "country": "Nigeria",
        "currency_code": "NGN"
    },
    {
        "country": "Niue",
        "currency_code": "NZD"
    },
    {
        "country": "Norfolk Island",
        "currency_code": "AUD"
    },
    {
        "country": "North Korea",
        "currency_code": "KPW"
    },
    {
        "country": "Northern Ireland",
        "currency_code": "GBP"
    },
    {
        "country": "Northern Mariana Islands",
        "currency_code": "USD"
    },
    {
        "country": "Norway",
        "currency_code": "NOK"
    },
    {
        "country": "Oman",
        "currency_code": "OMR"
    },
    {
        "country": "Pakistan",
        "currency_code": "PKR"
    },
    {
        "country": "Palau",
        "currency_code": "USD"
    },
    {
        "country": "Palestine",
        "currency_code": null
    },
    {
        "country": "Panama",
        "currency_code": "PAB"
    },
    {
        "country": "Papua New Guinea",
        "currency_code": "PGK"
    },
    {
        "country": "Paraguay",
        "currency_code": "PYG"
    },
    {
        "country": "Peru",
        "currency_code": "PEN"
    },
    {
        "country": "Philippines",
        "currency_code": "PHP"
    },
    {
        "country": "Pitcairn Islands",
        "currency_code": "NZD"
    },
    {
        "country": "Poland",
        "currency_code": "PLN"
    },
    {
        "country": "Portugal",
        "currency_code": "EUR"
    },
    {
        "country": "Puerto Rico",
        "currency_code": "USD"
    },
    {
        "country": "Qatar",
        "currency_code": "QAR"
    },
    {
        "country": "Reunion",
        "currency_code": "EUR"
    },
    {
        "country": "Romania",
        "currency_code": "RON"
    },
    {
        "country": "Russian Federation",
        "currency_code": "RUB"
    },
    {
        "country": "Rwanda",
        "currency_code": "RWF"
    },
    {
        "country": "Saint Helena",
        "currency_code": "SHP"
    },
    {
        "country": "Saint Kitts and Nevis",
        "currency_code": "XCD"
    },
    {
        "country": "Saint Lucia",
        "currency_code": "XCD"
    },
    {
        "country": "Saint Pierre and Miquelon",
        "currency_code": "EUR"
    },
    {
        "country": "Saint Vincent and the Grenadines",
        "currency_code": "XCD"
    },
    {
        "country": "Samoa",
        "currency_code": "WST"
    },
    {
        "country": "San Marino",
        "currency_code": "EUR"
    },
    {
        "country": "Sao Tome and Principe",
        "currency_code": "STD"
    },
    {
        "country": "Saudi Arabia",
        "currency_code": "SAR"
    },
    {
        "country": "Scotland",
        "currency_code": "GBP"
    },
    {
        "country": "Senegal",
        "currency_code": "XOF"
    },
    {
        "country": "Serbia",
        "currency_code": "RSD"
    },
    {
        "country": "Seychelles",
        "currency_code": "SCR"
    },
    {
        "country": "Sierra Leone",
        "currency_code": "SLL"
    },
    {
        "country": "Singapore",
        "currency_code": "SGD"
    },
    {
        "country": "Slovakia",
        "currency_code": "EUR"
    },
    {
        "country": "Slovenia",
        "currency_code": "EUR"
    },
    {
        "country": "Solomon Islands",
        "currency_code": "SBD"
    },
    {
        "country": "Somalia",
        "currency_code": "SOS"
    },
    {
        "country": "South Africa",
        "currency_code": "ZAR"
    },
    {
        "country": "South Georgia and the South Sandwich Islands",
        "currency_code": "GBP"
    },
    {
        "country": "South Korea",
        "currency_code": "KRW"
    },
    {
        "country": "South Sudan",
        "currency_code": "SSP"
    },
    {
        "country": "Spain",
        "currency_code": "EUR"
    },
    {
        "country": "Sri Lanka",
        "currency_code": "LKR"
    },
    {
        "country": "Sudan",
        "currency_code": "SDG"
    },
    {
        "country": "Suriname",
        "currency_code": "SRD"
    },
    {
        "country": "Svalbard and Jan Mayen",
        "currency_code": "NOK"
    },
    {
        "country": "Swaziland",
        "currency_code": "SZL"
    },
    {
        "country": "Sweden",
        "currency_code": "SEK"
    },
    {
        "country": "Switzerland",
        "currency_code": "CHF"
    },
    {
        "country": "Syria",
        "currency_code": "SYP"
    },
    {
        "country": "Tajikistan",
        "currency_code": "TJS"
    },
    {
        "country": "Tanzania",
        "currency_code": "TZS"
    },
    {
        "country": "Thailand",
        "currency_code": "THB"
    },
    {
        "country": "The Democratic Republic of Congo",
        "currency_code": "CDF"
    },
    {
        "country": "Togo",
        "currency_code": "XOF"
    },
    {
        "country": "Tokelau",
        "currency_code": "NZD"
    },
    {
        "country": "Tonga",
        "currency_code": "TOP"
    },
    {
        "country": "Trinidad and Tobago",
        "currency_code": "TTD"
    },
    {
        "country": "Tunisia",
        "currency_code": "TND"
    },
    {
        "country": "Turkey",
        "currency_code": "TRY"
    },
    {
        "country": "Turkmenistan",
        "currency_code": "TMT"
    },
    {
        "country": "Turks and Caicos Islands",
        "currency_code": "USD"
    },
    {
        "country": "Tuvalu",
        "currency_code": "AUD"
    },
    {
        "country": "Uganda",
        "currency_code": "UGX"
    },
    {
        "country": "Ukraine",
        "currency_code": "UAH"
    },
    {
        "country": "United Arab Emirates",
        "currency_code": "AED"
    },
    {
        "country": "United Kingdom",
        "currency_code": "GBP"
    },
    {
        "country": "United States",
        "currency_code": "USD"
    },
    {
        "country": "United States Minor Outlying Islands",
        "currency_code": "USD"
    },
    {
        "country": "Uruguay",
        "currency_code": "UYU"
    },
    {
        "country": "Uzbekistan",
        "currency_code": "UZS"
    },
    {
        "country": "Vanuatu",
        "currency_code": "VUV"
    },
    {
        "country": "Venezuela",
        "currency_code": "VEF"
    },
    {
        "country": "Vietnam",
        "currency_code": "VND"
    },
    {
        "country": "Virgin Islands, British",
        "currency_code": "USD"
    },
    {
        "country": "Virgin Islands, U.S.",
        "currency_code": "USD"
    },
    {
        "country": "Wales",
        "currency_code": "GBP"
    },
    {
        "country": "Wallis and Futuna",
        "currency_code": "XPF"
    },
    {
        "country": "Western Sahara",
        "currency_code": "MAD"
    },
    {
        "country": "Yemen",
        "currency_code": "YER"
    },
    {
        "country": "Zambia",
        "currency_code": "ZMW"
    },
    {
        "country": "Zimbabwe",
        "currency_code": "ZWD"
    }
]