import React from "react";
import "../../Styles/Fees.css";
import userCollection from "../../asset/IMG/fees-headsec-img.png";
import vectorimg from "../../asset/IMG/Fees/Vector.png";
import polygon1 from "../../asset/IMG/Fees/Polygon 1(1).png";
import polygon2 from "../../asset/IMG/Fees/Polygon 2.png";
import star from "../../asset/IMG/Fees/Star 1(1).png";
import line from "../../asset/IMG/Fees/Group 3(1).png";
import headSecline from "../../asset/IMG/Fees/line_logo.png";

import shoppingcart from "../../asset/IMG/Fees/shopping-cart.png";
import paymentcloud from "../../asset/IMG/Fees/payment-gateway-cloud.png";
import paymenticon from "../../asset/IMG/Fees/Payment-gateway.png";
import api from "../../asset/IMG/Fees/API.png";
import bitcollectionBg from "../../asset/IMG/Fees/Group 109(1).png";

import invoice from "../../asset/IMG/Fees/Invoice-builder.png";
import mobileapp from "../../asset/IMG/Fees/mobile-app.png";
import pointofsale from "../../asset/IMG/Fees/point-of-sale.png";

import chatsupport from "../../asset/IMG/Fees/chat-support.png";
import faq from "../../asset/IMG/Fees/FAQS.png";
import blog from "../../asset/IMG/Fees/BLOG.png";

import shortline from "../../asset/IMG/Fees/left-top-icon.png";
import bigline from "../../asset/IMG/Fees/big-line-bar.png";
import Footer from "../Layout/Footer";
function Merchant() {
  return (
    <>
      <div className="fees-page merchant">
        <div
          className="head-section position-relative"
          style={{ overflowX: "clip" }}
        >
          {/* <div className="polycon-fst-img z-2">
            <img src={polygon1} />
          </div> */}
          {/* <div className="star-img z-2">
            <img src={star} />
          </div> */}
          {/* <div className="line-img">
            <img src={headSecline} />
          </div> */}
          {/* <div className="bitcollection-img">
            <img src={bitcollectionBg} />
          </div> */}
          <div className="container position-relative merchant_paddings">
            <div className="row">
              <div className="col-lg-7 d-flex align-items-center">
                <div className="first-sec">
                  <h2>
                    <span className="one">variety of ways </span>to accept
                    crypto <br></br> payments using{" "}
                    <span className="loreum">COINPAYMATE</span>
                  </h2>
                  <p>
                    Lorem ipsum is simply dummy text of the printing and
                    typesetting industry. <br></br>Lorem ipsum has been the
                    industry's.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="snd-section">
                  <img src={userCollection} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="incoming-payments py-5 position-relative"
          style={{ overflowX: "clip" }}
        >
          {/* <div className="polycon-fst-img z-2">
            <img src={polygon1} />
          </div> */}
          {/* <div className="line-img">
            <img src={line} alt="" />
          </div> */}
          <div className="container">
            <div className="mt-5">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-5">
                  <div className="to-balance-section position-relative overflow-hidden">
                    {/* <div className="top-left-logo position-absolute">
                      <img src={shortline} />
                    </div> */}
                    <div className="position-relative d-flex justify-content-center">
                      <div
                      // style={{ width: "290px", height: "200px" }}
                      >
                        <img
                          src={shoppingcart}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <h4>SHOPPING CART</h4>
                      <h6>PLUGINS</h6>
                      <p>
                        Lorem ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                    </div>
                    {/* <div className="bottom-right-logo position-absolute">
                      <img src={bigline} />
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-5">
                  <div className="to-balance-section position-relative overflow-hidden">
                    {/* <div className="top-left-logo position-absolute">
                      <img src={shortline} />
                    </div> */}
                    <div className="mt-5">
                      <h4>PAYMENT GATEWAY</h4>
                      <h6>BUTTONS</h6>
                      <p>
                        Lorem ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                    </div>
                    <div className="position-relative d-flex justify-content-center">
                      <div
                      // style={{ width: "250px", height: "50px" }}
                      >
                        <img
                          src={paymentcloud}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="position-relative d-flex my-5 justify-content-center">
                      <div style={{ width: "80px", height: "80px" }}>
                        <img
                          src={paymenticon}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </div>
                    {/* <div className="bottom-right-logo position-absolute">
                      <img src={bigline} />
                    </div> */}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-5">
                  <div className="to-balance-section position-relative overflow-hidden">
                    {/* <div className="top-left-logo position-absolute">
                      <img src={shortline} />
                    </div> */}
                    <div className="position-relative d-flex justify-content-center">
                      <div
                      // style={{ height: "200px" }}
                      >
                        <img
                          src={api}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <h4>API</h4>
                      <h6>LOREUM IPSUM FO</h6>
                      <p>
                        Lorem ipsum is simply dummy text of the printing and
                      </p>
                    </div>
                    {/* <div className="bottom-right-logo position-absolute">
                      <img src={bigline} />
                    </div> */}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-5">
                  <div className="to-balance-section overflow-hidden position-relative d-flex justify-content-between flex-column">
                    {/* <div className="top-left-logo position-absolute">
                      <img src={shortline} />
                    </div> */}
                    <div className="position-relative d-flex justify-content-center">
                      <div
                        className="position-absolute"
                        style={{
                          // width: "230px",
                          // height: "230px",
                          top: "-52px",
                        }}
                      >
                        <img
                          src={invoice}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <h4>INVOICE BUILDER</h4>
                      <h6>LOREUM IPSUM OF</h6>
                      <p>
                        Lorem ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                    </div>
                    {/* <div className="bottom-right-logo position-absolute">
                      <img src={bigline} />
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-5">
                  <div className="to-balance-section position-relative overflow-hidden">
                    {/* <div className="top-left-logo position-absolute">
                      <img src={shortline} />
                    </div> */}
                    <div className="mt-5">
                      <h4>MOBILE APP</h4>
                      <h6>ESSENTIALLY UNCHARGED</h6>
                      <p>
                        Lorem ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                    </div>
                    <div className="position-relative d-flex justify-content-center">
                      <div
                        className="position-absolute"
                        style={{
                          // width: "280px",
                          // height: "220px",
                          top: "-50px",
                        }}
                      >
                        <img
                          src={mobileapp}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </div>
                    {/* <div className="bottom-right-logo position-absolute">
                      <img src={bigline} />
                    </div> */}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-5">
                  <div className="to-balance-section position-relative overflow-hidden">
                    {/* <div className="top-left-logo position-absolute">
                      <img src={shortline} />
                    </div> */}
                    <div className="position-relative d-flex justify-content-center">
                      <div
                      // style={{ width: "200px", height: "200px" }}
                      >
                        <img
                          src={pointofsale}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <h4>POINT OF SALE TOOLS</h4>
                      <h6>LOREUM IPSUM</h6>
                      <p>
                        Lorem ipsum is simply dummy text of the printing and
                      </p>
                    </div>
                    {/* <div className="bottom-right-logo position-absolute">
                      <img src={bigline} />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="incoming-polycon-snd-img">
            <img src={polygon2} />
          </div> */}
        </div>

        <div className="customer-support py-5 position-relative">
          {/* <div className="line-img-left d-none d-lg-block">
            <img src={line} />
          </div> */}
          <div className="container">
            <div className="position-relative">
              <h2>
                <span>LOREM</span> IPSUM IS SIMPLY
              </h2>
              <p className="content">
                Start your first trade with these easy steps.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="customer-col-section my-4 text-center-sm">
                  <div className="support-col mb-3">
                    <img src={chatsupport} />
                  </div>
                  <h6>24/7 CHAT SUPPORT</h6>
                  <p>
                    Lorem ipsum is simply dummy text of the <br></br>
                    printing and typesetting industry.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="customer-col-section my-4 text-center-sm">
                  <div className="support-col mb-3">
                    <img src={faq} />
                  </div>
                  <h6>FAQS</h6>
                  <p>
                    Lorem ipsum is simply dummy text of the <br></br>
                    printing and typesetting industry.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="customer-col-section my-4 text-center-sm">
                  <div className="support-col mb-3">
                    <img src={blog} />
                  </div>
                  <h6>BLOG</h6>
                  <p>
                    Lorem ipsum is simply dummy text of the <br></br>
                    printing and typesetting industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="line-img-right">
            <img src={line} />
          </div> */}
        </div>

        <div
          className="footer-section position-relative text-center py-5"
          style={{ overflowX: "clip" }}
        >
          {/* <div className="polycon-fst-img">
            <img src={polygon1} />
          </div> */}
          {/* <div className="star-img">
            <img src={star} />
          </div> */}
          {/* <div className="polycon-snd-img">
            <img src={polygon2} />
          </div> */}
          {/* <div className="star-img-one">
            <img src={star} />
          </div> */}
          {/* <div className="line-img">
            <img src={line} />
          </div> */}

          {/* <div className="line-bottom-img">
            <img src={line} />
          </div> */}

          <div className="container position-relative">
            <h2>
              <span>LOREM</span> IPSUM IS SIMPLY
            </h2>
            <p>Start your first trade with these easy steps.</p>
            <div className="d-flex justify-content-center mt-lg-5 mt-md-5 mt-4">
              <button className="commonBtn">
                  <img src={vectorimg} alt="" />{" "}
                  <sapn className="ms-2">GET STARTED</sapn>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Merchant;
