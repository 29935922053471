import React, { useEffect, useState } from "react";
import { Nav, Navbar, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";

import FilterCoins from "../utils/FilterCoins";
import Heading from "../utils/Heading";

import topimg from "../../asset/IMG/top-img.png";
import send from "../../asset/IMG/send.png";
import receive from "../../asset/IMG/receive.png";
import transTop from "../../asset/IMG/transaction-top.png";
import transBot from "../../asset/IMG/transaction-bottom.png";
import star from "../../asset/IMG/star2.png";
import doubleBar from "../../asset/IMG/double_rec.png";
import DepositHistory from "../utils/DepositHistory";
import WithdrawHistory from "../utils/WithdrawHistory";
import TransferHistory from "../utils/TransferHistory";
import ConversionHistory from "../utils/ConversionHistory";
import P2PHistory from "../utils/P2PHistory";
import MassWithdrawal from "../utils/MassWithdrawal";
import polygon5 from "../../asset/IMG/polygon5.png";
import polygon6 from "../../asset/IMG/polygon6.png";
import Footer from "../Layout/Footer";
import WalletTop from "../utils/WalletTop";
import halfPoly from "../../asset/IMG/Polygon8.png";
import sideStar from "../../asset/IMG/sideStar.png";
import { useLocation } from "react-router-dom";

const History = () => {

  const [key, setKey] = useState("first");
  const [symbol, setSymbol] = useState('');
  const [Id, setId] = useState('')
  const location = useLocation();
  console.log("asdfasdfasdf", key, location?.state?.key)
  console.log('loca6tiosadfasdf', key)
  useEffect(() => {
    console.log('000 loca6tiosadfasdf', location?.state?.key != undefined && location?.state?.key != null && location?.state?.key != "", location)
    if (location?.state?.key != undefined && location?.state?.key != null && location?.state?.key != "") {
      if (location?.state?.key) {
        console.log('222 loca6tiosadfasdf', location?.state?.key)
        setKey(location?.state?.key)
        setSymbol(location?.state?.symbol)
        setId(location?.state?._id)
        document.getElementById(`left-tabs-example-tabpane-${location?.state?.key}`).click();
      }
    } else {
      if (key) {
        console.log('111 loca6tiosadfasdf')
        document.getElementById(`left-tabs-example-tabpane-${key}`).click();
        setKey(key);
      }
    }

  }, [location])
  return (
    <>
      <section className="paddingY walletSection">
        <div className="wallet_top_container position-relative">
          {/* <div className="history halfPoly d-none d-lg-block">
            <img src={halfPoly} alt="half polygon" className="img-fluid" />
          </div> */}
          {/* <div className="side-star position-absolute">
            <img src={sideStar} alt="star" className="img-fluid" />
          </div> */}
          {/* <div className="halfPoly">
        <img src={halfPoly} alt="half polygon" className="img-fluid" />
      </div> */}
          {/* <div className="doubleBar">
            <img src={doubleBar} alt="Double Rectangle" className="img-fluid" />
          </div> */}

          <div className="container">
            <WalletTop name="History" />
          </div>
        </div>
        {console.log("keydddddddd", key)}
       {location?.state?.Id ?  <Tab.Container id="left-tabs-example" defaultActiveKey={key} onSelect={(e) => { setKey(e) }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8">
                <Nav variant="pills" className="d-flex nav_tabs tab_scroll_x">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      Deposit History
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      Withdrawal History
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="third"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      Transfer History
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="fourth"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      Conversion History
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="fifth"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      P2p history
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="sixth"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      Mass Withdrawal
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
           
          </div>
          <div className="tabContainerFluid background_img">
            <div className="container tab-content">
              <Tab.Content >
                <Tab.Pane eventKey="first">
                  <Heading name="deposit history" />
                  <DepositHistory symbol={symbol} Id={Id}/>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Heading name="withdraw history" />
                  <WithdrawHistory symbol={symbol} Id={Id}/>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Heading name="transfer history" />
                  <TransferHistory symbol={symbol} Id={Id}/>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <Heading name="conversion history" />
                  <ConversionHistory />
                </Tab.Pane>
                <Tab.Pane eventKey="fifth">
                  <Heading name="p2p history" />
                  <P2PHistory />
                </Tab.Pane>
                <Tab.Pane eventKey="sixth">
                  <Heading name="masswithdrawal" />
                  <MassWithdrawal />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container> : <Tab.Container id="left-tabs-example" defaultActiveKey={key} onSelect={(e) => { setKey(e) }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8">
                <Nav variant="pills" className="d-flex nav_tabs tab_scroll_x">
                 {<Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      Deposit History
                    </Nav.Link>
                  </Nav.Item>}
               {   <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      Withdrawal History
                    </Nav.Link>
                  </Nav.Item>}
                  { <Nav.Item>
                    <Nav.Link
                      eventKey="third"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      Transfer History
                    </Nav.Link>
                  </Nav.Item>}
                  <Nav.Item>
                    <Nav.Link
                      eventKey="fourth"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      Conversion History
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="fifth"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      P2p history
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="sixth"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      Mass Withdrawal
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
           
          </div>
          <div className="tabContainerFluid background_img">
            <div className="container tab-content">
              <Tab.Content >
                <Tab.Pane eventKey="first">
                  <Heading name="deposit history" />
                  <DepositHistory symbol={symbol} Id={Id}/>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Heading name="withdraw history" />
                  <WithdrawHistory symbol={symbol} Id={Id}/>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Heading name="transfer history" />
                  <TransferHistory symbol={symbol} Id={Id}/>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <Heading name="conversion history" />
                  <ConversionHistory />
                </Tab.Pane>
                <Tab.Pane eventKey="fifth">
                  <Heading name="p2p history" />
                  <P2PHistory />
                </Tab.Pane>
                <Tab.Pane eventKey="sixth">
                  <Heading name="masswithdrawal" />
                  <MassWithdrawal />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>}

        {/* <button className="commonBtn">
        <div className="leftBtnBg"></div>

        <div className="z-2">sign in</div>
      </button> */}
      </section>
      <Footer />
    </>
  );
};

export default History;
