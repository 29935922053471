import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getuseraccountdisabledhooks } from "../../hooks/UseUser";
import { useNavigate } from "react-router-dom";
import { FRONTENDURL } from "../../config/env";


function CloseAccount(props) {

  const [showClose, setShowClose] = useState(true);
  let navigate = useNavigate()

  const closeAccount = async () => {
    try {
      let reqData = { userId: props.userId, filter : "closeAccount"}
      let res = await getuseraccountdisabledhooks(reqData);
      console.log("resdata", res?.data)
      if(res?.data?.success == true) {
        props?.onDismiss()
        sessionStorage.removeItem("signup");
        sessionStorage.removeItem("accessToken");
        setTimeout(() => {
          window.location.href = FRONTENDURL;
        }, 1000)
      
      }
    } catch (e) {
      console.log("closeAccount_err", e)
    }
  }


  return (
    <div>
      {/* Modal Popup */}
      <Modal
        show={showClose}
        onHide={()=>{props.onDismiss()}}
        centered
        className="get_demo_popup"
      >
        <Modal.Body>
          <button className="btn_close_cross"  onClick={()=>{props.onDismiss()}}>
           x
          </button>
          <div className="get_demo_modal_popup">
            <div className="d-flex flex-column">
              <p className="mb-2">Close Account</p>
              <div className="d-flex justify-content-center align-items-center">
               
              </div>
            </div>
            <div className="d-flex flex-column mt-4">

              <div className="otp_input d-flex justify-content-center align-items-center">
              Are you sure want to close this account?
              </div>
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-center align-items-center mt-3">
            <Button
                onClick={()=>{closeAccount()}}
              className="new_common_btn modal_submit_btn me-2"
            >
              Confirm
            </Button>

            <Button
                onClick={()=>{props.onDismiss()}}
              className="new_common_btn modal_submit_btn modal_btn_cancelk"
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CloseAccount;
