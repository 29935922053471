import React from "react";
import "../../../../Styles/Dashboard.css";
import detailicon from "../../../../asset/IMG/Dashboard/AFFILIATE/detailsicon.png";
import binance from "../../../../asset/IMG/Dashboard/AFFILIATE/binance.png";
import bitcoin from "../../../../asset/IMG/Dashboard/AFFILIATE/bitcoin.png";
import dogecoin from "../../../../asset/IMG/Dashboard/AFFILIATE/dogecoin.png";
import ethereum from "../../../../asset/IMG/Dashboard/AFFILIATE/ethereum.png";
import tether from "../../../../asset/IMG/Dashboard/AFFILIATE/tether.png";
import tronbg from "../../../../asset/IMG/Dashboard/AFFILIATE/tronbg.png";
import tronicon from "../../../../asset/IMG/Dashboard/AFFILIATE/tronicon.png";
import detaillogo from "../../../../asset/IMG/Dashboard/support-coins-detail-logo.png";
import shortline from "../../../../asset/IMG/Dashboard/secondbox-lite-top.png";
import bigline from "../../../../asset/IMG/Dashboard/left-top-icon.png";

// import facebook from "../../../../asset/IMG/Affiliate/facebook.png";
import facebook from "../../../../asset/IMG/Affiliate/Affiliate/facebook.png";
import twitter from "../../../../asset/IMG/Affiliate/Affiliate/twitter.png";
import linkedin from "../../../../asset/IMG/Affiliate/Affiliate/linkedin.png";
import reddit from "../../../../asset/IMG/Affiliate/Affiliate/reddit.png";
import gplus from "../../../../asset/IMG/Affiliate/Affiliate/gplus.png";
import share from "../../../../asset/IMG/Affiliate/Affiliate/share_save.png";

import banner1 from "../../../../asset/IMG/Affiliate/Affiliate/banner1.jpg";
import banner2 from "../../../../asset/IMG/Affiliate/Affiliate/banner2.jpg";
import banner3 from "../../../../asset/IMG/Affiliate/Affiliate/banner3.jpg";
import banner4 from "../../../../asset/IMG/Affiliate/Affiliate/banner4.jpg";
import banner5 from "../../../../asset/IMG/Affiliate/Affiliate/banner5.jpg";
import banner6 from "../../../../asset/IMG/Affiliate/Affiliate/banner6.jpg";
function AffiliateTools() {
  const SupportCoins = [
    {
      logo: bitcoin,
      coinname: "BITCOIN",
      code: "BTC",
      shortline: shortline,
      bigline: bigline,
      detaillogo: detaillogo,
    },
    {
      logo: binance,
      coinname: "BINANCE",
      code: "BTC",
      shortline: shortline,
      bigline: bigline,
      detaillogo: detaillogo,
    },
    {
      logo: dogecoin,
      coinname: "DOGECOIN",
      code: "DOGE",
      shortline: shortline,
      bigline: bigline,
      detaillogo: detaillogo,
    },
    {
      logo: tether,
      coinname: "TETHER",
      code: "USDT",
      shortline: shortline,
      bigline: bigline,
      detaillogo: detaillogo,
    },
    {
      logo: ethereum,
      coinname: "ETHEREUM",
      code: "BTC",
      shortline: shortline,
      bigline: bigline,
      detaillogo: detaillogo,
    },
    {
      logo: binance,
      coinname: "TRON",
      code: "TRC",
      shortline: shortline,
      bigline: bigline,
      detaillogo: detaillogo,
    },
  ];
  return (
    <div className="affiliate-tools custom_affiliate_tools">
      
      <h5>Cryptocurrency Affiliate Marketing Program | CoinPayments</h5>
      <p>
        By popular demand CoinPayments presents our new affiliate system. By
        sharing your referral link any merchant who signs up after clicking it
        you will receive a percentage of their transaction fees1 for 5 years.
        Note that you cannot refer your own account to another of your own
        accounts.
      </p>
      <div className="mt-3">
        <h4>How does it work?</h4>
        <ol>
          <li>
            Simply share your URL with the person who you are referring:
            <span className="bld_content">
              {" "}
              https://www.coinpayments.net/index.php?ref=79da63745630679c58895d9b8a5c970e
            </span>
          </li>
          <li>
            When they sign up you will automatically receive 15% of their
            transaction fees1 in your wallet for 5 years.
          </li>
          <li>
            You can also use this shortened URL if you wish:
            <span className="bld_content">
              https://gocps.net/sfglzidu1cnfhsr5s3jih7svejs/
            </span>
          </li>
        </ol>
      </div>
      <div>
        <h4>How do I get started?</h4>
        <ol>
          <li>Share on Social Media</li>

          <div className="social_links my-1">
            <a href="" target="_blank">
              <img
                src={facebook}
                alt="facebook"
                className="facebook cmn_social img-fluid"
              />
            </a>
            <a href="" target="_blank">
              <img
                src={twitter}
                alt="twitter"
                className="twitter cmn_social img-fluid"
              />
            </a>
            <a href="" target="_blank">
              <img
                src={linkedin}
                alt="linkedin"
                className="linkedin cmn_social img-fluid"
              />
            </a>
            <a href="" target="_blank">
              <img
                src={reddit}
                alt="reddit"
                className="reddit cmn_social img-fluid"
              />
            </a>
            <a href="" target="_blank">
              <img
                src={gplus}
                alt="gplus"
                className="gplus cmn_social img-fluid"
              />
            </a>
            <a href="" target="_blank">
              <img
                src={share}
                alt="share"
                className="share cmn_social img-fluid"
              />
            </a>
          </div>
          <li>
            Email your friends with your referral URL and invite them to sign up
            with CoinPayments.
          </li>
          <li>Add your URL to your website or blog.</li>
          <li>
            <b>To date you have referred 0 user(s).</b>
          </li>
        </ol>
      </div>
      <div>
        <h4>Do you have any banners we can use?</h4>
        <ul className="custom_ul">
          <li>
            <span>
              https://www.coinpayments.net/images/b/banner1_468x60-3.jpg
            </span>
            <p className="mb-0">Size: 468x60</p>
          </li>
          <img src={banner1} alt="banner" className="banner img-fluid my-3" />
          <li>
            https://www.coinpayments.net/images/b/banner2_240x400-3.jpg
            <p>Size: 240x400</p>
          </li>
          <img src={banner2} alt="banner" className="banner img-fluid my-3" />
          <li>
            https://www.coinpayments.net/images/b/banner3_160x600-3.jpg
            <p>Size: 160x600</p>
          </li>
          <img src={banner3} alt="banner" className="banner img-fluid my-3" />

          <li>
            https://www.coinpayments.net/images/b/banner4_300x250-3.jpg
            <p>Size: 300x250</p>
          </li>
          <img src={banner4} alt="banner" className="banner img-fluid my-3" />
          <li>
            https://www.coinpayments.net/images/b/banner5_125x125-3.jpg
            <p>Size: 125x125</p>
          </li>
          <img src={banner5} alt="banner" className="banner img-fluid my-3" />
          <li>
            https://www.coinpayments.net/images/b/banner6_728x90-3.jpg
            <p>Size: 728x90</p>
          </li>
          <img src={banner6} alt="banner" className="banner img-fluid my-3" />
        </ul>
        <p>
          Transaction fees included in the program are payment fees for
          payments created with our buttons or 'create_transaction' API call,
          and deposit fees for deposits to callback addresses. Note that due to
          technical limitations of different blockchain types not all coins are
          available in the affiliate program.
        </p>
      </div>
    </div>
  );
}

export default AffiliateTools;
