import React, { useEffect, useRef, useState } from "react";
import "../../../../Styles/Dashboard.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import vectorimg from "../../../../asset/IMG/Dashboard/AccountSettings/Vector.png";
import linklogo from "../../../../asset/IMG/Dashboard/AccountSettings/link-logo.png";
import closeuser from "../../../../asset/IMG/Dashboard/AccountSettings/close-logo.png";
import login from "../../../../asset/IMG/Dashboard/AccountSettings/Login&Security.png";
import publicinfo from "../../../../asset/IMG/Dashboard/AccountSettings/publicInfo.png";
import merchant from "../../../../asset/IMG/Dashboard/AccountSettings/MerchantSettings.png";
import basicSetting from "../../../../asset/IMG/Dashboard/AccountSettings/basicSetting.png";
import hoverLogo from "../../../../asset/IMG/Dashboard/AccountSettings/hoverLogo.png";
import topleftlogo from "../../../../asset/IMG/Dashboard/AccountSettings/top-left.png";
import bottomrightlogo from "../../../../asset/IMG/Dashboard/AccountSettings/right-bottom.png";
import Dropdown from "react-bootstrap/Dropdown";

import BasicSetting from "../../../../asset/IMG/Dashboard/Tab_Icon/basic_setting.png";
import LoginSecurity from "../../../../asset/IMG/Dashboard/Tab_Icon/login_security.png";
import PublicInfo from "../../../../asset/IMG/Dashboard/Tab_Icon/public-info.png";
import MerchantSetting from "../../../../asset/IMG/Dashboard/Tab_Icon/merchant.png";
import CloseAccount from "../../CloseAccount";
import { position, style, iconTheme } from "../../../../hooks/UseToast";
import { useNavigate } from "react-router-dom";
import { copyUrl, getuserdatabytoken, requestemailhooks, sleep, usereditprofilehooks, userupdatemerchantsettings, userupdatepublicdetails } from "../../../../hooks/UseUser";
import PhoneInput from 'react-phone-input-2';
import { BiHide, BiShow } from "react-icons/bi";
import 'react-phone-input-2/lib/style.css'
import toast from "react-hot-toast";
import { DateTimeForm, DateTimeFormat1, profileUpdateValidation } from "../../../../hooks/UseValidation";
import { BACKURL, FRONTENDURL } from "../../../../config/env";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdOutlineContentCopy } from "react-icons/md";
import Accountmodal from "../../../Modals/accountmodal";

function AccountSettings() {


  const InitialState = {
    email: "",
    confirmemail: "",
    gender: "",
    timezone: DateTimeFormat1(new Date(), true),
    dateformat: new Date().toISOString().substring(0, 10),
    timeformat: DateTimeFormat1(new Date(), true),
    username: "",
    firstName: "",
    lastName: "",
    mobileNo: "",
    userId: "",
    logoURI: "",
    accounttype: "",
    merchantsetting: [],
    filter: "profileupdate"
  }

  let dates = new Date();
  var aryIanaTimeZones = Intl.supportedValuesOf('timeZone');

  let timezones = [];
  aryIanaTimeZones.forEach((timeZone) => {
    let strTime = dates.toLocaleString("en-US", { timeZone: `${timeZone}` });
    timezones.push(timeZone)
  });


  const navigate = useNavigate();
  const [userdetails, setUserDetails] = useState(InitialState);
  const [userpassworddetails, setUserPasswordDetails] = useState({ currentpassword: "", newpassword: "", confirmpassword: "", filter: "passwordupdate", userId: "", username: "", recoveryemail: "" })
  const [usermerchantdetails, setUserMerchantDetails] = useState({ ipnsecret: "", ipnurl: "", ipnfiatcurrency: "BTC", receiveemail: { "newpayment": false, "fundsreceived": false, "fundspayment": false, "depositreceived": false, "positivefeedback": false }, logemail: "", filter: "merchantupdate" });
  const [userpublicdetails, setUserPublicDetails] = useState({ publicname: "", publicemail: "", publicurl: "", usegravata: false, filter: "publicinfoupdate" })
  const [userToken, setUserToken] = useState();
  const [dialcode, setDialCode] = useState('1');
  const [errors, setErrors] = useState({})
  const [showIcon, setShowIcon] = useState(false);
  const [showIcon1, setShowIcon1] = useState(false);
  const [showIcon2, setShowIcon2] = useState(false);
  const [recoveryemail, setRecoveryEmail] = useState('');
  const [update, setUpdate] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [accountmodal, setAccountmodal] = useState(false);
  console.log("updateasdfasdf err email", usermerchantdetails, errors, userpassworddetails?.recoveryemail)
  const { email, confirmemail, gender, timezone, dateformat, timeformat, firstName, lastName, username, mobileNo, userId, logoURI } = userdetails;
  const { ipnsecret, ipnurl, ipnfiatcurrency, receiveemail, logemail, filter } = usermerchantdetails;
  const { publicname, publicemail, publicurl, usegravata } = userpublicdetails
  let date = new Date();
  let options = [
    new Date().toISOString().substring(0, 10),
    new Date().toLocaleDateString(),
    new Date().toDateString()
  ];
  let options1 = [
    DateTimeFormat1(new Date(), true),
    DateTimeFormat1(new Date(), false),
    DateTimeForm(new Date(), false, true, false),
    DateTimeForm(new Date(), false, true, true)
  ]

  let option2 = aryIanaTimeZones;

  let options3 = [
    "BTC", "INR", "USDT"
  ]
  useEffect(() => {

    (async () => {
      const UserToken = sessionStorage.getItem("accessToken")
      if (!(UserToken != '' && UserToken != null && UserToken != undefined && UserToken != "undefined")) {
        navigate("/")
        await sleep(1000)
      }
    })();
  }, []);

  useEffect(() => {
    let UserToken = sessionStorage.getItem("accessToken");
    setUserToken(UserToken)
    if ((UserToken != '' && UserToken != null && UserToken != undefined && UserToken != "undefined")) {
      getData(UserToken)
    }

  }, [userToken, update]);


  const getData = async (token) => {
    try {
      let reqdata = { token: token, filter: "userprofiledata" }
      let res = await getuserdatabytoken(reqdata);
      if (res?.data?.success == true) {
        let data = res?.data?.record;
        console.log('datasgallllll', data, data?.mobile_no?.split('.')[1])
        let obj = {
          email: data?.email,
          confirmemail: data?.email,
          firstName: data?.first_name,
          lastName: data?.last_name,
          gender: data?.gender,
          mobileNo: data?.mobile_no?.split('.')[1],
          username: data?.user_name,
          userId: data?.userId,
          logoURI: data?.logoURI,
          timezone: data?.Timezone != "" ? data?.Timezone : DateTimeFormat1(new Date(), true),
          timeformat: data?.TimeFormat != "" ? data?.TimeFormat : DateTimeFormat1(new Date(), true),
          dateformat: data?.DateFormat != "" ? data?.DateFormat : new Date().toISOString().substring(0, 10),
          accounttype: data?.accounttype,
          merchantsetting: data?.merchantSetting,
          isKYCVerify: data?.isKYCVerify,
          KYCFile: data?.KYCFile
        }
        console.log("data?.merchantSetting", data?.isKYCVerify)
        let obj1 = {
          accounttype: data?.accounttype,
          ipnsecret: data?.merchantSetting[0]?.IpnSecret,
          ipnurl: data?.merchantSetting[0]?.IpnUrl,
          logemail: data?.merchantSetting[0]?.LogEmail,
          ipnfiatcurrency: data?.merchantSetting[0]?.IpnFiatCurrency,
          receiveemail: data?.merchantSetting[0]?.ReceiveEmail,
        }
        let obj2 = {
          publicname: data?.PublicInfo[0]?.PublicName,
          publicemail: data?.PublicInfo[0]?.PublicEmail,
          publicurl: data?.PublicInfo[0]?.PublicUrl,
          usegravata: data?.PublicInfo[0]?.UseGravata,
        }
        let dialcode = (data?.mobile_no?.split('.')[0])?.split('+')[1];
        console.log('dailcoddata', dialcode)
        setUserDetails({ ...userdetails, ...obj });
        setDialCode(dialcode)
        setUserMerchantDetails({ ...usermerchantdetails, ...obj1 })
        setUserPublicDetails({ ...userpublicdetails, ...obj2 })
        setUserPasswordDetails({ ...userpassworddetails, ...{ userId: data?.userId, username: data?.user_name } });
        // setRecoveryEmail(data?.email)
      }
    } catch (e) {
      console.log("getData_err", e)
    }
  }

  const handleChange = (e) => {
    const { name, value, files } = e?.target;
    if (name == "logoURI") {
      setUserDetails({ ...userdetails, ...{ [name]: files[0] } })
    } else {
      setUserDetails({ ...userdetails, ...{ [name]: value } })
    }

  }

  const handleChange1 = (e) => {
    const { name, value } = e?.target;
    setUserPasswordDetails({ ...userpassworddetails, ...{ [name]: value } })

  }

  const handleChange2 = (e) => {
    const { name, value } = e?.target;
    setUserMerchantDetails({ ...usermerchantdetails, ...{ [name]: value } })
  }

  const handleChange3 = (e) => {
    const { name, value } = e?.target;
    setUserPublicDetails({ ...userpublicdetails, ... { [name]: value } })
  }

  const UpdateAccount = async (data) => {
    try {
      console.log("datasssss", data)
      if (data == "profileupdate") {
        const { errors, isValid } = await profileUpdateValidation(userdetails);
        setErrors(errors);
        if (Object.keys(errors).length == 0 && isValid === true) {
          console.log("req userdetails", userdetails)
          let reqData = {
            username: userdetails.username,
            firstName: userdetails.firstName,
            lastName: userdetails.lastName,
            email: userdetails.email,
            gender: userdetails.gender,
            timezone: userdetails.timezone,
            filter: userdetails.filter,
            userId: userdetails.userId,
            mobileNo: "+" + dialcode + "." + userdetails.mobileNo,
            dateformat: userdetails.dateformat,
            timeformat: userdetails.timeformat,
            logoURI: userdetails.logoURI
          }
          console.log("req Datasgal", reqData)
          let res = await usereditprofilehooks(reqData);
          if (res?.data?.success == "success") {
            setErrors(res?.data?.errors)
          } else if (res?.data?.success == true) {
            getData(userToken)
          }
        }
      } else if (data == "passwordupdate") {
        let reqData = {
          recoveryemail: userpassworddetails?.recoveryemail,
          user_name: username,
          userId: userpassworddetails?.userId,
          filter: "recoveryemailupdate"
        }
        const { errors, isValid } = await profileUpdateValidation(userpassworddetails);
        console.log("err passwordupdate email", errors)
        setErrors(errors);
        console.log("user data", userpassworddetails?.recoveryemail, (userpassworddetails?.recoveryemail != ""), userdetails?.currentpassword, (Object.keys(errors).length == 0 && isValid === true) && (userdetails?.currentpassword != "" && userdetails?.newpassword != "" && userdetails?.confirmpassword != ""))
        if ((Object.keys(errors).length == 0 && isValid === true) && ((userdetails?.currentpassword != "" && userdetails?.currentpassword != undefined) && (userdetails?.newpassword != "" && userdetails?.newpassword != undefined) && (userdetails?.confirmpassword != "" && userdetails?.confirmpassword != undefined))) {
          let res = await usereditprofilehooks(userpassworddetails);
          console.log("res data updatepass", res?.data)
          if (res?.data?.success == "success") {
            setErrors(res?.data?.errors)
          } else if (res?.data?.success == true) {
            setTimeout(() => {
              setUserPasswordDetails({ ...userpassworddetails, ...{ currentpassword: "", newpassword: "", confirmpassword: "", filter: "passwordupdate", userId: "", username: "" } });
            }, 1000)
            getData(userToken)
          }
        } else if (((userpassworddetails?.recoveryemail != "" && userpassworddetails?.recoveryemail != undefined))) {
          let res = await usereditprofilehooks(reqData);
          console.log("Resasdfasddata", res?.data)
          if (res?.data?.success == "success") {
            setErrors(res?.data?.errors)
          } else if (res?.data?.success == true) {
            setTimeout(() => {
              setUserPasswordDetails({ ...userpassworddetails, ...{ currentpassword: "", newpassword: "", confirmpassword: "", filter: "passwordupdate", userId: "", username: "", recoveryemail: "" } });
            }, 1000)
            getData(userToken)
          }
        }


      } else if (data == "merchantupdate") {
        const { errors, isValid } = await profileUpdateValidation(usermerchantdetails);
        setErrors(errors);
        console.log("errors_data", errors)
        if (Object.keys(errors).length == 0 && isValid === true) {
          let reqdata = {
            ipnsecret: ipnsecret, ipnurl: ipnurl, ipnfiatcurrency: ipnfiatcurrency, receiveemail: receiveemail, logemail: logemail, merchantId: userdetails?.merchantsetting[0]?.merchantId, user_name: userdetails?.username,
            filter: filter
          }
          console.log("merchantupdate_reqdata", reqdata)
          let res = await userupdatemerchantsettings(reqdata);
          console.log("redatadsasdf", res?.data)
          if (res?.data?.success == true) {
            console.log("res data merchantupdate", res?.data)
            setUpdate(!update)
          }
        }
      } else if (data == "publicinfoupdate") {
        const { errors, isValid } = await profileUpdateValidation(userpublicdetails);
        console.log("errordata", errors)
        setErrors(errors);
        if (Object.keys(errors).length == 0 && isValid === true) {
          let reqdata = {
            publicname: publicname, publicurl: publicurl, usegravata: usegravata, publicemail: publicemail, user_name: userdetails?.username,
            filter: "publicinfoupdate"
          }
          let res = await userupdatepublicdetails(reqdata);
          if (res?.data?.success == true) {
            console.log("res data updatepublicdetails", res?.data)
            setUpdate(!update)
          }
        }
      }

    } catch (e) {
      console.log("UpdateAccount_err", e)
    }
  }

  const handlePhoneNumber = async (value, country) => {
    const { dialCode } = country;
    let newPhoneNo = value;
    if (dialCode) {
      setDialCode(dialCode)
      setUserDetails({ ...userdetails, ...{ "mobileNo": newPhoneNo.slice(dialCode.length) } })
    } else if (value) {
      setUserDetails({ ...userdetails, ...{ "mobileNo": value } })
    }
  }
  const fileInputRef = useRef(null);
  const handleFileUpload = () => {
    fileInputRef.current.click();
  };


  // useEffect(() => {
  //   if (userdetails?.accounttype != "Normal") {

  //     document.getElementById(`left-tabs-example-tab-${keydata}`).click()
  //   }
  // }, [keydata])



  return (
    <div className="account-settings-page">
      {accountmodal && <Accountmodal onDismiss={() => setAccountmodal(false)} />}
      {showClose && <CloseAccount onDismiss={() => setShowClose(false)} userId={userdetails?.userId} />}
      <div className="account-setting-section overflow-hidden container">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Nav variant="pills" className="navs-border-sec">
              <div className="row head-section overflow-hidden position-relative">
                {/* <div
                  className="position-absolute top-left-light-blur"
                  style={{ top: "-61px", left: "-56px" }}
                >
                  <img src={topleftlogo} />
                </div> */}

                <div className={userdetails?.accounttype != "Normal" ? "col-sm-6 col-6 col-lg-3 col-md-3 nav-item-border-right" : "col-sm-6 col-6 col-lg-4 col-md-3 nav-item-border-right"}>
                  <Nav.Item className="my-2">
                    <Nav.Link eventKey="first" className="link-section">
                      <div className="d-flex align-items-center basic-settings">
                        <div className="base-img">
                          <div className="tab-headings-logo-bg">
                            <img
                              src={BasicSetting}
                              className="tab-logo-fliter"
                            />
                          </div>
                        </div>
                        <span className="tabs-title-heading">
                          BASIC SETTINGS
                        </span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </div>

                <div className={userdetails?.accounttype != "Normal" ? "col-sm-6 col-6 col-lg-3 col-md-3 nav-item-border-right" : "col-sm-6 col-6 col-lg-4 col-md-3 nav-item-border-right"}>
                  <Nav.Item className="my-2">
                    <Nav.Link eventKey="second" className="link-section">
                      <div className="d-flex align-items-center basic-settings">
                        <div className="base-img">
                          <div className="tab-headings-logo-bg">
                            <img src={PublicInfo} className="tab-logo-fliter" />
                          </div>
                        </div>
                        <span className="tabs-title-heading">PUBLIC INFO</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </div>

                <div className={userdetails?.accounttype != "Normal" ? "col-sm-6 col-6 col-lg-3 col-md-3 nav-item-border-right" : "col-sm-6 col-6 col-lg-4 col-md-3 nav-item-border-right"}>
                  <Nav.Item className="my-2">
                    <Nav.Link eventKey="third" className="link-section">
                      <div className="d-flex align-items-center basic-settings">
                        <div className="base-img">
                          <div className="tab-headings-logo-bg">
                            <img
                              src={LoginSecurity}
                              className="tab-logo-fliter"
                            />
                          </div>
                        </div>
                        <span className="tabs-title-heading">
                          LOGIN & SECURITY
                        </span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </div>

                {userdetails?.accounttype == "Merchant" && <div className="col-sm-6 col-6 col-lg-3 col-md-3 nav-item-border-right">
                  <Nav.Item className="nav-item-border-right-none my-2">
                    <Nav.Link eventKey="fourth" className="link-section">
                      <div className="d-flex align-items-center basic-settings">
                        <div className="base-img">
                          <div className="tab-headings-logo-bg">
                            <img
                              src={MerchantSetting}
                              className="tab-logo-fliter"
                            />
                          </div>
                        </div>
                        <span className="tabs-title-heading">
                          MERCHANT SETTINGS
                        </span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </div>}
              </div>
            </Nav>

            <Tab.Content className="position-relative">
              <Tab.Pane
                eventKey="first"
                className="text-white position-relative"
                style={{ zIndex: "2" }}
              >
                <div className="basic-settings-content">
                  <div className="row mb-4">
                    {userdetails?.accounttype != "Normal" && <div className="col-lg-4 mb-2">
                      <div>
                        <p className="hover-issue-para">
                          <span>YOUR MERCHANT ID : </span>
                          <span className="merchant-id">
                            {userdetails?.merchantsetting && userdetails?.merchantsetting?.length ? userdetails?.merchantsetting[0].merchantId : ""}
                          </span>
                        </p>
                      </div>
                    </div>}
                    <div className="col-lg-4 mb-2">
                      <div>
                        <p className="hover-issue-para">
                          {/* <span>
                            ACCOUNT VERIFICATION STATUS :{" "}
                            <span className="unverified-text">UNVERIFIED</span>{" "}
                            -{" "}
                          </span> */}
                          {/* <span className="verify-now">VERIFY NOW</span> */}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-2">
                      <div>
                        <p className="hover-issue-para">
                          <span className="affiliate-url">AFFILIATE URL: </span>
                          <span className="ms-2 copytext">{`${FRONTENDURL}/${userdetails?.merchantsetting[0]?.merchantId}`}</span>
                          <span className="ms-2">
                            <CopyToClipboard text={`${FRONTENDURL}/${userdetails?.merchantsetting[0]?.merchantId}`} onCopy={() => copyUrl('Copied Url', `${FRONTENDURL}/${userdetails?.merchantsetting[0]?.merchantId}`)}>
                              <MdOutlineContentCopy className="copysvg" />
                            </CopyToClipboard>
                          </span>
                        </p>


                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          USER NAME :
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="colFormLabel"
                            name="username"
                            // value
                            value={username}
                            onChange={(e) => handleChange(e)}
                          // placeholder="col-form-label"
                          />
                          <span className="text-danger">{errors?.username}</span>
                        </div>

                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          FIRST NAME :
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="colFormLabel"
                            name="firstName"
                            // value
                            value={firstName}
                            onChange={(e) => handleChange(e)}
                          // placeholder="col-form-label"
                          />
                          <span className="text-danger">{errors?.firstName}</span>
                        </div>

                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          LAST NAME :
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="colFormLabel"
                            name="lastName"
                            // value
                            value={lastName}
                            onChange={(e) => handleChange(e)}
                          // placeholder="col-form-label"
                          />
                          <span className="text-danger">{errors?.lastName}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          ACCOUNT EMAIL:
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="email"
                            class="form-control"
                            id="colFormLabel"
                            name="email"
                            // value
                            value={email}
                            onChange={(e) => handleChange(e)}
                          // placeholder="col-form-label"
                          />
                          <span className="text-danger">{errors?.email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          CONFIRM EMAIL:
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="email"
                            class="form-control"
                            id="colFormLabel"
                            name="confirmemail"
                            value={confirmemail}
                            onChange={(e) => handleChange(e)}
                          // placeholder="col-form-label"
                          />
                          <span className="text-danger">{errors?.confirmemail}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          MOBILE NO :
                        </label>
                        {console.log('dialcode + mobileNo', dialcode, mobileNo)}
                        <div class="col-lg-8">
                          <PhoneInput
                            country='us'
                            placeholder=''
                            value={dialcode + mobileNo}
                            onChange={handlePhoneNumber}
                            // autoComplete="new-password"
                            specialLabel={false}
                          />
                          <span className="text-danger">{errors?.mobileNo}</span>
                        </div>

                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          SEX:
                        </label>
                        <div class="col-lg-8">
                          <select
                            class="form-control"
                            id="colFormLabel"
                            value={gender}
                            name="gender"
                            onChange={(e) => handleChange(e)}
                          >
                            <option hidden></option>
                            <option>Male</option>
                            <option>Female</option>
                          </select>
                          <span className="text-danger">{errors?.gender}</span>
                        </div>
                      </div>
                    </div>


                    {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          TIME ZONE:
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="email"
                            class="form-control"
                            id="colFormLabel"
                            name="timezone"
                            value={timezone}
                            onChange={(e) => handleChange(e)}
                          // placeholder="col-form-label"
                          />
                        </div>
                        <span className="text-danger">{errors?.timezone}</span>
                      </div>
                    </div> */}
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          TIME ZONE:
                        </label>
                        <div class="col-lg-8">
                          <select
                            class="form-control"
                            id="colFormLabel"
                            name="timezone"
                            value={timezone}
                            onChange={(e) => handleChange(e)}
                          // placeholder="col-form-label"
                          >
                            {option2 && option2?.length > 0 && option2?.map((val, i) => {
                              return (<option>{val}</option>)
                            })}
                          </select>
                          <span className="text-danger">{errors?.timezone}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          DATE FORMAT:
                        </label>
                        <div class="col-lg-8">
                          <select
                            class="form-control"
                            id="colFormLabel"
                            name="dateformat"
                            value={dateformat}
                            onChange={(e) => handleChange(e)}
                          // placeholder="col-form-label"
                          >
                            {options && options?.length > 0 && options?.map((val, i) => {
                              return (<option>{val}</option>)
                            })}
                          </select>
                          <span className="text-danger">{errors?.dateformat}</span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          TIME FORMAT:
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="email"
                            class="form-control"
                            id="colFormLabel"
                            name="timeformat"
                            value={timeformat}
                            onChange={(e) => handleChange(e)}
                          // placeholder="col-form-label"
                          />

                        </div>
                        <span className="text-danger">{errors?.timeformat}</span>
                      </div>
                    </div> */}
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          TIME FORMAT:
                        </label>
                        <div class="col-lg-8">
                          <select
                            class="form-control"
                            id="colFormLabel"
                            name="timeformat"
                            value={timeformat}
                            onChange={(e) => handleChange(e)}
                          // placeholder="col-form-label"
                          >
                            {options1 && options1?.length > 0 && options1?.map((val, i) => {
                              return (<option>{val}</option>)
                            })}
                          </select>
                          <span className="text-danger">{errors?.timeformat}</span>
                        </div>

                      </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          COUNTRY:
                        </label>
                        <div class="col-lg-8">
                          <input type="text"
                            class="form-control"
                            id="colFormLabel"
                            value={country}
                            name="country"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                        <span className="text-danger">{errors?.country}</span>
                      </div>
                    </div> */}
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          IMAGE
                        </label>
                        <div class="col-lg-8">
                          <div className="chs_btn_sec">
                            <button className="btn btn-choosen" onClick={handleFileUpload}>Choose</button>
                            <input
                              type="file"
                              class="form-control"
                              name="logoURI"
                              accept=".jpg,.jpeg,.png"
                              onChange={(e) => handleChange(e)}
                              ref={fileInputRef}
                              style={{ display: "none" }}


                            />

                            {console.log('logoURI', logoURI)}
                            <p>{typeof logoURI == "object" ? logoURI?.name : logoURI}</p>
                          </div>
                          <span className="text-danger">{errors?.logoURI}</span>
                        </div>
                      </div>
                      {typeof logoURI == "object" ? logoURI && logoURI?.name && <img src={URL.createObjectURL(logoURI)} className="img-fluid image_inside_tab" /> : logoURI != "" ? <img src={BACKURL + `/Images/${logoURI}`} className="img-fluid image_inside_tab" /> : "Upload Image"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <button className="commonBtn " onClick={() => { UpdateAccount("profileupdate") }}>
                      <img
                        src={vectorimg}
                        alt=""
                        className="account-update-btn"

                      />{" "}
                      <span className="ms-2 fw-bolder close-account-btn-hov">UPDATE ACCOUNT</span>
                    </button>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane
                eventKey="second"
                className="text-white position-relative"
                style={{ zIndex: "2" }}
              >
                <div className="basic-settings-content">
                  <div className="row public-info">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          PUBLIC NAME:
                        </label>
                        <div class="col-lg-8">
                          <input type="text" class="form-control" name="publicname" value={publicname} onChange={(e) => handleChange3(e)} />
                          <span className="text-danger">{errors?.publicname}</span>
                          <span>
                            This is what buyers will see when they send you a
                            payment leave blank to show your username.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          PUBLIC EMAIL:
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="email"
                            class="form-control"
                            id="colFormLabel"
                            name="publicemail"
                            value={publicemail}
                            onChange={(e) => handleChange3(e)}
                          // placeholder="col-form-label"
                          />
                          <span className="text-danger">{errors?.publicemail}</span>
                          <span>

                            This is the email what buyers will see when they
                            send you a payment leave blank to show your account
                            email.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          PUBLIC URL:
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="colFormLabel"
                            name="publicurl"
                            value={publicurl}
                            onChange={(e) => handleChange3(e)}
                          // placeholder="col-form-label"
                          />
                          <span className="text-danger">{errors?.publicurl}</span>
                          <span>
                            This is shown on your feedback page .[optional]
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="public-info">
                    <div class="mb-4 d-flex">
                      <label for="colFormLabel" class="col-form-label">
                        USE GRAVATA:
                      </label>
                      <div class="checkbox-sec">
                        <div class="form-check custom-checkbox">
                          <input
                            class="form-check-input custom-public-info-checkbox"
                            type="checkbox"
                            id="check1"
                            name="usegravata"
                            checked={usegravata}
                            onChange={(e) => { setUserPublicDetails({ ...userpublicdetails, ... { "usegravata": e?.target?.checked } }) }}
                          />
                          <span className="text-danger">{errors?.usegravata}</span>     <br></br>
                          <label class="form-check-label">
                            If enabled your Gravatar image is shown on your
                            feedback page. (uses yoyr Public Email if one is set
                            or your Account Email otherwise)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center mt-5">
                    <button className="commonBtn ">
                      <img
                        src={vectorimg}
                        alt=""
                        className="account-update-btn"
                      />{" "}
                      <span className="ms-2 ms-2 fw-bolder close-account-btn-hov" onClick={() => { UpdateAccount("publicinfoupdate") }}>UPDATE ACCOUNT</span>
                    </button>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane
                eventKey="third"
                className="text-white position-relative"
                style={{ zIndex: "2" }}
              >
                <div className="basic-settings-content">
                  <div className="row public-info">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          CURRENT PASSWORD:
                        </label>
                        <div class="col-lg-8">
                          <div className="password_show_hide">
                            <input value={userpassworddetails?.currentpassword} type={showIcon ? "text" : "password"} class="form-control" name="currentpassword" onChange={(e) => handleChange1(e)} />
                            <div className="icon_sec">
                              {showIcon ? (
                                // <BiSolidShow onClick={() => setShowIcon(!showIcon)} />
                                <BiShow onClick={() => setShowIcon(!showIcon)} />
                              ) : (
                                // <BiSolidHide onClick={() => setShowIcon(!showIcon)} />
                                <BiHide onClick={() => setShowIcon(!showIcon)} />
                              )}
                            </div>
                          </div>
                          {/* </div> */}
                          <span className="text-danger">
                            {errors?.currentpassword != "" ? errors?.currentpassword : " Only needed if you ewant tos et a new password"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          NEW PASSWORD:
                        </label>
                        <div class="col-lg-8">
                          <div className="password_show_hide">
                            <input type={showIcon1 ? "text" : "password"} value={userpassworddetails?.newpassword} class="form-control" name="newpassword" onChange={(e) => handleChange1(e)} />
                            <div className="icon_sec">
                              {showIcon1 ? (
                                // <BiSolidShow onClick={() => setShowIcon(!showIcon)} />
                                <BiShow onClick={() => setShowIcon1(!showIcon1)} />
                              ) : (
                                // <BiSolidHide onClick={() => setShowIcon(!showIcon)} />
                                <BiHide onClick={() => setShowIcon1(!showIcon1)} />
                              )}
                            </div>
                          </div>
                          {/* </div> */}
                          <span className="text-danger">
                            {errors?.newpassword != "" ? errors?.newpassword : " Only needed if you ewant tos et a new password"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          CONFIRM PASSWORD:
                        </label>
                        <div class="col-lg-8">
                          <div className="password_show_hide">
                            <input type={showIcon2 ? "text" : "password"} class="form-control" value={userpassworddetails?.confirmpassword} name="confirmpassword" onChange={(e) => handleChange1(e)} />
                            <div className="icon_sec">
                              {showIcon2 ? (
                                // <BiSolidShow onClick={() => setShowIcon(!showIcon)} />
                                <BiShow onClick={() => setShowIcon2(!showIcon2)} />
                              ) : (
                                // <BiSolidHide onClick={() => setShowIcon(!showIcon)} />
                                <BiHide onClick={() => setShowIcon2(!showIcon2)} />
                              )}
                            </div>
                          </div>
                          {/* </div> */}
                          <span className="text-danger">
                            {errors?.confirmpassword != "" ? errors?.confirmpassword : " Only needed if you ewant tos et a new password"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          RECOVERY EMAIL:
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="colFormLabel"
                            name="recoveryemail"
                            value={userdetails?.recoveryemail}
                            onChange={(e) => handleChange1(e)}
                          // placeholder="col-form-label"
                          />
                          <span className="text-danger">
                            {errors?.recoveryemail}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          2FA:
                        </label>
                        <div class="col-lg-8">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              className="custom-drop-btn w-100 d-flex justify-content-end align-items-center"
                            ></Dropdown.Toggle>

                            <Dropdown.Menu className="w-100">
                              <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Something else
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <span>
                            Leave password fields blank for no change.
                          </span>
                        </div>
                      </div>
                    </div> */}
                    {console.log("lofinsac", userdetails)}
                    {userdetails && userdetails?.accounttype == "Merchant" && <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          KYC STATUS
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="colFormLabel"
                            readOnly
                            value={(userdetails?.KYCFile?.length == 0 && userdetails?.isKYCVerify === null) ?
                              "No KYC Documents" : (userdetails?.KYCFile?.length == 0 && userdetails?.isKYCVerify === false) ? "ReUpload Kyc Documents" : userdetails?.KYCFile?.length > 0 && userdetails?.isKYCVerify === true ? "Accept" :
                                userdetails?.KYCFile?.length > 0 && userdetails?.isKYCVerify === null ? "Waiting" : "Reject"}
                          />
                          <span>
                            {console.log('userdetailsdata', userdetails, userdetails?.KYCFile, userdetails?.KYCFile?.length < 0, userdetails?.KYCFile?.length == 0, (userdetails?.KYCFile?.length == 0 && userdetails?.isKYCVerify === null))}
                            {(userdetails?.KYCFile?.length == 0 && userdetails?.isKYCVerify === null) ?
                              "No KYC Documents" : (userdetails?.KYCFile?.length == 0 && userdetails?.isKYCVerify === false) ? "ReUpload Kyc Documents" : userdetails?.KYCFile?.length > 0 && userdetails?.isKYCVerify === true ? "Admin Accept your KYC Documents" :
                                userdetails?.KYCFile?.length > 0 && userdetails?.isKYCVerify === null ? "Waiting for admin response on your KYC douments" : "Admin reject your KYC Documents,so upload correct doucumnets"}
                          </span>
                        </div>
                        {/* { (userdetails?.KYCFile?.length == 0 && userdetails?.isKYCVerify === false)  && <button className="commonBtn link-your-trezor" onClick={() => setAccountmodal(true)}>
                          <img
                            src={closeuser}
                            alt=""
                            className="link-your-trezor-img"
                          />{" "}
                          <span className="ms-2 fw-bolder link-your-trezor-text">
                          ReUpload Kyc
                          </span>
                        </button>} */}
                      </div>
                    </div>}



                    <div className="col-12 mt-4">
                      <div
                        className="btn_sec_new_mob d-flex align-items-center justify-content-center account-settings"
                        style={{ gap: "5px" }}
                      >
                        {(userdetails?.KYCFile?.length == 0 && userdetails?.isKYCVerify === false) && <button className="commonBtn close-acoount-btn" onClick={() => setAccountmodal(true)}>
                          <img
                            src={closeuser}
                            alt=""
                            className="link-your-trezor-img"
                          />{" "}
                          <span className="ms-2 fw-bolder link-your-trezor-text reupload_kyc">
                            ReUpload Kyc
                          </span>
                        </button>}


                        {/* <button className="commonBtn link-your-trezor">
                          <img
                            src={closeuser}
                            alt=""
                            className="link-your-trezor-img"
                          />{" "}
                          <sapn className="ms-2 fw-bolder link-your-trezor-text">
                            LINK YOUR TREZOR
                          </sapn>
                        </button> */}
                        {/* <button className="commonBtn link-your-trezor">
                          <img
                            src={closeuser}
                            alt=""
                            className="link-your-trezor-img"
                          />{" "}
                          <span className="ms-2 fw-bolder link-your-trezor-text">
                            LINK YOUR TREZOR
                          </span>
                        </button> */}

                        <button className="commonBtn close-acoount-btn" onClick={() => { setShowClose(true) }}>
                          <img
                            src={linklogo}
                            alt=""
                            className="close-account-img-hov"
                          />{" "}
                          <span className="ms-2 ms-2 fw-bolder close-account-btn-hov buton_update_acct">
                            CLOSE ACCOUNT
                          </span>
                        </button>

                        <button className="commonBtn update-account-btn">
                          <img
                            src={vectorimg}
                            alt=""
                            className="update-btn-img-hov"
                          />{" "}
                          <span className="ms-2 ms-2 fw-bolder close-account-btn-hov buton_update_acct" onClick={() => { UpdateAccount("passwordupdate"); }}>UPDATE ACCOUNT</span>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

              </Tab.Pane>
              <Tab.Pane
                eventKey="fourth"
                className="text-white position-relative"
                style={{ zIndex: "2" }}
              >
                <div className="basic-settings-content">
                  <div className="row public-info">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          IPN SECRET:
                        </label>
                        <div class="col-lg-8">
                          <input type="text" class="form-control" name="ipnsecret" value={ipnsecret} onChange={(e) => handleChange2(e)} />
                          <span>
                            This is used to verify that an IPN is from us, use a good random string nobody can guess.
                          </span>
                          <span className="text-danger">{errors?.ipnsecret}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          IPN URL:
                        </label>
                        <div class="col-lg-8">
                          <input
                            type="text"
                            class="form-control"
                            id="colFormLabel"
                            name="ipnurl"
                            value={ipnurl}
                            onChange={(e) => handleChange2(e)}
                          // placeholder="col-form-label"
                          />

                          <span>
                            IPN URL : HTTPS URL recommended (self-signed certs OK)
                            If you are using one of our shopping cart plugins you can leave this field blank.
                          </span>
                          <span className="text-danger">{errors?.ipnurl}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">

                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          STATUS/LOG EMAIL:
                        </label>
                        <div class="col-lg-8">
                          {/* <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              className="custom-drop-btn w-100 d-flex justify-content-end align-items-center"
                            ></Dropdown.Toggle>

                            <Dropdown.Menu className="w-100">
                              <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Something else
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown> */}

                          <input
                            type="text"
                            class="form-control"
                            id="colFormLabel"
                            name="logemail"
                            value={logemail}
                            onChange={(e) => handleChange2(e)}
                          // placeholder="col-form-label"
                          />

                          <span>
                            If set payment status emails and Positive feedback notifications will be sent to this email address. Otherwise they will be sent to your primary email address.
                          </span>

                          <span className="text-danger">{errors?.logemail}</span>
                        </div>
                      </div>




                      {/* <div className="row">
                        <div className="col-lg-5">
                          <p>WHEN TO RECEIVE EMAILS :</p>
                        </div>
                        <div className="col-lg-7">
                          <div className="checkHolder">
                            <input type="checkbox" className="" />
                            <p> When a user submits a new payment to you.</p>
                          </div>
                          <div className="checkHolder">
                            <input type="checkbox" className="" />
                            <p> When funds for a payment have been sent to you.


                            </p>
                          </div>
                          <div className="checkHolder">
                            <input type="checkbox" className="" />
                            <p className="check_Label">When funds for a payment have been sent to you.</p>
                          </div>
                          <div className="checkHolder">
                            <input type="checkbox" className="" />
                            <p> When a deposit is received on one of your deposit addresses.</p>
                          </div>
                          <div className="checkHolder">
                            <input type="checkbox" className="" />
                            <p>When positive feedback has been left for you.</p>
                          </div>
                        </div>
                      </div> */}
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          CALLBACK DEPOSIT IPN FIAT CURRENCY
                        </label>
                        <div class="col-lg-8">
                          <select
                            class="form-control"
                            id="colFormLabel"
                            name="ipnfiatcurrency"
                            value={ipnfiatcurrency}
                            onChange={(e) => handleChange2(e)}
                          >
                            {options3 && options3?.length > 0 && options3?.map((val, i) => {
                              return (<option>{val}</option>)
                            })}
                          </select>
                          <span className="text-danger">{errors?.ipnfiatcurrency}</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
                          <p>WHEN TO RECEIVE EMAILS :</p>

                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12 mb-3">
                          <div className="checkHolder">
                            <input
                              type="checkbox"
                              className="checkInput"
                              value={receiveemail?.newpayment}
                              name="newpayment"
                              onChange={(e) =>
                                setUserMerchantDetails({
                                  ...usermerchantdetails,
                                  ...{
                                    "receiveemail":
                                    {
                                      ...usermerchantdetails.receiveemail, ...{ newpayment: e?.target?.checked }
                                    }
                                  }
                                })
                              }
                            />
                            <p>When a user submits a new payment to you.</p>
                          </div>
                          <div className="checkHolder">
                            <input type="checkbox" className="checkInput" name="fundsreceived" checked={receiveemail?.fundsreceived} onChange={(e) => setUserMerchantDetails({ ...usermerchantdetails, ...{ "receiveemail": { ...usermerchantdetails.receiveemail, ...{ fundsreceived: e?.target?.checked } } } })} />
                            <p> When funds have been received by us for a payment to you.


                            </p>
                          </div>
                          <div className="checkHolder">
                            <input type="checkbox" className="checkInput" name="fundspayment" checked={receiveemail?.fundspayment} onChange={(e) => setUserMerchantDetails({ ...usermerchantdetails, ...{ "receiveemail": { ...usermerchantdetails.receiveemail, ...{ fundspayment: e?.target?.checked } } } })} />
                            <p className="check_Label">When funds for a payment have been sent to you.</p>
                          </div>
                          <div className="checkHolder">
                            <input type="checkbox" className="checkInput" name="depositreceived" checked={receiveemail?.depositreceived} onChange={(e) => setUserMerchantDetails({ ...usermerchantdetails, ...{ "receiveemail": { ...usermerchantdetails.receiveemail, ...{ depositreceived: e?.target?.checked } } } })} />
                            <p> When a deposit is received on one of your deposit addresses.</p>
                          </div>
                          <div className="checkHolder">
                            <input type="checkbox" className="checkInput" name="positivefeedback" checked={receiveemail?.positivefeedback} onChange={(e) => setUserMerchantDetails({ ...usermerchantdetails, ...{ "receiveemail": { ...usermerchantdetails.receiveemail, ...{ positivefeedback: e?.target?.checked } } } })} />
                            <p>When positive feedback has been left for you.</p>
                          </div>
                          <span className="text-danger">{errors?.receiveemail}</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 bottom_alignBtn">

                      <button className="commonBtn" onClick={() => UpdateAccount("merchantupdate")}>
                        <img
                          src={vectorimg}
                          alt=""
                          className="account-update-btn me-2"
                        />{" "}

                        UPDATE ACCOUNT
                      </button>

                    </div>



                  </div>



                  {/* <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          STATUS/LOG EMAIL:
                        </label>
                        <div class="col-lg-8">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              className="custom-drop-btn w-100 d-flex justify-content-end align-items-center"
                            ></Dropdown.Toggle>

                            <Dropdown.Menu className="w-100">
                              <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Something else
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <span>
                            Leave password fields blank for no change.
                          </span>
                        </div>
                      </div> */}
                </div>

              </Tab.Pane>
              {/* <div
                className="position-absolute line-logo-disable"
                style={{ bottom: "-86px", right: "-32px", zIndex: "1" }}
              >
                <img src={bottomrightlogo} />
              </div> */}
            </Tab.Content>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
}

export default AccountSettings;
