import React, { useEffect, useState } from "react";
import { Nav, Navbar, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";

import FilterCoins from "../utils/FilterCoins";
import Heading from "../utils/Heading";

import topimg from "../../asset/IMG/top-img.png";
import send from "../../asset/IMG/send.png";
import receive from "../../asset/IMG/receive.png";
import transTop from "../../asset/IMG/transaction-top.png";
import transBot from "../../asset/IMG/transaction-bottom.png";
import star from "../../asset/IMG/star2.png";
import halfPoly from "../../asset/IMG/Polygon8.png";
import doubleBar from "../../asset/IMG/double_rec.png";
import DepositHistory from "../utils/DepositHistory";
import WithdrawHistory from "../utils/WithdrawHistory";
import TransferHistory from "../utils/TransferHistory";
import ConversionHistory from "../utils/ConversionHistory";
import P2PHistory from "../utils/P2PHistory";
import MassWithdrawal from "../utils/MassWithdrawal";
import historyImg from "../../asset/IMG/historyimg.png";
import PopularCoin from "../utils/PopularCoin";
import Allcrypto from "../utils/Allcrypto";
import StableCoin from "../utils/StableCoin";
import Token from "../utils/Token";
import WalletOnly from "../utils/WalletOnly";
import support from "../../asset/IMG/support-img.png";
import support2 from "../../asset/IMG/support2.png";
import polygon5 from "../../asset/IMG/polygon5.png";
import polygon6 from "../../asset/IMG/polygon6.png";
import Footer from "../Layout/Footer";
import supportStar from "../../asset/IMG/support_Coin-star.png";
import polygon8 from "../../asset/IMG/Polygon 8.png";
import { style, position, iconTheme } from "../../hooks/UseToast";
import search from "../../asset/IMG/search.png";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { sleep } from "../../hooks/UseUser";
import toast from "react-hot-toast";

const SupportCoin = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("first");
  const [searchdata, setSearchData] = useState("");

  // useEffect(() => {
  //   (async () => {
  //     const UserToken = sessionStorage.getItem("accessToken")
  //     if (!(UserToken != '' && UserToken != null && UserToken != undefined && UserToken != "undefined")) {
  //       navigate("/")
  //       toast.error("Please login first !", {
  //         position: position.position,
  //         style: style,
  //         iconTheme: iconTheme,
  //       })
  //       await sleep(1000)
  //     }
  //   }) ();
  // }, []);

  const handleSearch = (e) => {
    setSearchData(e?.target?.value);
  };

  useEffect(() => {
    console.log("key_data", key);
    if (
      key == "first" ||
      key == "second" ||
      key == "third" ||
      key == "fourth" ||
      key == "fifth"
    ) {
      setSearchData("");
    }
  }, [key]);

  return (
    <>
      <section className="paddingY walletSection">
        {/* <div className="star">
          <img src={star} alt="star" className="img-fluid" />
        </div> */}

        <div className="wallet_top_container position-relative">
          {/* <div className="support_coin side-star position-absolute">
            <img src={supportStar} alt="star" className="img-fluid" />
          </div> */}
          {/* <div className="doubleBar">
            <img src={doubleBar} alt="Double Rectangle" className="img-fluid" />
          </div> */}

          {/* <div className="history halfPoly d-none d-lg-block">
            <img src={halfPoly} alt="half polygon" className="img-fluid" />
          </div> */}
          {/* <div className="halfPoly">
    <img src={halfPoly} alt="half polygon" className="img-fluid" />
  </div> */}
          {/* <div className="red_dot"></div>
          <div className="yellow_dot"></div> */}
          {/* <div className="doubleBar">
            <img src={doubleBar} alt="Double Rectangle" className="img-fluid" />
          </div> */}
          <div className="container">
            <div className="walletTop">
              <p className="titleP">Support coins</p>
              <p className="subtitleP">
                Store, send and receive cryptocurrencies <br /> through{" "}
                <span>COINPAYMATE</span>{" "}
              </p>
              {/* <div className="supportAvatar d-none d-lg-block">
                <img src={support} alt="History Avatar" className="img-fluid" />
              </div> */}
              <div className="supportAvatar2 d-block d-lg-none">
                <img
                  src={support2}
                  alt="History Avatar"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={key}
          onSelect={(k) => setKey(k)}
        >
          <div className="container d-flex align-items-center supportCoin_cont">
            <Nav
              variant="pills"
              className="d-flex nav_tabs supportCoin_Container tab_scroll_x"
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  className="active_link_clrChange white_space_nowrap"
                >
                  Popular coin
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  className="active_link_clrChange white_space_nowrap"
                >
                  all Crypto
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="third"
                  className="active_link_clrChange white_space_nowrap"
                >
                  Stable coin
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="fourth"
                  className="active_link_clrChange white_space_nowrap d-flex align-items-center"
                >
                  Token{" "}
                  <span className="ms-2">
                    <AiOutlinePlusCircle />
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="fifth"
                  className="active_link_clrChange white_space_nowrap"
                >
                  Wallet only{" "}
                  <span className="ms-2">
                    <AiOutlinePlusCircle className="plus_iconActive" />
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <div className="support_coin searchbar">
              <input
                type="text"
                placeholder="FILTER BY COIN NAME"
                value={searchdata}
                onChange={(e) => handleSearch(e)}
              />
              <img src={search} alt="search" className="img-fluid" />
            </div>
          </div>

          <div className="tabContainerFluid background_img">
            {/* <div className="polygon_side position-absolute">
              <img src={polygon5} alt="polygon" className="img-fluid" />
            </div> */}
            {/* <div className="polygon_bot position-absolute">
              <img src={polygon6} alt="polygon" className="img-fluid" />
            </div> */}
            <div className="container">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <PopularCoin searchdata={key == "first" ? searchdata : ""} />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Allcrypto searchdata={key == "second" ? searchdata : ""} />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <StableCoin searchdata={key == "third" ? searchdata : ""} />
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <Token searchdata={key == "fourth" ? searchdata : ""} />
                </Tab.Pane>
                <Tab.Pane>
                  <WalletOnly
                    eventKey="fifth"
                    searchdata={key == "fifth" ? searchdata : ""}
                  />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
        {/* <button className="commonBtn">
    <div className="leftBtnBg"></div>

    <div className="z-2">sign in</div>
  </button> */}
      </section>
      <Footer />
    </>
  );
};

export default SupportCoin;
