// // Local
// export const FRONTENDURL = "http://localhost:3000";
// export const BACKURL = "http://localhost:8009";
// change the sitekey recapcha.js live  when move to live

//demo


export const FRONTENDURL = "https://coinpaymate.maticz.in";
export const BACKURL = "https://backend-coinpaymate.maticz.in"
export const TENMINUTESINMILISECONDS = 600000;
