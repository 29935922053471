import axios from "axios";
import { BACKURL } from "../config/env";
import { position, iconTheme, style } from "./UseToast";
import toast from "react-hot-toast";

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getAuthToken = () => {
  try {
    let accessToken = sessionStorage.getItem("accessToken");
    
    if (accessToken != "" && accessToken != null && accessToken != undefined) {
      return `Bearer ${accessToken}`;
    }
  } catch (e) {
    console.log("getAuthToken_err", e);
  }
};

export const Test = () => {
  axios.defaults.headers.common["Authorization"] = getAuthToken();
};

axios.defaults.headers.common["Authorization"] = getAuthToken();

export const createuserhooks = async (data) => {
  try {
    console.log("register data", data);
    let respData = await axios.post(BACKURL + "/api/User/NewCreate", data);
    console.log("registerrespData", respData);
    if (respData?.status == 200) {
      return respData;
    }
  } catch (e) {
    console.log("userregister_err", e);
  }
};

export const validateloginhooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/Login", data);
    if (respData?.status == 200) {
      return respData;
    }
  } catch (e) {
    console.log("userregister_err", e);
  }
};

export const emailverificationhooks = async (data) => {
  try {
    let respData = await axios.post(
      BACKURL + "/api/User/EmailVerification",
      data
    );
    return respData;
  } catch (e) {
    console.log("emailverificationhooks_err", e);
  }
};

export const checkloginotphooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/CheckLoginOtp", data);
    return respData;
  } catch (e) {
    console.log("checkloginotphooks_err", e);
  }
};

export const updateaccounttypehooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/UpdateAccount", data);
    if (respData?.data?.success == true) {
      Toast("success", respData?.data?.data);
    } else {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("updateaccounttypehooks_err", e);
  }
};

export const getuserdatabytoken = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/user/infobytoken", data);
  
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (respData?.data?.success == false) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("getuserdatabytoken_err", e);
  }
};

export const usereditprofilehooks = async (data) => {
  try {
    console.log("user edit updatedata", data)
    const {
      email,
      gender,
      timezone,
      dateformat,
      timeformat,
      firstName,
      lastName,
      username,
      mobileNo,
      country,
      userId,
      logoURI,
      filter,
      currentpassword,
      newpassword,
      addressname,
      address,
      address2,
      state,
      city,
      zipcode,
      defaultaddress,
      recoveryemail,
      user_name,
    } = data;
    let formData = new FormData();
    if (filter == "profileupdate") {
      formData.append("user_name", username?.toLowerCase());
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("email", email);
      formData.append("gender", gender);
      formData.append("Timezone", timezone);
      formData.append("filter", filter);
      formData.append("userId", userId);
      formData.append("mobileNo", mobileNo);
      formData.append("dateformat", dateformat);
      formData.append("timeformat", timeformat);
      formData.append("logoURI", logoURI);
    } else if (filter == "passwordupdate") {
      formData.append("filter", filter);
      formData.append("currentpassword", currentpassword);
      formData.append("password", newpassword);
      formData.append("userId", userId);
      formData.append("user_name", username?.toLowerCase());
    } else if (filter == "addressupdate") {
      formData.append("filter", filter);
      formData.append("Country", country);
      formData.append("addressname", addressname);
      formData.append("address", address);
      formData.append("address2", address2);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("zipcode", zipcode);
      formData.append("userId", userId);
      formData.append("defaultaddress", defaultaddress);
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("mobileNo", mobileNo);
    } else if (filter == "recoveryemailupdate") {
      formData.append("recoveryemail", recoveryemail);
      formData.append("user_name", user_name?.toLowerCase());
      formData.append("filter", filter);
      formData.append("userId", userId);
    }

    let respData = await axios.post(
      BACKURL + "/api/user/editprofile",
      formData
    );
    console.log(
      "respData_respData",
      respData?.data,
      respData?.data?.data,
      respData?.data?.data != []
    );
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success != "success" &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }

    return respData;
  } catch (e) {
    console.log("usereditprofilehooks_err", e);
  }
};

export const requestemailhooks = async (data) => {
  try {
    console.log("asdfbyk asdvfasdfasd requestemailhooks", data);
    const respData = await axios.post(
      BACKURL + "/api/user/request/reset",
      data
    );
    console.log("requestemailhooks", data, respData?.data?.data);

    if (respData?.data?.success == true) {
      Toast("success", respData?.data?.data);
    } else {
      Toast("error", respData?.data?.data);
    }
  } catch (err) {}
};

export const getallassetdatahooks = async (data) => {
  try {
   
    let respData = await axios.post(BACKURL + "/api/admin/getAsset", data);
    console.log("respData_getallassetdatahooks", respData);
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("getallassetdatahooks_err", e);
  }
};

export const getuserallapikeyshooks = async (data) => {
  try {
    let respData = await axios.post(
      BACKURL + "/api/user/infobyallapikey",
      data
    );
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("getuserallapikeyshooks_err", e);
  }
};

export const generateuserapikeyhooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/CreateApikey", data);

    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("generateuserapikeyhooks_err", e);
  }
};

export const updateuserapikeyhooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/UpdateApiKey", data);
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("updateuserapikeyhooks_err", e);
  }
};

export const getuserapikeyhooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/infobyapikey", data);
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("getgenerateuserapikeyshooks_err", e);
  }
};

export const deleteuseraddressdatahooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/DeleteAddress", data);
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("deleteuseraddressdatahooks_err", e);
  }
};

export const userresetpasswordhooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/ResetPassword", data);
    console.log("resPdata", respData);
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("userresetpasswordhooks_err", e);
  }
};

export const getuserdetailsbyname = async (username) => {
  try {
    let respData = await axios.get(
      `${BACKURL}/api/user/infobyname/${username}`
    );
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("getuserdetailsbyname_err", e);
  }
};

export const getuserwalletdetails = async (data) => {
  try {
    console.log("userasdfasdfasd", data);
    let respData = await axios.post(
      BACKURL + "/api/User/getUserWalletData",
      data
    );
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("getuserwalletdetails_err", e);
  }
};

export const getuseraccountdisabledhooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/closeAccount", data);
    console.log(
      "closeAccountrespData",
      respData,
      respData?.data?.success == true && respData?.data?.data != undefined
    );
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("getuserwalletdetails_err", e);
  }
};

export const userupdatemerchantsettings = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/UpdateMerchantSetting", data);
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    
  }
}


export const userupdatepublicdetails = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/UpdatePublicInfo", data);
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("userupdatepublicdetails_err", e)
  }
}

export const getusermerchantId = async (merchantId) => {
  try {
    let respData = await axios.get( `${BACKURL}/api/user/merchantId/${merchantId}` );
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("getusermerchantId_err", e)
  }
}


export const userupdatelabelhooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/UpdateLabel", data);
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log('userupdatelabel_err', e)
  }
}

export const getuserparticularwalletdatahooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/getUserAssWalletData", data);
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data?.address);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("getuserparticularwalletdatahooks_err", e)
  }
}


export const useraddnewlabeldatahooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/AddnewLabel", data);
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("useraddnewlabeldatahooks_err", e)
  }
}


export const getusertransdetailshooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/getUserTransaction", data);
    if (respData?.data?.success == true && respData?.data?.data != undefined) {
      Toast("success", respData?.data?.data);
    } else if (
      respData?.data?.success == false &&
      respData?.data?.data != undefined
    ) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("getusertransdetailshooks_err", e)
  }
}


export const addfeedbackhooks = async (data) => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/getAllFeedBacks", data);
  
    if (respData?.data?.status == true && respData?.data?.message != undefined) {
      Toast("success", respData?.data?.message);
    } else if (respData?.data?.success == false) {
      Toast("error", respData?.data?.data);
    }
    return respData;
  } catch (e) {
    console.log("getuserdatabytoken_err", e);
  }
};


export const getallfeedbackdatahooks = async () => {
  try {
    let respData = await axios.post(BACKURL + "/api/User/getAllFeedBacks");
    if (respData?.data?.status == true ) {
      return respData;
    } 
  } catch (e) {
    console.log("getallfeedbackdatahooks_err", e)
  }
}


export const Toast = async (filter, message) => {
  try {
    if (filter == "success") {
      toast.success(message, {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      });
      await sleep(1000);
    } else {
      console.log("tilerrrrrr", filter, message);
      toast.error(message, {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      });
      await sleep(1000);
    }
  } catch (e) {
    console.log("Toast_err", e);
  }
};


export const copyUrl = async (message) => {
  toast.success(message, {
    position: position.position,
    style: style,
    iconTheme: iconTheme,
  });
  await sleep(1000);
}



export const getAssetsymbol = async (symbol) => {

 
  try {
    let respData = await axios.get(BACKURL + `/api/asset/symbol/${symbol}`);
   
    if (respData?.data?.success == true) {
      return respData?.data?.data;
    }
  } catch (e) {
    console.log("userregister_err", e);
  }
};



