import React, { useEffect, useState } from "react";
import icon from "../../asset/IMG/icon.png";
import search from "../../asset/IMG/search.png";
import filter from "../../asset/IMG/filter.png";
import { FaFilter } from "react-icons/fa";
import { BiSolidDownArrow } from "react-icons/bi";

const Heading = ({ name, getSearchData, walletCoinData }) => {
  const [WalletCoin, setWalletCoin] = useState([]);
  const [searchdata, setSearchData] = useState("");
  console.log("walletCoinData", walletCoinData, WalletCoin);
  useEffect(() => {
    setWalletCoin(walletCoinData);
  }, [walletCoinData]);
  return (
    <div className="heading">
      <div className="left">
        <img src={icon} alt="icon" className="img-fluid" />
        <p>{name}</p>
      </div>
     {WalletCoin && WalletCoin?.length > 0 && <div className="right gap-3 d-flex flex-column flex-sm-row">
        <div className="searchbar srch_bar_wid">
          <input
            type="text"
            placeholder="FILTER BY COIN NAME"
            onChange={(e) => {
              console.log("asdfasdf", e?.target?.value);
              getSearchData(e?.target?.value);
            }}
          />
          <img src={search} alt="search" className="img-fluid" />
        </div>
        <div class="dropdown custom-dropdown-col ms-sm-4 flt_btn_wd">
          <button
            type="button"
            class="btn btn-primary custom-filter-btn new_common_btn d-flex align-items-center "
            data-bs-toggle="dropdown"
          >
            <div className="filter-text-hover-effect d-flex align-items-center">
              <FaFilter />{" "}
              <span className="px-1 text-bolder fw-bolder">
                {searchdata != "" ? searchdata : "FILTER"}
              </span>
              <BiSolidDownArrow size={10} />
            </div>
          </button>
          <ul class="dropdown-menu" data-popper-placement="bottom-start">
            <li>
              {WalletCoin &&
                WalletCoin?.length > 0 &&
                WalletCoin?.map((val) => {
                  return (
                    <>
                      <a
                        class="dropdown-item"
                        onClick={(e) => {
                          getSearchData(val);
                          setSearchData(val);
                        }}
                      >
                        {" "}
                        {val}
                      </a>
                    </>
                  );
                })}
            </li>
          </ul>
        </div>
      </div>}
    </div>
  );
};

export default Heading;
