import React, { useEffect, useState } from "react";
import { popularCoin } from "../../Data/data";
import popularCoinsIcon from "../../asset/IMG/popularcoins.png";
import recTop from "../../asset/IMG/popularCoin-doublebarTop.png";
import recBot from "../../asset/IMG/popularCoin-doubleBot.png";
import { getallassetdatahooks } from "../../hooks/UseUser";
import { BACKURL } from "../../config/env";

const PopularCoin = (props) => {

  const [records, setRecords] = useState([]);
  const [status, setStatus] = useState(true)

  useEffect(() => {
    async function fetchData() {
      try {
        console.log("(props?.searchdata)?.length == 0", props?.searchdata, (props?.searchdata)?.length, (props?.searchdata)?.length == 0);
        const UserToken = sessionStorage.getItem("accessToken")
        if (UserToken != '' && UserToken != null && UserToken != undefined && UserToken != "undefined") {
          let reqdata = {
            filter: "all",
            search: props?.searchdata != "" ? props?.searchdata : ""
          }
          let res = await getallassetdatahooks(reqdata);
          console.log("resssssssss", res?.data?.record)
          setRecords(res?.data?.record)
        }
      } catch (e) {

      }
    }
    fetchData();
  }, [props]);



  return (
    <>
      <div className="row">
        <>
          {records && records?.length > 0 ? records?.map((val, i) => (
            <>{
              (status && (i < 9 == true)) ?
                <div
                  className="col-12 col-md-6  col-lg-4 "
                  style={{ marginTop: "40px" }}
                >
                  <div className="popular-container">
                    <div className="container position-relative">
                      <div className="row justify-content-between rows">
                        <div className="col-5 d-flex align-items-center justify-content-center cols-div z-2 ">
                          <div className="d-flex align-items-center coin-div ">
                            <div className="coin-img">
                              <img src={`${BACKURL}/Images/${val.logoURI}`} alt="btc" className="img-fluid" />
                            </div>
                            <div className="coin-txt">
                              <div className="coin-title">
                                <p>Coin Name</p>
                              </div>
                              <div className="coin-name">
                                <p>{val?.name}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-4 d-flex align-items-center justify-content-center cols-div">
                          <div className="d-flex align-items-center coin-div ">
                            <div className="coin-txt">
                              <div className="coin-title code_txt">
                                <p className="">Code</p>
                              </div>
                              <div className="coin-name">
                                <p>{val?.symbol}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-3 d-flex align-items-center justify-content-center cols">
                          <div>
                            <img
                              src={popularCoinsIcon}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> :

                (!status) && <div
                  className="col-12 col-md-6  col-lg-4 "
                  style={{ marginTop: "40px" }}
                >
                  <div className="popular-container">
                    <div className="container position-relative">
                      <div className="row justify-content-between rows">
                        <div className="col-5 d-flex align-items-center justify-content-center cols-div z-2 ">
                          <div className="d-flex align-items-center coin-div ">
                            <div className="coin-img">
                              <img src={`${BACKURL}/Images/${val.logoURI}`} alt="btc" className="img-fluid" />
                            </div>
                            <div className="coin-txt">
                              <div className="coin-title">
                                <p>Coin Name</p>
                              </div>
                              <div className="coin-name">
                                <p>{val?.name}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-4 d-flex align-items-center justify-content-center cols-div">
                          <div className="d-flex align-items-center coin-div ">
                            <div className="coin-txt">
                              <div className="coin-title code_txt">
                                <p className="">Code</p>
                              </div>
                              <div className="coin-name">
                                <p>{val?.symbol}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-3 d-flex align-items-center justify-content-center cols">
                          <div>
                            <img
                              src={popularCoinsIcon}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            }</>

          )) : <>  <div className="empty_rec">
            <p >No Records</p>
          </div>
          </>}</>
      </div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "50px" }}
      >
        {(records?.length > 9 && status) && <button className="commonBtn show_more_btn">
          {<p className="z-2" onClick={() => { setStatus(false) }}>Show All Coins</p>}
        </button>}
      </div>
    </>
  );
};

export default PopularCoin;
