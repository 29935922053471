import React, { useContext, useState, useMemo ,useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { AiOutlineClose } from "react-icons/ai";
import Select from 'react-select'
import countryList from 'react-select-country-list';
import { getuserdatabytoken } from '../../hooks/UseUser';
import { Useuploadfile } from '../../hooks/UseBackend';
import toast from 'react-hot-toast';
import { iconTheme, position, style } from '../../hooks/UseToast';
import Fileimage from "../../asset/IMG/Fees/pdf.png";



const   Accountmodal = (props) => {

    const [accountmodal, setAccountmodal] = useState(true);
    const AddClose = () => setAccountmodal(false);
    const [userToken, setUserToken] = useState();
    
    const [country, setcountry] = useState('')
    const options = useMemo(() => countryList().getData(), [])
    const [file, set_file] = useState({})

    const [imagesize, setimagesize] = useState(50)
    const [imagepath, set_imagepath] = useState("")
    const [orginalsize,setorginalsize] = useState()
    const [ImageURL, setImageURL] = useState("") 
    const [fileType, setfileType] = useState("")

    const [doctype,setdoctype] = useState("")

  


    useEffect(() => {
        let UserToken = sessionStorage.getItem("accessToken");
        setUserToken(UserToken);
      
      },[userToken]);
    
      const FileUpload = event => {
        var name = event.target.value;
        var size = Number(event.target.files[0].size);
        var type = event.target.files[0].type;
        setfileType(type)
        console.log("type",event.target.files[0].type);
        if(size > imagesize  * 1000){
          toast.error(`Please select file size less than ${imagesize} KB`, {

          
            position:position.position,
            style:style,
            iconTheme: iconTheme,
          })
        
        }else if(type !== "image/png" && type !== "image/jpeg" &&  type !== "application/pdf" &&  type !== "jpg"){
        


            toast.error(`Please upload file having extensions .jpeg/.jpg/.png/.pdf only.`, {
                position:position.position,
                style:style,
                iconTheme: iconTheme,
              })

        }
        else{
            set_imagepath(name);
            var url = URL.createObjectURL(event.target.files[0])
            set_file(event.target.files[0]);
          setImageURL(url)
          setorginalsize(size);
        
        }
    };


    const changeHandler = value => {
        setcountry(value)
    }
    const onSubmit = async () => {
      

        const fileData ={
            assessToken:userToken,
            country: country?.label,
            FileType:doctype,
            file:file
           };
           
           await Useuploadfile(fileData)
        }

    return (

        <div className=''>
  

            <Offcanvas show={accountmodal} placement="top" onHide={AddClose} className="login-section canvas-section">

                <Offcanvas.Body className="canvas-body">
                    <button
                        type="button"
                        class=" position-absolute canvas-closebtn close_btn_icon"
                        aria-label="Close"
                        onClick={props.onDismiss}
                    >
                        <AiOutlineClose />
                    </button>

                    <div className='mt-5'>
                        <div className='container'>
                            <h3 className='text-center mb-5 fw-600'>Upload KYC Documents</h3>
                            <div className='row'>
                                <div className='col-md-6 mx-auto'>
                                    <h6 className='mb-3 fw-600'>Select Your Country of Residence</h6>
                                    <Select options={options} 

                                     value={country}
                                     required
                                     placeholder="Select Country"
                                     onChange={changeHandler} />
                                </div>
                                 <div className='col-md-6 mx-auto'>
                                    <h6 className='mb-3 fw-600'>Select document Type</h6>
                                    <select class="form-select" 
                                    aria-label="Default select example"
                                    value={doctype}
                                    required
                                    onChange={(e) => setdoctype (e.target.value)}>
                                            <option value="Select document" selected>Select document</option>
                                            <option value="Passport">Passport</option>
                                            <option value="voterID">Voter's Identity Card.</option>
                                            <option value="DrivingLicence">Driving Licence</option>
                                            <option value="AadhaarCard"> Aadhaar Card.</option>
                                        </select>
                                       
                                </div>

                                <div className='col-md-6 mt-2'>
                                    <div className='row align-items-center'>
                                        <div className='col-md-12 col-sm-12'>
                                        <h6 className='mb-3 mt-4 fw-600'>Document Upload</h6>
                                        </div>
                                        <div className='col-md-5 col-lg-5 col-sm-3'>
                                        <div className='browseinput'><input type="file" onChange={FileUpload} /><button className='btn commonBtn register_btn'> Upload Image</button>  </div>                                    
                                        </div>
                                        <div className='col-md-6 col-lg-6 col-sm-9 text-end'>
                                            {console.log("fileType",fileType)}
                                        {
                                        imagepath.length !== 0  &&  fileType  !== "application/pdf" ? 
                                        <img src={ImageURL} className='img-fluid modalprevimg previewimg mt-2'/> :
                                      
                                        imagepath.length !== 0 &&  fileType  === "application/pdf" ? 
                                      <img src={Fileimage} className='img-fluid modalprevimg previewimg mt-2' /> : <></>} 
                                      
                                        </div>
                                    </div>

                                    </div>
                                

                                  
                                <div className='mt-4 mb-4 textcenter'>
                                    {fileType != "" ?
                                    <button
                                        onClick={() => onSubmit()}
                                        className="z-2 commonBtn register_btn"
                                        style={{
                                            cursor: "pointer",
                                            textTransform: "none",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                        }}
                                    > Done
                                    </button> : 
                                      <button
                                     disabled={true}
                                      className="z-2 commonBtn register_btn"
                                      style={{
                                          cursor: "pointer",
                                          textTransform: "none",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                      }}
                                  > Done
                                  </button>}
                                </div>
                            </div>
                        </div>
                    </div>


                </Offcanvas.Body>

            </Offcanvas>

        </div>
    )
}

export default Accountmodal;