import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import { IoMdClose } from "react-icons/io";

import failedIcon from "../../asset/SVG/failed.svg";

const PaymentFailed = ({ failedShow, setFailedShow }) => {
  const handleClose = () => setFailedShow(false);
  return (
    <Modal
      show={failedShow}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        <div className="position-relative py-5">
          <div className="pm_mdl_cls_icn position-absolute">
            <IoMdClose className="fs-4 " onClick={handleClose} />
          </div>
          <div className=" d-flex flex-column align-items-center">
            <img src={failedIcon} alt="success" className="img-fluid" />
            <p className="mt-3 text-center fw-bold">Payment Failed.Try again</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentFailed;
