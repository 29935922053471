import React, { useEffect, useState } from "react";
import "../../../Styles/Dashboard.css";
import searchicon from "../../../asset/IMG/Fees/icon-park-outline_search.png";
import { Nav, Navbar, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import icon from "../../../asset/IMG/Fees/icon.png";
import { FaFilter } from "react-icons/fa";
import Dashboards from "./DashboardSubContant/Dashboards";
import AffiliateTools from "./DashboardSubContant/AffiliateTools";
import AccountSettings from "./DashboardSubContant/AccountSettings";
import CoinAcceptance from "./DashboardSubContant/CoinAcceptance";

import lineLogo from "../../../asset/IMG/Dashboard/line_logo.png";
import Polygon from "../../../asset/IMG/Dashboard/Polygon 1(1).png";
// import HalfPolygon from "../../../asset/IMG/Dashboard/polygon_half.png";
import PolygonSmall from "../../../asset/IMG/Dashboard/Polygon 2.png";
import Star from "../../../asset/IMG/Dashboard/Star 1(1).png";
import AddressBook from "./DashboardSubContant/AddressBook";
import Footer from "../../Layout/Footer";
import WalletTop from "../../utils/WalletTop";

import polygondab from "../../../asset/IMG/Dashboard/Polygon-dashboard-logo.png";
import polygontab from "../../../asset/IMG/Dashboard/Polygon-tab-logo.png";
import halfPoly from "../../../asset/IMG/Polygon8.png";

import Feedback from "../Dashboard/DashboardSubContant/Feedback";
import ApiKeys from "./DashboardSubContant/ApiKeys";
import { useSelector } from "react-redux";

function Dashboard() {
  let keydata = useSelector((state) => state.reducer.userdashboardnavigate);
  console.log("key data", keydata);
  const [titleName, setTitleName] = useState("Dashboard");
  const [key, setKey] = useState("first");
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (keydata) {
      setKey(keydata);
      document.getElementById(`left-tabs-example-tab-${keydata}`).click();
    }
  }, [keydata, status]);

  return (
    <>
      <div className="walletSection dashboard-page position-relative overflow-hidden paddingY">
        <div className="wallet_top_container position-relative">
          {/* <div className="line-logo position-absolute">
            <img src={lineLogo} className="img-fluid" alt="line" />
          </div> */}

          {/* <div className="history halfPoly d-none d-lg-block position-absolute">
            <img
              src={halfPoly}
              alt="half polygon"
              className="img-fluid"
              style={{ opacity: "0.3" }}
            />
          </div> */}
          {/* <div className="star-logo position-absolute">
            <img src={Star} alt="" style={{ opacity: "0.3" }} />
          </div> */}

          <div className="container">
            <WalletTop name="Dashboard" />
          </div>
        </div>
        <div className="tabs-section">
          {console.log("keysdata", key)}
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={key}
            onSelect={(e) => {
              setKey(e);
            }}
          >
            {/* <div className="container"> */}

            <div className="tabs-header-section-dark-bg">
              <div className="container position-relative overflow-hidden dashboard-tabs-header">
                <Nav variant="pills" className="d-flex nav_tabs tab_scroll_x">
                  <Nav.Item className="z-2">
                    <Nav.Link
                      eventKey="first"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      DASHBOARD
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="z-2">
                    <Nav.Link
                      eventKey="second"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      AFFILIATE TOOLS
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="z-2">
                    <Nav.Link
                      eventKey="third"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      ACCOUNT SETTINGS
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="z-2">
                    <Nav.Link
                      eventKey="fourth"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      COIN ACCEPTANCE SETTINGS
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="z-2">
                    <Nav.Link
                      eventKey="fifth"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      ADDRESS BOOK
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="z-2">
                    <Nav.Link
                      eventKey="sixth"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      FEEDBOOK
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="z-2">
                    <Nav.Link
                      eventKey="seventh"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      API KEYS
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="z-2">
                    <Nav.Link
                      eventKey="eigth"
                      className="active_link_clrChange white_space_nowrap"
                    >
                      COIN REQUEST
                    </Nav.Link>
                  </Nav.Item> */}
                  {/* <div clas sName="small-polygon-logo position-absolute">
                    <img src={PolygonSmall} />
                  </div> */}
                </Nav>
                {/* <div className="tabs-polygon-tab-logo position-absolute">
                  <img src={polygontab} alt="" style={{ opacity: "0.3" }} />
                </div> */}
              </div>
            </div>

            <Row className="dashboard-tabs-content z-4 position-relative">
              <div className="tabContainerFluid position-relative">
                {/* <div className="polygon-logo position-absolute">
                  <img src={Polygon} />
                </div> */}
                {/* <div className="polygon-tab-logo position-absolute">
                  <img src={polygontab} alt="" style={{ opacity: "0.3" }} />
                </div> */}
                <div className="container tab-content">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Dashboards />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <AffiliateTools />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <AccountSettings />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <CoinAcceptance />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <AddressBook />
                    </Tab.Pane>
                    <Tab.Pane eventKey="sixth">
                      {/* <Feed/> */}
                      <Feedback />
                    </Tab.Pane>
                    <Tab.Pane eventKey="seventh">
                      <ApiKeys />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
                {/* <div className="half-polygon-logo position-absolute">
                  <img src={HalfPolygon} />
                </div> */}
              </div>
            </Row>
          </Tab.Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
