import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useraddnewlabeldatahooks } from "../../hooks/UseUser";

function AddnewAddress(props) {
  const [showAdd, setShowAdd] = useState(true);
  const [label, setLabel] = useState("");
  const [error, setError] = useState("");
  const [rec, setRec] = useState({});

  console.log("prsdfsopsdata", props);
  useEffect(() => {
    setRec(props?.label);
  }, [props]);

  const AddLabel = async () => {
    try {
      if (label != "" && label != undefined) {
        let reqdata = {
          assetId: rec?._id,
          label: label,
          rpc: rec?.rpc,
          network: rec?.network,
          networktype: rec?.networktype,
          accessToken: sessionStorage.getItem("accessToken"),
          filter: "walletlabelupdate",
        };
        let res = await useraddnewlabeldatahooks(reqdata);
        console.log("resdatasdfasfasdf", res?.data);
        if (res?.data?.success == true) {
          props.onDismiss();
          props?.onCheck();
        }
      } else {
        setError({ label: "Label is required" });
      }
    } catch (e) {
      console.log("AddLabel_err", e);
    }
  };

  return (
    <div>
      {/* Modal Popup */}
      <Modal
        show={showAdd}
        onHide={() => {
          props.onDismiss();
        }}
        centered
        className="get_demo_popup"
      >
        <Modal.Body>
          <button
            className="btn_close_cross"
            onClick={() => {
              props.onDismiss();
            }}
          >
            x
          </button>
          <div className="get_demo_modal_popup">
            <div className="d-flex flex-column">
              <p className="mb-2">Add New Deposit Address</p>
            </div>
            <div className="d-flex flex-column mt-4">
              <div className="otp_input mb-3">Enter Label</div>
              <input
                type="text"
                class="form-control"
                id="label"
                name="label"
                onChange={(e) => setLabel(e?.target?.value)}
              />
            </div>
            <span className="text-danger">{error?.label}</span>
          </div>
          <div className="mt-4 d-flex justify-content-center align-items-center mt-3">
            <Button
              onClick={() => {
                AddLabel();
              }}
              className="new_common_btn modal_submit_btn me-2"
            >
              Add
            </Button>

            <Button
              onClick={() => {
                props.onDismiss();
              }}
              className="new_common_btn modal_submit_btn modal_btn_cancelk"
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddnewAddress;
