import React,{useEffect, useState} from "react";
import icon from "../../../../asset/IMG/Dashboard/icon.png";
import "../../../../Styles/Feedback.css";
import detaillogo from "../../../../asset/IMG/Dashboard/Feedback/support-coins-detail-logo.png";
import cnfmlogo from "../../../../asset/IMG/Dashboard/Feedback/flat-color-icons_ok.png";
import shortline from "../../../../asset/IMG/Dashboard/Feedback/secondbox-lite-top.png";
import bigline from "../../../../asset/IMG/Dashboard/Feedback/left-top-icon.png";

import { AiFillStar } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";

import accountIcon from "../../../../asset/IMG/Dashboard/Feedback/account-img.png";
import countryIcon from "../../../../asset/IMG/Dashboard/Feedback/country-icon.png";
import HeadIcon from "../../../../asset/IMG/Dashboard/Feedback/headicon.png";
import leftBottomPattern from "../../../../asset/IMG/Dashboard/Feedback/left-icon.png";
import memberIcon from "../../../../asset/IMG/Dashboard/Feedback/member-cion.png";
import verify from "../../../../asset/IMG/Dashboard/Feedback/verify.png";
import AddLogo from "../../../../asset/IMG/Dashboard/Feedback/add-icon.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";

import { DateTimeForm, FeedBackValidation, countryList } from "../../../../hooks/UseValidation";
import { addfeedbackhooks, getallfeedbackdatahooks, getuserdatabytoken } from "../../../../hooks/UseUser";
const Feedback = () => {

  const [rating, setRating] = useState(0);
  const stars = [1, 2, 3, 4, 5];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [country, setcountry] = useState("")
  const [bs, setBs] = useState("");
  const [comments, setComments] = useState("");
  const [errors, setErrors] = useState({});
  const [userToken, setUserToken] = useState();
  const [email, setEmail] = useState("");
  const [feedback, setFeedBack] = useState([])

console.log('feedback_feedback', feedback)

useEffect(() => {
  let UserToken = sessionStorage.getItem("accessToken");
  setUserToken(UserToken)
  if((UserToken != '' && UserToken != null && UserToken != undefined && UserToken != "undefined")) {
    getData(UserToken)
  }

}, [userToken]);

  useEffect(() => {
    async function fetchData() {
      let resp = await getallfeedbackdatahooks();
      console.log("getallfeedbackdatahooks", resp);
      if (resp?.data?.status == true) {
        setFeedBack(resp?.data?.data)
      }
    }
    fetchData()
  }, []);

const getData = async (token) => {
  try {
    let reqdata = { token: token, filter : "userprofiledata" }
    let res = await getuserdatabytoken(reqdata);
    if (res?.data?.success == true) {
    console.log("res?.data?.success", res?.data)
    setEmail(res?.data?.record?.email);
    }
  } catch (e) {
    console.log("getData_err", e)
  }
}



  const handleSave = async () => {
    try {
      let validation = {
        "comments": comments,
        "country": country,
        "ratings": rating,
        "email": email

      };
      const { errors, isValid } = await FeedBackValidation(validation);
      setErrors(errors)
      if (Object.values(errors)?.length == 0 && isValid === true) {
        let reqdata = {
          email: email,
          ratings: rating,
          comments: comments,
          country: country
        }
        const res = await addfeedbackhooks(reqdata);
        console.log('res_addfeedbackhooks', res);
        if (res?.data?.success == "success") {
          console.log("handleSave_reserr")
        } else if (res?.data?.status == true) {
          handleClose()
        }
      }
      console.log('handleSaveerrrrrrrr', errors)
    } catch (e) {
      console.log("handleSave_err", e)
    }
  }



  const month = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUGUST", "SEP", "OCT", "NOV", "DEC"];

  const d = new Date();
  let name = month[d.getMonth()];


console.log("bs, comments", bs, comments, rating, country)
  const Feedback = [
    {
      date: 23,
      month: "OCT",
      time: "12 : 30 PM",
      timeLogo: detaillogo,
      status: "SENT",
      statusLogo: cnfmlogo,
      bsText:'LOREUM IPSUM',
      comment: ' Lorem Ipsum is simply dummy text of the printing and typesetting.',
      countryIcon: countryIcon,
      countryname: "GERMAN",
      shortline:shortline,
      bigline:bigline
    },
  ];

  const FeedStar = (startings) => {
    console.log('feedstartings', startings);
    let data = []
    for (let i = 0; i < 5; i++) {
      if ((i + 1) <= startings) {
        data.push(
          <>
            <span className="rating-icon-bg">
              <AiFillStar
                size={13}
                className="rating-icon-dark"
              />
            </span>
          </>
        )
      } else {
        data.push(
          <>
            <span className="rating-icon-bg">
              <AiFillStar
                size={13}
                className="rating-icon-lite"
              />
            </span>
          </>
        )
      }
    }

return data;
  }

  return (
    <div className="feedback">
     
      <div className="d-flex align-items-center justify-content-between head-section mb-4">
          <div className="d-flex align-items-center mb-4">
            <div className="img-section">
              <img src={HeadIcon} alt="icon" />
            </div>
            <h4 className="m-0 ps-3">FEEDBACK</h4>
          </div>
          <button className="commonBtn feedback_add" onClick={handleShow}>
            Add
          </button>
        </div>


      <div>
        {feedback && feedback?.map((item) => {
          return (
            <>
              <div className="feedback-table overflow-hidden mb-4">
                <div class="table-responsive">
                  <table
                    class="table m-0 p-0 position-relative table-line table-left-line fw-bolder"
                    // style={{ height: "150px" }}
                  >
                    {/* <div className="position-absolute">
                      <img src={item.shortline} />
                    </div> */}

                    <tr className="z-2 position-relative">
                      <td
                        rowspan="3"
                        className="bdr-right text-white"
                        style={{ minWidth: "230px" }}
                      >
                        <div className="fst-sec-border d-flex justify-content-center">
                          <div className="recent-date-section d-flex align-items-center justify-content-center bitcoin-bg">
                            <div className="first-sec position-relative">
                              <div className="dot-icon"></div>
                              <h5 className="date px-3 pt-2">{(DateTimeForm(item?.createdAt, true, false, false)).split('/')[0]}</h5>
                              <p className="py-0 px-1 month">{month[new Date(item?.createdAt)?.getMonth()]}</p>
                            </div>

                            <div>
                              <div className="d-flex justify-content-center align-items-center px-3 py-2">
                                <img
                                  src={detaillogo}
                                  className="time-img-logo"
                                />
                                <p className="ps-1 pb-1 time">{DateTimeForm(new Date(item?.createdAt), false, true, true)}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="head-title bdr-right border-bottom-border">
                        RATING
                      </td>
                      {/* <td className="head-title bdr-right border-bottom-border">
                        B/S
                      </td> */}
                    
                      <td className="head-title bdr-right border-bottom-border">
                        COMMENT
                      </td>
                      <td className="head-title border-bottom-border">
                        COUNTRY
                      </td>
                    </tr>
                    <tr className="z-2 position-relative">
                      <td className="bdr-right">
                        <div className="d-flex justify-content-center align-items-center">
                          <>{
                            FeedStar(item?.ratings)
                          }
                          </>
                          
                        </div>
                      </td>
                      {/* <td className="bdr-right">
                        <span className="bs-text">{item.bsText}</span>
                      </td> */}
                     
                      <td className="bdr-right">
                        <div className="text-start comment-section ps-3">
                          <p className="comment-text">
                            {item.comments}
                          </p>
                          {/* <p className="read-more-btn">Read more</p> */}
                        </div>
                      </td>
                      <td
                        className="position-relative"
                        style={{ minWidth: "200px" }}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <img src={item.countryIcon} />
                          <span className="ms-2 value country-name">
                            {item.country}
                          </span>
                        </div>
                      </td>
                    </tr>
                    {/* <div className="botton-right-logo position-absolute overflow-hidden z-1">
                      <img src={item.bigline} />
                    </div> */}
                  </table>
                </div>
              </div>
            </>
          );
        })}
      </div>

      {/* <div className="d-flex justify-content-end">
        <div className="save-btn p-0" style={{ width: "120px" }}>
          <p
            className="z-2 d-flex justify-content-center align-items-center address-click-btn"
            style={{
              cursor: "pointer",
              textTransform: "none",
              // fontSize: "14px",
              fontWeight: "500",
            }}
          >
            <img src={AddLogo} alt="" /> SAVE ADDRESS
          </p>
        </div>
      </div> */}
       <Modal
        show={show}
        onHide={handleClose}
        centered
        className="feedback_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>FeedBack</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              {/* <Form.Group className="mb-3">
                <Form.Label>B/S</Form.Label>
                <Form.Control type="text" placeholder="" onChange={(e) => setBs(e?.target?.value)}/>
                <p className="text-danger">{errors?.feedback}</p>
              </Form.Group> */}

              <Form.Group className="mb-3">
                <Form.Label>COMMENT</Form.Label>
                <Form.Control as="textarea" rows={3} onChange={(e) => setComments(e?.target?.value)}/>
                <p className="text-danger">{errors?.comments}</p>
              </Form.Group>
              <Dropdown className=""  value={country} onSelect={(e) => { setcountry(e) }} >
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="custom-drop-btn whit_drp_dwn  w-100 d-flex justify-content-between align-items-center"
                        >
                          {country ? country : "Select Country" }
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100 countrydropstyle" value={country} >
                          {countryList && countryList?.map((val, i) => {
                            return (<><Dropdown.Item eventKey={val?.country}>{val?.country}</Dropdown.Item></>)
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                      <p className="text-danger">{errors?.country}</p>
              <Form.Group className="my-3">
                <Form.Label>RATING</Form.Label>
                <div className="fb_rating">
                  {stars.map((star) => {
                    return (
                      <>
                        <div key={star} onClick={() => setRating(star)}>
                          {rating >= star ? (
                            
                            <FaStar className="filled_rating" />
                          ) : (
                            <FaRegStar className="unfilled_rating" />
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
                <p className="text-danger">{errors?.ratings}</p>
              </Form.Group>

            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="commonBtn feedback_save_btn" onClick={() => {handleSave()}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Feedback;
