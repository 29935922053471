import React, { useEffect, useState } from "react";
import "../../../../../Styles/ApiKeys.css";
// import searchicon from "../../../../asset/IMG/Fees/icon-park-outline_search.png";
import { Nav, Navbar, Row } from "react-bootstrap";
// import Tab from "react-bootstrap/Tab";
import icon from "../../../../../asset/IMG/Dashboard/Feedback/headicon.png";
// import { FaFilter } from "react-icons/fa";
import lineLogo from "../../../../../asset/IMG/Dashboard/line_logo.png";
// import Polygon from "../../../../../asset/IMG/Dashboard/Polygon 1(1).png";
// import PolygonSmall from "../../../../asset/IMG/Dashboard/Polygon 2.png";
import Star from "../../../../../asset/IMG/Dashboard/Star 1(1).png";
import arrow from "../../../../../asset/IMG/Dashboard/maki_arrow.png";
import AddLogo from "../../../../../asset/IMG/Dashboard/add-icon.png";
import keyPermission from "../../../../../asset/IMG/Dashboard/key-permission.png";
import Dropdown from "react-bootstrap/Dropdown";
// import vectorimg from "../../../../asset/IMG/Dashboard/Vector.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../../Layout/Footer";
import halfPoly from "../../../../../asset/IMG/Dashboard/half-poly.png";
import fullPolySmall from "../../../../../asset/IMG/Dashboard/Polygon-tab-logo.png";
import formPolyIcon from "../../../../../asset/IMG/Dashboard/key-permission-poly.png";
import { getuserapikeyhooks, updateuserapikeyhooks } from "../../../../../hooks/UseUser";
import { useDispatch, useSelector } from "react-redux";
import { SET_DASHBOARD_NAVIGATE, SET_USERAPI_ACCESS } from "../../../../../redux/action";
import { isEmpty } from "../../../../../Helper/isEmpty";


function EditApi() {

  const InitialState = {
    keyname: "", ip: "", withdrawallimits: "BTC", limitperday: "0.00000000", limitpertransaction: "0.00000000",
    keyenabled: false
  }

  const InitialState1 =
  {
    "get_basic_info": false, "create_withdrawal": false, "set_basic_info": false, "Allow_auto_confirm_in_create_withdrawal": false,
    "create_transaction": false, "create_mass_withdrawal": false, "get_tx_info": false, "get_withdrawal_info": false, "get_callback_address": false,
    "cancel_withdrawal": false, "rates": false, "convert": false, "balances": false, "convert_limits": false, "get_deposit_address": false,
    "get_conversion_info": false, "create_transfer": false, "get_pbn_info": false, "Allow_auto_confirm_in_create_transfer": false, "manage_pbn": false,
  }

  const location = useLocation();
  const navigate = useNavigate();
  const [apidetails, setApiDetails] = useState(InitialState);
  const [accessdata, setAccessData] = useState(InitialState1);
  console.log("acasdfasdfccccc", accessdata)
  const [pathName, setPathName] = useState("");

  let userapiaccessdata = useSelector((state) => state.reducer.userapiaccessdata);
  let dispatch = useDispatch();

 

  useEffect(() => {
    async function fetchData() {
      let pathName = location?.pathname?.split('/')[2];
      setPathName(pathName)
      let reqData = { _id: pathName }
      let res = await getuserapikeyhooks(reqData);
      console.log("reswsdfasdf", res?.data)
      if (res?.data?.success == true) {
        let rec = res?.data?.record;
        let accessdatas = rec?.Access[0];
        let obj = {
          keyname: rec?.KeyName,
          ip: rec?.IP,
          withdrawallimits: rec?.WithdrawLimit,
          limitpertransaction: rec?.LimitPerTransaction,
          limitperday: rec?.LimitPerDay,
          keyenabled: rec?.Status
        }

        let obj1 = {
          "get_basic_info": accessdatas?.GetBasicInfo, "create_withdrawal": accessdatas?.CreateWithdrawal, "set_basic_info": accessdatas?.SetBasicInfo, "Allow_auto_confirm_in_create_withdrawal": accessdatas?.AllowConfirmCreateTransfer,
          "create_transaction": accessdatas?.CreateTransaction, "create_mass_withdrawal": accessdatas?.CreateMassWithdrawal, "get_tx_info": accessdatas?.GetTxInfo, "get_withdrawal_info": accessdatas?.GetWithdrawInfo, "get_callback_address": accessdatas?.GetCallbackAddress,
          "cancel_withdrawal": accessdatas?.CancelWithdrawal, "rates": accessdatas?.Rates, "convert": accessdatas?.Convert, "balances": accessdatas?.Balances, "convert_limits": accessdatas?.ConvertLimits, "get_deposit_address": accessdatas?.GetDepositAddress,
          "get_conversion_info": accessdatas?.GetConversionInfo, "create_transfer": accessdatas?.CreateTransfer, "get_pbn_info": accessdatas?.GetPbnInfo, "Allow_auto_confirm_in_create_transfer": accessdatas?.AllowConfirmCreateTransfer, "manage_pbn": accessdatas?.ManagePbn,

        }
        // console.log("obj1_data", obj1, userapiaccessdata,(isEmpty((userapiaccessdata) && (userapiaccessdata &&Object.values(userapiaccessdata)?.length > 0) &&  (userapiaccessdata?._id == pathName))))
        console.log("obj1_data", userapiaccessdata,Object.values(userapiaccessdata)?.length > 0, userapiaccessdata?._id == pathName)
        if(!(Object.values(userapiaccessdata)?.length > 0 && userapiaccessdata?._id == pathName)) {
          setAccessData({ ...accessdata, ...obj1 })
        }
        setApiDetails({ ...apidetails, ...obj })
        
      }
      console.log("userapiaccessdata", userapiaccessdata)
      if (Object.values(userapiaccessdata)?.length > 0 && (userapiaccessdata?._id == pathName)) {
        setAccessData(userapiaccessdata)
      }

    }
    fetchData()
  }, [location, userapiaccessdata])


  console.log("apidetails", apidetails)
  let options = [
    'BTC', 'USDT', 'INR'
  ]


  const { keyname, ip, limitperday, limitpertransaction, withdrawallimits, keyenabled } = apidetails
  let options1 = ['0.00000000', '0.00000001', '0.00000002']
  const handleBack = () => {
   navigate("/dashboard");
   dispatch({ type: SET_DASHBOARD_NAVIGATE, payload: "seventh"});
    dispatch({ type: SET_USERAPI_ACCESS, payload: {} });

  };

  const handleKeyPermissions = () => {
    navigate(`/api-key-permissions/${pathName}`, {state : accessdata});
  };


  const handleChange = (e) => {
    const { name, value, checked } = e?.target;
    console.log("checked", checked)
    if (name == "keyenabled") {
      setApiDetails({ ...apidetails, ...{ [name]: checked } })
    } else {
      setApiDetails({ ...apidetails, ...{ [name]: value } })
    }
  }
  console.log('111 accessdtatatat', accessdata, keyenabled);


  const saveAddress = async () => {
    try {
      let reqdata = {
        keyname: keyname,
        withdrawallimits: withdrawallimits,
        limitperday: limitperday,
        limitpertransaction: limitpertransaction,
        keyenabled: keyenabled,
        accessdata: accessdata,
        ip: ip,
        _id: pathName
      }
      console.log("reqdata_reqdata", reqdata)
      let res = await updateuserapikeyhooks(reqdata);
      console.log("ressss", res?.data)
      if (res?.data?.success == true) {
        navigate("/dashboard");
        dispatch({ type: SET_USERAPI_ACCESS, payload: {} });
        dispatch({ type: SET_DASHBOARD_NAVIGATE, payload: "seventh" });

      }
    } catch (e) {
      console.log("saveAddress_err", e)
    }
  }
  return (
    <>
      <div className="edit-api-page position-relative overflow-hidden">
        {/* <div className="line-logo position-absolute">
          <img src={lineLogo} />
        </div> */}
        {/* <div className="star-logo position-absolute">
          <img src={Star} />
        </div> */}
        {/* <div className="hal-polygon-img-sec">
          <img src={halfPoly} alt="polygon" />
        </div> */}
        {/* <div className="full-polygon-img-sec">
          <img src={fullPolySmall} alt="polygon" />
        </div> */}
        <div className="header-section py-5 overflow-hidden">
          <div className="position-relative">
            <div className="position-relative container d-flex flex-column align-items-start dashboard-section">
              <div>
                <h2>EDIT API</h2>
                <h4>
                  TRADE, SELL AND HOLD 10+ CRYPTOCURRENCIES ON <br></br>
                  <span className="youpay">COINPAYMATE</span>
                </h4>
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                onClick={() => { handleBack() }}
                style={{ cursor: "pointer" }}
              >
                <img src={arrow} className="left-arrow-custom" />{" "}
                <span className="back ms-2">BACK</span>
              </div>
            </div>
            {/* <div className="full-polygon-img-sec">
              <img src={fullPolySmall} alt="polygon" />
            </div> */}
          </div>
        </div>

        <div className="form-section z-2 position-relative">
          <div className="edit-form-section container py-3">
            <div className="recent-sections">
              <div className="position-relative mb-2 mb-xl-5 mb-lg-5 mb-md-4 mb-sm-3 d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="img-section">
                    <img src={icon} />
                  </div>
                  <h4 className="m-0 ps-3">Edit</h4>
                </div>
                <div className="d-flex key-enabled">
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="key-enabled">KEY ENABLED:</span>
                    <span className="ms-3">
                      <input
                        class="form-check-input custom-input custom-checkbox"
                        type="checkbox"
                        id="check1"
                        name="keyenabled"
                        checked={keyenabled}
                        onChange={(e) => handleChange(e)}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="basic-settings-content mx-0">
              <div className="row public-info">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-xl-5 mb-lg-5 mb-md-5 mb-sm-4 mb-3">
                  <div class="row">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      KEY NAME <span>(OPTIONAL):</span>
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="keyname" value={keyname} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-xl-5 mb-lg-5 mb-md-5 mb-sm-4 mb-3">
                  <div class="row">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      RESTRICT TO IP/IP RANGE:
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="ip" value={ip} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-xl-5 mb-lg-5 mb-md-5 mb-sm-4 mb-3">
                  <div class="row">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      WITHDRAWAL LIMITS:
                    </label>
                    {console.log("withdrawallimits", withdrawallimits)}
                    <div class="col-lg-8">
                      <Dropdown value={withdrawallimits} onSelect={(e) => { setApiDetails({ ...apidetails, ...{ "withdrawallimits": e } }) }}>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="custom-drop-btn w-100 d-flex justify-content-between align-items-center"
                        >
                          <span className="drop-down-inside-text" >{withdrawallimits}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100">
                          {
                            options && options.map((val) => {
                              return (
                                <Dropdown.Item eventKey={val}>{val}</Dropdown.Item>
                              )
                            }
                            )
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-xl-5 mb-lg-5 mb-md-5 mb-sm-4 mb-3">
                  <div class="row">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      LIMIT PER TRANSACTION:
                    </label>
                    <div class="col-lg-8">
                      <Dropdown value={limitpertransaction} onSelect={(e) => { setApiDetails({ ...apidetails, ...{ "limitpertransaction": e } }) }}>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="custom-drop-btn w-100 d-flex justify-content-between align-items-center"
                        >
                          <span className="drop-down-inside-text">{limitpertransaction}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100">
                          {
                            options1 && options1.map((val) => {
                              return (
                                <Dropdown.Item eventKey={val}>{val}</Dropdown.Item>
                              )
                            }
                            )
                          }
                          {/* <Dropdown.Item href="#/action-1">
                            Action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-xl-5 mb-lg-5 mb-md-5 mb-sm-4 mb-3">
                  <div class="row">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      LIMIT PER DAY:
                    </label>
                    <div class="col-lg-8">
                      <Dropdown value={limitperday} onSelect={(e) => { setApiDetails({ ...apidetails, ...{ "limitperday": e } }) }}>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="custom-drop-btn w-100 d-flex justify-content-between align-items-center"
                        >
                          <span className="drop-down-inside-text">{limitperday}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100">
                          {
                            options1 && options1.map((val) => {
                              return (
                                <Dropdown.Item eventKey={val}>{val}</Dropdown.Item>
                              )
                            }
                            )
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-xl-5 mb-lg-5 mb-md-5 mb-sm-4 mb-3">
                  <div className="d-flex justify-content-end align-items-end">
                    {/* <Link to="api-key-permissions" className=""> */}
                    <div
                      className="d-flex align-items-center justify-content-end ms-3"
                      style={{ gap: "5px" }}

                    >
                      <button
                        className="key-permission commonBtn p-0"
                        onClick={() => { handleKeyPermissions() }}
                      // style={{ width: "131px" }}
                      >
                        <img
                          src={keyPermission}
                          alt="key-permission"
                          className="key-per-img me-1"
                        />{" "}
                        <span className="key-per-text">
                          KEY PERMISSION
                        </span>
                      </button>
                    </div>
                    {/* </Link> */}
                    <div
                      className="d-flex align-items-center justify-content-end account-settings ms-3"
                      style={{ gap: "5px" }}
                    >
                      <button
                        className="save-address commonBtn p-0"
                        // style={{ width: "131px" }}
                        onClick={() => saveAddress()}
                      >
                        <img src={AddLogo} alt="save-address" className="common-inside-btn-img me-1" /> UPDATE APIKEY
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*        */}
          {/* <div
            className="position-absolute"
            style={{ bottom: "-263px", left: "0px" }}
          >
            <img src={formPolyIcon} alt="polygon" />
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditApi;
