import React, { useEffect, useState } from "react";
import "../../Styles/Login.css";
import { Form } from "react-bootstrap";
import { userValidation } from "../../hooks/UseValidation";
import { updateaccounttypehooks, userresetpasswordhooks, validateloginhooks } from "../../hooks/UseUser";
import { style, position, iconTheme } from "../../hooks/UseToast";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_LOGIN_DETAILS } from "../../redux/action";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { BiHide, BiShow } from "react-icons/bi";
import { sleep } from "../../hooks/UseUser";
import { TENMINUTESINMILISECONDS } from "../../config/env";
function ResetPassword({
    handleResetClose,
    Otp
}) {

  let dispatch = useDispatch();

  const [showIcon, setShowIcon] = useState(false);
  const [showIcon1, setShowIcon1] = useState(false);
  const [errors, setErrors] = useState({});
  const [resetdetails, setResetDetails] = useState({
    user_name: "",
    password: "",
    confirmpassword : "",
    filter : "resetpassword"
  });

  console.log('Otp_Otp', Otp)

  const { user_name, password, confirmpassword } = resetdetails;

    const handleChange = (e) => {
        const { value, id } = e.target;
        setResetDetails({ ...resetdetails, ...{ [id]: value } });
    };

    const resetPassword = async () => {
        try {
            let reqData = {
                user_name: user_name,
                password: password,
                Otp : Otp,
                filter : "resetpassword"
            }

            const { errors, isValid } = await userValidation(resetdetails);
            setErrors(errors)
            if (Object.keys(errors).length == 0 && isValid === true) {
                let res = await userresetpasswordhooks(reqData);
                console.log("ressssssss", res)
                if (res?.data?.success == "success") {
                    setErrors(res?.data?.errors)
                  } else if (res?.data?.success == true) {
                    handleResetClose()
                  }
            }

        } catch (e) {
            console.log('ResetPassword_err', e)
        }

    };


  return (
    <div className="login-page">
      <div className="container second_div">
        <div className="row p-0 justify-content-center">
          {/* <div className="col-lg-6 col-md-6 p-0 box-one">
            <div className="signin-account-section my-3 p-4">
              <h2>
                <span className="signin">SIGNIN</span> ACCOUNT
              </h2>
              <p>
                LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE{" "}
                <span className="printing">PRINTING</span>
              </p>
            </div>

            <div className="forgot-section pe-4 pb-4 ps-4">
              <p className="my-3 create-account" >
                DO NOT HAVE AN ACCOUNT ?
              </p>
              <p
                className="my-3 forgot-password"
                // onClick={handleForgotPassword}
              >
                FORGOT PASSWORD ?
              </p>
              <span>
                Lorem ipsum is simply dummy text of the printing and typesetting
                industry, Lorem ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled.
              </span>
            </div>
          </div> */}

          <div className="col-lg-8 col-md-8 p-4 box-two">
            {
            
                <>
                <div className="rest_pwd_title">
                  <p>Reset Password</p>
                </div>
                  <div className="row mb-3 mt-5">
                    <label for="inputusername3" className="col-sm-4 col-form-label">
                      USERNAME 
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="user_name"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    <span className="text-danger">{errors?.user_name} </span>

                    </div>
                  </div>

                  <div className="row mb-3 mt-4 mt-md-5">
                    <label for="inputPassword3" className="col-sm-4 col-form-label">
                      PASSWORD
                    </label>
                    <div className="col-sm-8">
                      <div className="password_show_hide">
                        <input
                          type={showIcon ? "text" : "password"}
                          className="form-control custom-input"
                          id="password"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {/* {console.log("show", showIcon)} */}
                        <div className="icon_sec">
                          {showIcon ? (
                            <BiShow onClick={() => setShowIcon(!showIcon)} />
                          ) : (
                            <BiHide onClick={() => setShowIcon(!showIcon)} />
                          )}
                        </div>
                      </div>
                    <span className="text-danger">{errors?.password} </span>

                    </div>
                  </div>

                  <div className="row mb-3 mt-4 mt-md-5">
                    <label for="inputPassword3" className="col-sm-4 col-form-label">
                      CONFIRM PASSWORD
                    </label>
                    <div className="col-sm-8">
                      <div className="password_show_hide">
                        <input
                          type={showIcon1 ? "text" : "password"}
                          className="form-control custom-input"
                          id="confirmpassword"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {/* {console.log("show", showIcon)} */}
                        <div className="icon_sec">
                          {showIcon1 ? (
                            // <BiSolidShow onClick={() => setShowIcon(!showIcon)} />
                            <BiShow onClick={() => setShowIcon1(!showIcon1)} />
                          ) : (
                            // <BiSolidHide onClick={() => setShowIcon(!showIcon)} />
                            <BiHide onClick={() => setShowIcon1(!showIcon1)} />
                          )}
                        </div>
                      </div>
                      <span className="text-danger">{errors?.confirmpassword} </span>
                    </div>
                   
                  </div>


                  <div className="login-submit-section d-flex justify-content-end align-items-center">
                    {/* <div className="signin-commonBtn commonBtn">
                      <button
                        className="z-2"
                        style={{
                          cursor: "pointer",
                          textTransform: "none",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        ResetPassword
                      </button>
                    </div> */}
                    <div className="signin-commonBtn commonBtn" >
                      <button
                        className="z-2"
                        style={{
                          cursor: "pointer",
                          textTransform: "none",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        onClick={() => { resetPassword() }}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </>
                
            }



          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
