import React, { useEffect, useState } from "react";
import { depositTable } from "../../Data/data.js";
import tableTop from "../../asset/IMG/table-top.png";
import tableBot from "../../asset/IMG/table-bot.png";
import dollar from "../../asset/IMG/dollar.png";
import clock from "../../asset/IMG/clock.png";
import address from "../../asset/IMG/address.png";
import success from "../../asset/IMG/success.png";
import pending from "../../asset/IMG/pending.png";
import cancel from "../../asset/IMG/cancel.png";
import eth from "../../asset/IMG/ETH.png";
import btc from "../../asset/IMG/BTC.png";
import { getusertransdetailshooks } from "../../hooks/UseUser.js";
import { DateTimeForm } from "../../hooks/UseValidation.js";

const DepositHistory = (props) => {

  const [records, setRecords] = useState([]);

  useEffect(() => {
    async function fetchData() {
      console.log('propsdatasgal', props)
      if(props?.Id) {
        let reqData = { type: "Deposit", accessToken: sessionStorage.getItem("accessToken"), symbol : props?.symbol, filter : "single"};
        let res = await getusertransdetailshooks(reqData);
        console.log("depositreco", res?.data)
        if (res?.data?.success == true) {
          setRecords(res?.data?.record)
        }
      } else {
        let reqData = { type: "Deposit", accessToken: sessionStorage.getItem("accessToken"), symbol : "", filter : "all"}
        let res = await getusertransdetailshooks(reqData);
        console.log("depositreco", res?.data)
        if (res?.data?.success == true) {
          setRecords(res?.data?.record)
        }
      }
      
    }
    fetchData()
  }, [props]);


  const month = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUGUST", "SEP", "OCT", "NOV", "DEC"];

  const d = new Date();
  let name = month[d.getMonth()];


  return (
    <div className="tableContainer">
      {records && records?.length > 0 ? records?.map((item) => (
        <div key={item.id}>
          <div className="table-responsive">
            <table class="table table-bordered table_wallet">
              <tbody>
                <tr className="headerRow">
                  <td rowSpan={2} className="firstTd">
                    {/* <div className="position-absolute table_toprec">
                      <img
                        src={tableTop}
                        alt="rectangle"
                        className="img-fluid"
                      />
                    </div> */}
                    <div className="coin-type d-flex align-items-center justify-content-center">
                      <div className="d-flex align-items-center coin-div">
                        <div className="time-div">
                          <div className="date">
                            <div className="red-dot"></div>
                            <p>{(DateTimeForm(item?.createdAt, true, false, false)).split('/')[0]}</p>
                          </div>
                          <div className="month">
                            <p>{month[new Date(item?.createdAt)?.getMonth()]}</p>
                          </div>
                        </div>
                        <div className="text-div">
                          <div className="clock">
                            <img
                              src={clock}
                              alt="clock"
                              className="img-fluid"
                            />
                          </div>
                          <div className="time">
                            <p>{DateTimeForm(new Date(item?.createdAt), false, true, true)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="tableTitle">Symbol</td>
                  <td className="tableTitle">Transaction address/ID</td>
                 
                  <td className="tableTitle">Amount</td>
                  <td className="tableTitle">Confirm Status</td>
                  {/* <td className="tableTitle">Convert to</td>
                  <td className="tableTitle">Receive it in other wallets</td> */}
                </tr>
                <tr className="contentRow">
                <td>
                    <div className="tableContent dif_clr2">
                      <p className="lite_yellow_clr">
                        <span> {item.currencySymbol}</span>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="tableContent">
                      <img src={address} alt="address" className="img-fluid" />
                      {/* `${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}` */}
                      <p className="lite_yellow_clr">{`${item.transactionId.substring(0, 5)}...${item.transactionId.substring(38, 42)}`}</p>
                    </div>
                  </td>
                  <td>
                    <div className="tableContent dif_clr2">
                      <p className="lite_yellow_clr">
                        $<span> {item.amount}</span>
                      </p>
                    </div>
                  </td>
                 
                  <td>
                    <div className="tableContent">
                      <div>
                        <img
                          src={item?.status == "Completed" ? success : pending}
                          alt="success"
                          className="img-fluid"
                        />
                      </div>
                      <p className="statusColor">{item.status}</p>
                    </div>
                  </td>
                  {/* <td>
                    <div className="tableContent dif_clr">
                      <div className="table-btc">
                        <img src={eth} alt="eth" className="img-fluid" />
                      </div>
                      <p>{item.converTo}</p>
                    </div>
                  </td>
                  <td>
                    <div className="tableContent dif_clr">
                      <div className="table-btc">
                        <img src={btc} alt="btc" className="img-fluid" />
                      </div>
                      <p>{item.otherWallets}</p>
                    </div>
                  </td> */}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )) : <> <div className="empty_rec">
        <p>No Records</p>
      </div>
      </>}
    </div>
  );
};

export default DepositHistory;
