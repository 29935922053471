import React, { useEffect, useState } from "react";
import "../../../../Styles/Dashboard.css";
import icon from "../../../../asset/IMG/Dashboard/icon.png";
import { FaFilter } from "react-icons/fa";
import searchicon from "../../../../asset/IMG/Dashboard/icon-park-outline_search.png";
import bitcoin from "../../../../asset/IMG/Dashboard/cryptocurrency-color_btc.png";
import detaillogo from "../../../../asset/IMG/Dashboard/support-coins-detail-logo.png";

import transactionlogo from "../../../../asset/IMG/Dashboard/Group 61.png";
import receivedlogo from "../../../../asset/IMG/Dashboard/cryptocurrency-color_eth.png";
import cnfmlogo from "../../../../asset/IMG/Dashboard/flat-color-icons_ok.png";
import convertlogo from "../../../../asset/IMG/Dashboard/Group.png";

import binance from "../../../../asset/IMG/Dashboard/binance.png";
import tether from "../../../../asset/IMG/Dashboard/tether.png";
import shortline from "../../../../asset/IMG/Dashboard/secondbox-lite-top.png";
import bigline from "../../../../asset/IMG/Dashboard/left-top-icon.png";
import Dropdown from "react-bootstrap/Dropdown";
import AddLogo from "../../../../asset/IMG/Dashboard/add-icon.png";
import DeleteLogo from "../../../../asset/IMG/Dashboard/fluent_delete-12-filled.png";
import { Link } from "react-router-dom";
import { deleteuseraddressdatahooks, getuserdatabytoken } from "../../../../hooks/UseUser";
function AddressBook() {

  const [userToken, setUserToken] = useState();
  const [useraddressbook, setUserAddressBook] = useState([]);
  console.log("useraddressbook", useraddressbook)
  useEffect(() => {
    let UserToken = sessionStorage.getItem("accessToken");
    setUserToken(UserToken)
    if((UserToken != '' && UserToken != null && UserToken != undefined && UserToken != "undefined")) {
      getData(UserToken)
    }

  }, [userToken]);


  const getData = async (token) => {
    try {
      let reqdata = { token: token, filter : "useraddressdata" }
      let res = await getuserdatabytoken(reqdata);
      if (res?.data?.success == true) {
        let data = res?.data?.record;
        setUserAddressBook(data?.addressbook)
      }
    } catch (e) {
      console.log("getData_err", e)
    }
  }


  const DeleteAddress = async (index) => {
    try {
      let reqData = { token: userToken, index: index }
      let res = await deleteuseraddressdatahooks(reqData);
      if (res?.data?.success == true) {
        getData(userToken)
      }
    } catch (e) {
      console.log("DeleteAddress_err", e)
    }
  }

  // const SupportCoins = [
  //   {
  //     count: 1,
  //     text: "ADDRESS",
  //     content:
  //       "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
  //     shortline: shortline,
  //     bigline: bigline,
  //     default: "Default",
  //     delete: DeleteLogo,
  //   },
  //   {
  //     count: 2,
  //     text: "COINS SELECT",
  //     content:
  //       "but also the leap into electronic typesetting, remaining essentially unchanged",
  //     shortline: shortline,
  //     bigline: bigline,
  //     // default:'DEFAULT',
  //     delete: DeleteLogo,
  //   },
  //   {
  //     count: 3,
  //     text: "ADDRESS",
  //     content:
  //       "centuries, but also the leap into electronic typesetting. remaining essentially unchanged",
  //     shortline: shortline,
  //     bigline: bigline,
  //     // default:'DEFAULT',
  //     delete: DeleteLogo,
  //   },
  //   {
  //     count: 4,
  //     text: "FIAT",
  //     content:
  //       "the leap into electronic typesetting, remaining essentially unchanged",
  //     shortline: shortline,
  //     bigline: bigline,
  //     // default:'DEFAULT',
  //     delete: DeleteLogo,
  //   },
  // ];
   const supportCoins=[{
    default:'yes',
   },
   {
    default:'no',
   },
   {
    default:'no',
   }, {
    default:'no',
   }]
  return (
    <div className="address-book">
      <div className="recent-sections">
        <div className="filter-sec position-relative my-5">
          <div className="d-flex align-items-center">
            <div className="img-section">
              <img src={icon} />
            </div>
            <h4 className="m-0 ps-3">ADDRESS</h4>
          </div>
          <div className="d-flex filter-box-search">
            <Link to="/add-address" className="text-decoration-none">
              <button className="commonBtn p-0" style={{ width: "120px" }}>
                <img src={AddLogo} alt="" className="add-address-icon me-2" />{" "}
                ADD ADDRESS
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="support-icons-coins">
        <div className="row px-3 px-sm-0 px-md-0 px-md-0 px-lg-0 px-xl-0 px-xl-0">
          {useraddressbook && useraddressbook?.length > 0 ? useraddressbook?.map((val, ind) => {
            return (
              <>
               <div className="col-md-6 mb-4">
                <div className="addr_card">
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-4">
                      <p className="addr_name"><span className="addr_no">{ind + 1} </span>Address</p>

                    </div>
                    <div className="col-12 col-sm-6 right_delete_sec mb-4">
                      <div className="delete_sec">
                      {/* onClick={() => DeleteAddress(ind)} */}
                      <p className="addr_name1" >Delete</p>
                     
                      <div className="delete_icn">
                      <img src={DeleteLogo} className="img-fluid" onClick={() => DeleteAddress(ind)}/>
                      </div>
                      
                      </div>
                      {val?.DefaultAddress == "true" &&
                      <p className="label_default">DEFAULT</p>
            }
                    </div>

                  </div>
                  <div className="addr_inercard">
                    <div className="row">
                      <div className="col-6">
                        <p className="label">First Name :</p>
                        <p className="value">{val?.FirstName}</p>
                      </div>
                      <div className="col-6">
                        <p className="label">Last Name :</p>
                        <p  className="value">{val?.LastName}</p>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="label">Address :</p>
                        <p className="value">{val?.Address}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="label">Address2 :</p>
                        <p className="value">{val?.Address2}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="label">Country :</p>
                        <p className="value">{val?.Country}</p>
                      </div>
                      <div className="col-6">
                        <p className="label">State :</p>
                        <p className="value">{val?.State}</p>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="label">City :</p>
                        <p className="value">{val?.City}</p>
                      </div>
                      <div className="col-6">
                        <p className="label">Zipcode :</p>
                        <p className="value">{val?.Zipcode}</p>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="label">Phone Number :</p>
                        <p className="value">{val?.MobileNo}</p>
                      </div>
                    </div>
                    
                    

                  </div>



                </div>
               </div>
              </>
            );
          })
          

          : <> <div className="empty_rec">
            <p> No Saved Addresses</p>
            </div></>
          }
        </div>
      </div>
    </div>
  );
}

export default AddressBook;
