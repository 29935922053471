import React from "react";
import ReCAPTCHA from "react-google-recaptcha";


const Captchaa = (props) => {

   console.log("sitekey", "6LeO2SIpAAAAAE9Vi4I_C4r0yVpHvPWKHRVcgQF4")

    return (
        <>
            <div className="captcha mt-3 mb-2">
                <ReCAPTCHA
                   
                    sitekey="6LeO2SIpAAAAAE9Vi4I_C4r0yVpHvPWKHRVcgQF4" //live
                
                    //  sitekey="6LeNYB4nAAAAALOginXnJkhbXt6tDh6NHgFCpRD3" //local
                      onChange={props.onchange}                
                />
            </div>

        </>
    )
}


export default Captchaa;