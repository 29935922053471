import React, { useEffect, useState } from "react";
import "../../Styles/Login.css";
import { Form } from "react-bootstrap";
import { userValidation } from "../../hooks/UseValidation";
import { Test, updateaccounttypehooks, validateloginhooks } from "../../hooks/UseUser";
import { style, position, iconTheme } from "../../hooks/UseToast";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_LOGIN_DETAILS } from "../../redux/action";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { BiHide, BiShow } from "react-icons/bi";
import { sleep } from "../../hooks/UseUser";
import { TENMINUTESINMILISECONDS } from "../../config/env";
import Accountmodal from "../Modals/accountmodal"
import axios from "axios";

function Login({
  handleForgotPassword,
  handleCreateAccount,
  handleTwoFactorShow,
  handleLoginClose
  
}) {

  let dispatch = useDispatch();
  const [accountmodal, setAccountmodal] = useState(false);

  const [showIcon, setShowIcon] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [steptwo, setSteptwo] = useState(false);
  const [acctype, setAcctype] = useState("Normal");
  const [ipadddress,setIP] = useState('');
  const [errors, setErrors] = useState({});
  const [userdetails, setUserDetails] = useState({
    user_name: "",
    password: "",
  });
 const [accessToken, setAccessToken] = useState("");
 const navigate = useNavigate();
let useraccoutdetail = useSelector((state) => state.reducer.useraccounttype);


const GetIpAddress = async()=>{
  const res = await axios.get('https://geolocation-db.com/json/')
  setIP(res?.data?.IPv4)
}


useEffect(() => {
GetIpAddress()
}, []);


  useEffect(() => {
    if(useraccoutdetail?.accounttype) {
      setSteptwo(true);
      setAccessToken(useraccoutdetail?.accessToken)
    }
  }, [useraccoutdetail])
  const handleChange = (e) => {
    const { value, id } = e.target;
    setUserDetails({ ...userdetails, ...{ [id]: value } });
  };

  const UserLogin = async () => {
    try {
      const { errors, isValid } = await userValidation(userdetails);
      setErrors(errors);
      const { user_name, password } = userdetails;
  
      if (errors.user_name == undefined && errors.password == undefined) {
        let reqData = {
          user_name: user_name?.toLowerCase(),
          password: password,
          CreatedAt: Date.now() + TENMINUTESINMILISECONDS,
          filter: "login",
          ipaddress  : ipadddress
        };

        let res = await validateloginhooks(reqData);
        if (res?.data?.success == true) {
          dispatch({ type: SET_USER_LOGIN_DETAILS, payload: res?.data });

          toast.success(res?.data?.data, {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
          });
          await sleep(1000)
        } else {

          toast.error(res?.data?.data, {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
          });
          await sleep(1000)
        }
        console.log("")
        if (res?.data?.success == true) {
          handleTwoFactorShow()
        }
      }
    } catch (e) {
      console.log('UserLogin_err', e)
    }
  };


  const Proceed = async () => {
    try {
      let reqData = {
        accessToken : accessToken,
        type : acctype
      }
      let res = await updateaccounttypehooks(reqData);
      console.log("proccedd res", res)
      if(res?.data?.success == true) {
        sessionStorage.setItem("accessToken", accessToken);
        sessionStorage.setItem("signup", true)
        localStorage.removeItem("merchantId")
        Test()
        navigate("/dashboard")
        handleLoginClose()
         
      }
    } catch (e) {
      console.log("Proceed_err", e)
    }
  }


  const KYCProceed = async () => {
    try {
      let reqData = {
        accessToken : accessToken,
        type : acctype
      }
      let res = await updateaccounttypehooks(reqData);
    console.log("responddata", res)
      if(res?.data?.success == true) {
        sessionStorage.setItem("accessToken", accessToken);
        sessionStorage.setItem("signup", true)
        localStorage.removeItem("merchantId")
        setAccountmodal(true)
        Test()
          // handleLoginClose()
        
     
      }
    } catch (e) {
      console.log("Proceed_err", e)
    }
  }
  return (
    
    <div className="login-page">
      {accountmodal && <Accountmodal onDismiss={() => setAccountmodal(false)} />}
      <div className="container second_div">
        <div className="row p-0">
          <div className="col-lg-6 col-md-6 p-0 box-one">
            <div className="signin-account-section my-3 p-4">
              <h2>
                <span className="signin">SIGNIN</span> ACCOUNT
              </h2>
              <p>
                LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE{" "}
                <span className="printing">PRINTING</span>
              </p>
            </div>

            <div className="forgot-section pe-4 pb-4 ps-4">
              <p className="my-3 create-account" onClick={handleCreateAccount}>
                DO NOT HAVE AN ACCOUNT ?
              </p>
              <p
                className="my-3 forgot-password"
                onClick={handleForgotPassword}
              >
                FORGOT PASSWORD ?
              </p>
              <span>
                Lorem ipsum is simply dummy text of the printing and typesetting
                industry, Lorem ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled.
              </span>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 p-4 box-two">
            {
              !steptwo ?
                <>
                  <div className="row mb-3 mt-5">
                    <label for="inputusername3" className="col-sm-4 col-form-label">
                      USERNAME 
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="user_name"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="text-danger">{errors?.user_name} </span>
                    </div>
                    
                  </div>
                  
                  <div className="row mb-3 mt-4 mt-md-5">
                    <label for="inputPassword3" className="col-sm-4 col-form-label">
                      PASSWORD
                    </label>
                    <div className="col-sm-8">
                      <div className="password_show_hide">
                        <input
                          type={showIcon ? "text" : "password"}
                          className="form-control custom-input"
                          id="password"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {/* {console.log("show", showIcon)} */}
                        <div className="icon_sec">
                          {showIcon ? (
                            // <BiSolidShow onClick={() => setShowIcon(!showIcon)} />
                            <BiShow onClick={() => setShowIcon(!showIcon)} />
                          ) : (
                            // <BiSolidHide onClick={() => setShowIcon(!showIcon)} />
                            <BiHide onClick={() => setShowIcon(!showIcon)} />
                          )}
                        </div>
                      </div>
                      <span className="text-danger">{errors?.password} </span>
                    </div>
                    
                  </div>
                 

                  <div className="login-submit-section d-flex justify-content-between align-items-center">
                    {/* <div className="signin-commonBtn commonBtn">
                      <button
                        className="z-2"
                        style={{
                          cursor: "pointer",
                          textTransform: "none",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        Signin with Tezor
                      </button>
                    </div> */}
                    <div className="commonBtn" >
                      <button
                        className="z-2"
                        style={{
                          cursor: "pointer",
                          textTransform: "none",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        onClick={() => { UserLogin() }}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </>
                :
                <div className="loading_screen">
{/* loaded ?
                      <div className="spin_screen">
                        <div className="spinner"></div>
                      </div> */}

                      

                  {
                    
                      <div className="row mb-3 mt-5">
                        <div className="col-12">
                          <h5>Choose Your Account Type</h5>
                        </div>

                        <div className="col-12 mt-4">
                          <Form.Check
                            type="radio"
                            label='Business Account'
                            className="mt-1"
                            name="acctype"
                            value="Merchant Account"
                            defaultChecked={acctype == "Merchant" ? true : false}
                            onChange={(e) => setAcctype("Merchant")}
                          />
                          <Form.Check
                            type="radio"
                            label='Personal Account'
                            className="mt-3"
                            name="acctype"
                            value="Normal Account"
                            defaultChecked={acctype == "Normal" ? true : false}
                            onChange={(e) => setAcctype("Normal")}
                          />
                        </div>
                        <div className="col-12 mt-4 btn_se_proce">
                          <div className="signin-commonBtn commonBtn">

                            {acctype === "Merchant" ? <>
                             <button
                              className="z-2"
                              style={{
                                cursor: "pointer",
                                textTransform: "none",
                                fontSize: "13px",
                                fontWeight: "500",
                              }}
                              onClick={() => KYCProceed()}
                            >
                              Proceed
                            </button></> : <> <button
                              className="z-2"
                              style={{
                                cursor: "pointer",
                                textTransform: "none",
                                fontSize: "13px",
                                fontWeight: "500",
                              }}
                              onClick={() => Proceed()}
                            >
                              Proceed
                            </button></>}
                           
                          </div>
                        </div>
                      </div>

                  }





                </div>

            }



          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
