import React, { useEffect, useState } from "react";
import "../../../../Styles/Dashboard.css";
import searchicon from "../../../../asset/IMG/Fees/icon-park-outline_search.png";
import { Nav, Navbar, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import icon from "../../../../asset/IMG/Fees/icon.png";
import { FaFilter } from "react-icons/fa";
import lineLogo from "../../../../asset/IMG/Dashboard/line_logo.png";
import Polygon from "../../../../asset/IMG/Dashboard/Polygon 1(1).png";
import PolygonSmall from "../../../../asset/IMG/Dashboard/Polygon 2.png";
import Star from "../../../../asset/IMG/Dashboard/Star 1(1).png";
import arrow from "../../../../asset/IMG/Dashboard/maki_arrow.png";
import AddLogo from "../../../../asset/IMG/Dashboard/add-icon.png";
import Dropdown from "react-bootstrap/Dropdown";
import vectorimg from "../../../../asset/IMG/Dashboard/Vector.png";
import { useNavigate } from "react-router-dom";
import Footer from "../../../Layout/Footer";
import { countryList, profileUpdateValidation } from "../../../../hooks/UseValidation";
import { getuserdatabytoken, usereditprofilehooks } from "../../../../hooks/UseUser";
import { useDispatch } from "react-redux";
import { SET_DASHBOARD_NAVIGATE } from "../../../../redux/action";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
function AddAddress() {
  const InitialState = {
    addressname : "", 
    address : "",
    address2 : "",
    country : "",
    state : "",
    city : "",
    zipcode : "",
    filter : "addressupdate",
    defaultaddress : false,
    userId : "",
    firstName : "", lastName : "", mobileNo : ""
  }
  const [useraddress, setUserAddress] = useState(InitialState);
  const [userToken, setUserToken] = useState();
  const [errors, setErrors] = useState({});
  const [dialcode, setDialCode] = useState('1');
  console.log("useraddress", useraddress)
  const [countrylist, setcountryList] = useState(countryList);
  let NumOnly = /^\d+$/;
  let dispatch = useDispatch();
  const { addressname, address, address2, country, state, city, zipcode, filter, defaultaddress, mobileNo} = useraddress;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    let UserToken = sessionStorage.getItem("accessToken");
    setUserToken(UserToken)
    if((UserToken != '' && UserToken != null && UserToken != undefined && UserToken != "undefined")) {
      getData(UserToken)
    }

  }, [userToken]);


  const getData = async (token) => {
    try {
      let reqdata = { token: token, filter : "useraddressdata" }
      let res = await getuserdatabytoken(reqdata);
      if (res?.data?.success == true) {
        let data = res?.data?.record;
        setUserAddress({...useraddress, ...{"userId" :data?.userId}})
      }
    } catch (e) {
      console.log("getData_err", e)
    }
  }

  const handleChange = (e) => {
    const { name, value, checked } = e?.target;
    if(name == "defaultaddress") {
      setUserAddress({ ...useraddress, ...{ [name]: checked } })
    } else {
      setUserAddress({ ...useraddress, ...{ [name]: value } })
    }
   
  }



  const handlePhoneNumber = async (value, country) => {
    const { dialCode } = country;
    let newPhoneNo = value;
    if (dialCode) {
      setDialCode(dialCode)
      setUserAddress({ ...useraddress, ...{ "mobileNo": newPhoneNo.slice(dialCode.length) } })
    } else if (value) {
      setUserAddress({ ...useraddress, ...{ "mobileNo": value } })
    }
  }


  const saveAddress = async () => {
    try {
      const { errors, isValid } = await profileUpdateValidation(useraddress);
      setErrors(errors)

      if (Object.keys(errors).length == 0 && isValid === true) {
        let res = await usereditprofilehooks(useraddress);
        if(res?.data?.success == "success") {
          setErrors(res?.data?.errors)
        } else if (res?.data?.success == true) {
          dispatch({ type: SET_DASHBOARD_NAVIGATE, payload: "fifth" });
          navigate("/dashboard")
        }
      }
    } catch (e) {
      console.log("saveAddress_err", e)
    }
  }

  return (
    <>
      <div className="dashboard-page add-address-page position-relative overflow-hidden">
        {/* <div className="line-logo position-absolute">
          <img src={lineLogo} />
        </div> */}
        {/* <div className="star-logo position-absolute">
          <img src={Star} />
        </div> */}

        <div className="header-section py-5">
          <div className="container d-flex flex-column align-items-start dashboard-section">
            <div>
              <h2>ADDRESS BOOK</h2>
              <h4>
                TRADE, SELL AND HOLD 10+ CRYPTOCURRENCIES ON <br></br>
                <span className="youpay">COINPAYMATE</span>
              </h4>
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              onClick={handleBack}
              style={{ cursor: "pointer" }}
            >
              <img src={arrow} className="left-arrow-custom" />{" "}
              <span className="back ms-2">BACK</span>
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="address-book container py-3">
            <div className="recent-sections">
              <div className="filter-sec position-relative mb-5">
                <div className="d-flex align-items-center">
                  <div className="img-section">
                    <img src={icon} />
                  </div>
                  <h4 className="m-0 ps-3">ADD NEW ADDRESS</h4>
                </div>
                <div className="d-flex filter-box-search">
                  <button className="commonBtn p-0" style={{ width: "120px" }}>
                    <img
                      src={AddLogo}
                      alt=""
                      className="add-address-icon me-2"
                    />{" "}
                    ADD ADDRESS
                  </button>
                </div>
              </div>
            </div>

            <div className="basic-settings-content mx-0">
              <div className="row public-info">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-4">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                       ADDRESS NAME:
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="addressname" value={addressname} onChange={(e) => {handleChange(e)}}/>
                    </div>
                    <span className="text-danger">{errors?.addressname}</span>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-4 align-items-center">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                       DEFAULT ADDRESS :
                    </label>
                    <div class="col-lg-8">
                    <div className="d-flex key-enabled">
                  <div className="d-flex align-items-center justify-content-center">
                    {/* <span className="ms-3"> */}
                      <input
                        // class="form-check-input custom-input custom-checkbox"
                        type="checkbox"
                        id="check1"
                        name="defaultaddress"
                       checked={defaultaddress}
                        onChange={(e) => handleChange(e)}
                      />
                    {/* </span> */}
                  </div>
                </div>
                      {/* <input type="checkbox" class="form-control" name="defaultaddress" checked={true} onChange={(e) => {handleChange(e)}}/> */}
                    </div>
                    <span className="text-danger">{errors?.defaultaddress}</span>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-4">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      FIRST NAME:
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="firstName" onChange={(e) => {handleChange(e)}}/>
                    </div>
                    <span className="text-danger">{errors?.firstName}</span>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-4">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      LAST NAME:
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="lastName" onChange={(e) => {handleChange(e)}} />
                    </div>
                    <span className="text-danger">{errors?.lastName}</span>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-4">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      ADDRESS:
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="address" value={address} onChange={(e) => {handleChange(e)}}/>
                    </div>
                    <span className="text-danger">{errors?.address}</span>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-4">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      ADDRESS 2:
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="address2" value={address2} onChange={(e) => {handleChange(e)}}/>
                    </div>
                    <span className="text-danger">{errors?.address2}</span>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-4">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      COUNTRY:
                    </label>
                    <div class="col-lg-8">
                      <Dropdown  value={country} onSelect={(e) => { setUserAddress({ ...useraddress, ...{ "country" : e } }) }}>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="custom-drop-btn w-100 d-flex justify-content-between align-items-center"
                        >
                          {country ? country : "Select Country" }
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100 countrydropstyle" value={country} >
                          {countryList && countryList?.map((val, i) => {
                            return (<><Dropdown.Item eventKey={val?.country}>{val?.country}</Dropdown.Item></>)
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <span className="text-danger">{errors?.country}</span>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-4">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      STATE:
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="state" value={state} onChange={(e) => {handleChange(e)}}/>
                    </div>
                    <span className="text-danger">{errors?.state}</span>
                  </div>
                 
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-4">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      CITY:
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="city" value={city} onChange={(e) => {handleChange(e)}}/>
                    </div>
                    <span className="text-danger">{errors?.city}</span>
                  </div>
                
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-4">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      ZIP CODE:
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="zipcode" value={zipcode} onChange={(e) => { if (NumOnly.test(e?.target?.value) || e?.target?.value == "") { handleChange(e) } }}/>
                    </div>
                    <span className="text-danger">{errors?.zipcode}</span>
                  </div>
                 
                </div>


                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row mb-4">
                        <label
                          for="colFormLabel"
                          class="col-lg-4 col-form-label"
                        >
                          MobileNo
                        </label>
                        <div class="col-lg-8">
                          <PhoneInput
                            country='us'
                            placeholder=''
                            value={dialcode + mobileNo}
                            onChange={handlePhoneNumber}
                            // autoComplete="new-password"
                            specialLabel={false}
                          />
                        </div>
                        <span className="text-danger">{errors?.mobileNo}</span>
                      </div>
                    </div>

                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-4">
                    <label for="colFormLabel" class="col-lg-4 col-form-label">
                      PHONE NUMBER:
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="" onChange={(e) => {handleChange(e)}}/>
                    </div>
                  </div>
                </div> */}
              </div>
              <div
                className="d-flex align-items-center justify-content-end account-settings"
                style={{ gap: "5px" }}
              >
                <button className="commonBtn p-0" style={{ width: "131px" }} onClick={() => saveAddress()}>
                  <img src={AddLogo} alt="" className="common-inside-btn-img me-2" />{" "}
                  SAVE ADDRESS
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddAddress;
