import io from 'socket.io-client';
import { BACKURL, FRONTENDURL} from './env';

const socket = io(BACKURL);
console.log("BACKURL_BACKURL", BACKURL, socket);



socket.on('connection', function () {
    console.log('Client Connected');
    const token = sessionStorage.getItem("accessToken");
    console.log("tokendata", token)
    if (token) {
        createSocketUser()

    }
});


const createSocketUser = () => {
    console.log("emit_frontend")
    // socket.emit('LOGINUSER', "venkatesh")
}


export {
    socket,
    createSocketUser
}