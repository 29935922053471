import React, { useEffect, useState } from "react";
import "../../Styles/TwoFactor.css";
import { Container, Row, Col } from "react-bootstrap";
import OtpInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import { userValidation } from "../../hooks/UseValidation";
import { Test, checkloginotphooks, getAuthToken } from "../../hooks/UseUser";
import { style, position, iconTheme } from "../../hooks/UseToast";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { sleep } from "../../hooks/UseUser";
import { SET_ACCOUNTTYPE } from "../../redux/action";
import axios from 'axios'
import {Buffer} from 'buffer';

const TwoFactor = (props) => {

  const navigate = useNavigate();
  let logindata = useSelector((state) => state.reducer.userlogindetails);
  // const [otp, setOtp] = useState();
  const [Error, setError] = useState({});
  const [otp, setOtp] = useState('');
  const [ip, setIP] = useState("")

  let regex = /^[0-9]+$/;
  let dispatch = useDispatch();


  const GetIpAddress = async()=>{
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log("res?.data", res,res?.data?.IPv4)
     setIP(res?.data?.IPv4)
}


useEffect(() => {
  GetIpAddress()
 }, []);

const Loginhistory ={
       
  loginTime:Buffer.from(String(Date.now())).toString('base64') ,
  Browser:Buffer.from(window.navigator.userAgent).toString('base64'),
  ipaddress: Buffer.from(ip).toString('base64')

}



  const UserLogin = async () => {
    try {

      const { errors, isValid } = await userValidation({ otp: otp, filter: "otp" });
      setError(errors);

      if (errors?.otp == undefined) {
        let reqData = {
          user_name: (logindata?.user_name).toLowerCase(),
          Otp: otp,
          LoginHistory: Loginhistory,
          Date: Date.now(),
          filter: "otp"
        }
        let res = await checkloginotphooks(reqData);

        if (res?.data?.success == true) {
          props?.onDismiss();
          if(res?.data?.accounttype != "") {
            toast.success(res?.data?.data, {
              position: position.position,
              style: style,
              iconTheme: iconTheme,
            });
          }
          if (res?.data?.accounttype != "") {
            sessionStorage.setItem("accessToken", res?.data?.accessToken)
            sessionStorage.setItem("signup", true)
            Test()
            navigate("/dashboard")
            await sleep(1000);
            console.log("asdfasdfasdf 234234", res?.data)
          } else {
            let obj = {
              accessToken : res?.data?.accessToken,
              accounttype : true
            }
            props?.onLogin()
            dispatch({ type: SET_ACCOUNTTYPE, payload: obj })
          }


        } else if (res?.data?.success == false) {

          toast.error(res?.data?.data, {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
          });
          await sleep(1000)
        }

      }
    } catch (e) {
      console.log("UserLogin_twofactor_err", e)
    }
  }


  return (
    <div className="two-factor">
      <div className="container second_div">
        <div className="row">
          <div className="col-md-6 col-12 bord-left">
            <div className="twoFactor-sec my-3 p-4">
              <h2>
                <span>2</span> Factor authentication
              </h2>
              <p className="subtitle">
                LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE <span>PRINTING</span>
              </p>
            </div>
            <div className="twoFactor-section-text mt-3 ps-4 pe-4 pb-4">
              <p className="twoFactor-mainP">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12 p-4 bord-left-right">
            <div className="code container">
              <div className="row align-items-center">
                <div className="col-sm-4 col-12">
                  <label
                    for="inputPassword3"
                    className=" col-form-label label-features"
                  >
                    Code
                  </label>
                </div>
                <div className="col-sm-8 col-12 otp_input_div">
                  <OtpInput
                    value={otp ? (otp == "" || regex.test(otp)) ? otp : (otp == "" || regex.test(otp)) ? otp : "" : ""}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  {/* <input
                    type="text"
                    value={otp}
                    className="form-control custom-input input-features"
                    onChange={(e) => { if (e.target.value == "" || regex.test(e.target.value)) { if (e.target.value.length <= 4) { setOtp(e.target.value) } } }}
                  />  */}
                </div>
                <span className="text-danger">{Error?.otp} </span>
              </div>
            </div>
            <button className="commonBtn btn_Features" onClick={() => UserLogin()}>Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactor;
