import React from "react";
import { stableCoin } from "../../Data/data.js";
import tableTop from "../../asset/IMG/table-top.png";
import dollarGreen from "../../asset/IMG/dollarGreen.png";
import bitcoin from "../../asset/IMG/bitcoin.png";
import tableBot from "../../asset/IMG/support-coin-rec.png";

// import btc from '../../asset/IMG/cryptocurrency-color_btc.png'

import clock from "../../asset/IMG/clock.png";
import address from "../../asset/IMG/address.png";

import view from "../../asset/IMG/view.png";
import deleteImg from "../../asset/IMG/delete.png";

const StableCoin = () => {
  return (
    <div className="tableContainer">
      {stableCoin.map((item) => (
        <div key={item.id}>
          <div className="table-responsive">
            <table class="table table-bordered table_wallet">
              <tbody>
                <tr className="headerRow">
                  <td rowSpan={2} className="firstTd">
                    {/* <div className="position-absolute table_toprec">
                      <img
                        src={tableTop}
                        alt="rectangle"
                        className="img-fluid"
                      />
                    </div> */}
                    <div className="coin-type d-flex align-items-center justify-content-center">
                      <div className="d-flex align-items-center coin-div">
                        <div className="coin-img">
                          <img
                            src={item.coinImg}
                            alt="btc"
                            className="img-fluid"
                          />
                        </div>
                        <div className="coin-txt">
                          <div className="coin-name-title">
                            <p>Coin Name</p>
                          </div>
                          <div className="coin-name">
                            <p>{item.coinName}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="tableTitle">Code</td>

                  <td className="tableTitle">Payment fee</td>
                  <td className="tableTitle">Confrims</td>
                  <td className="tableTitle">To USD</td>
                  <td className="tableTitle">To BTC</td>
                </tr>
                <tr className="contentRow">
                  <td>
                    <div className="tableContent">
                      <p>{item.code}</p>
                    </div>
                  </td>
                  <td>
                    <div className="tableContent">
                      <p>{item.paymentFee}</p>
                    </div>
                  </td>
                  <td>
                    <div className="tableContent">
                      <p>{item.confirms}</p>
                    </div>
                  </td>
                  <td>
                    <div className="tableContent">
                      <div>
                        <img
                          src={dollarGreen}
                          alt="Bitcoin"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <p className="statusColor">{item.btc}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tableContent">
                      {/* <div className="rectangleBottom support_coin_bot">
                        <img
                          src={tableBot}
                          alt="Rectangle"
                          className="img-fluid"
                        />
                      </div> */}
                      <div>
                        <img src={bitcoin} alt="dollar" className="img-fluid" />
                      </div>
                      <div>
                        <p className="statusColor">{item.usd}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StableCoin;
