import React, { useEffect, useContext } from 'react';
import SocketContext from './content/socketContent';
import { FRONTENDURL } from './config/env';
import jwt_decode from "jwt-decode"

const HelperRoutes = () => {

    const socketContext = useContext(SocketContext);
    let token = sessionStorage.getItem("accessToken");
    console.log("socketContext_socketContext tokensocketContextdata", socketContext?.socket, token)
    useEffect(() => {
        console.log("111 decoded_dasta", socketContext.socket)
        if (socketContext.socket) {
            try {

                socketContext.socket.on("FORCELOGOUT", (data) => {
                    try {
                        console.log('frontendforcelogout', data)
                        if (data?.user_id) {
                            sessionStorage.removeItem("accessToken");
                            window.location.href = FRONTENDURL;
                        }
                    } catch (e) {
                        console.log("forcelogout_err", e)
                    }
                })



                let token = sessionStorage.getItem("accessToken");
              

                if (token != '' && token != null && token != undefined) {
                    const decoded = jwt_decode(token);
                    console.log("decoded_dasta", decoded)
                    socketContext.socket.emit("LOGINUSER", decoded);
                    console.log("welcomeddata Socket Initiated")
                }
            } catch (e) {
                if (sessionStorage) {
                    console.log("Error : ", e);
                    sessionStorage.clear();
                    window.location.href = FRONTENDURL;
                }
            }

        }
    }, [socketContext.socket, sessionStorage.getItem("accessToken")])
}

export default HelperRoutes;