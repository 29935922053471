import React, { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AddnewAddress from "./AddnewAddress";
import EditnewAddress from "./EditnewAddress";

import { Card, Col, Row } from "react-bootstrap";
import { BiSolidPencil } from "react-icons/bi";

import qrcode from "../../asset/IMG/NewLanding/qrcode.png"
import { useLocation, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { getAssetsymbol, getuserparticularwalletdatahooks } from "../../hooks/UseUser";
import { DateTimeForm } from "../../hooks/UseValidation";
const Deposits = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [depositdata, setDepositData] = useState({});
  const [assetdata, setassetdata] = useState({});
  const [check, setCheck] = useState(false);
  const [currentrec, setCurrentRec] = useState({});
  const [index, setIndex] = useState()
  
  let location = useLocation();


  useEffect(() => {
    async function fetchData() {
      let reqdata = { _id: location?.pathname?.split("/")[2], accessToken: sessionStorage.getItem("accessToken"), filter: "assetwalletdata" }
      let res = await getuserparticularwalletdatahooks(reqdata);
      setDepositData(res?.data?.rec)

      let assetdata = await getAssetsymbol(res?.data?.rec?.symbol);
      setassetdata(assetdata)
    


     
    }
    fetchData()
  }, [location, check]);

  const month = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUGUST", "SEP", "OCT", "NOV", "DEC"];

  const d = new Date();
  let name = month[d.getMonth()];


  return (
    <>
      <section className="paddingY walletSection ">
        <div className="wallet_top_container position-relative">
          <div className="top_bg">
            <div className="container">
              <div className="walletTop">
                <p className="titleP">Deposit</p>
                <p className="subtitleP">
                  One wallet. Countless features on the <br /> go through{" "}
                  <span>COINPAYMATE</span>{" "}
                </p>
              </div>

              {/* <button className="commonBtn">
          <div className="leftBtnBg"></div>

          <div className="z-2">sign in</div>
        </button> */}
            </div>
          </div>
        </div>
        <div
          className="container-fluid  walletBottom wallet_margin_top background_img"
          style={{ zIndex: "100" }}
        >
          <div className="container tab-content">
            <div className="heading py-5">
              <div className="left">
                <p>Deposit Coins - {depositdata?.symbol} ( {depositdata?.name} )</p>

              </div>
            </div>

            {/* LOOP HERE`` */}
           {/* { depositdata && depositdata?.addressInfo?.length > 0 && depositdata?.addressInfo?.map((val, ind) =>  */}
           
           { depositdata  && depositdata?.addressInfo?.map((val, ind) => 
           
           

           <div className="table-responsive">
              <table class="table table-bordered table_wallet table_depo">
                <tbody>
                  <tr className="headerRow">

                    <td className="tableTitle">Deposit Address - {depositdata?.name}</td>
                    <td className="tableTitle">QR Code</td>
                    <td className="tableTitle">Date</td>

                  </tr>
                  <tr className="contentRow">
                    <td>
                      <div className="tableContent dif_clr2">
                        <p className="lite_yellow_clr">
                          <span>{val?.label}</span>
                          <button className="bluebtn_theme btn_edit ms-2" onClick={() => {  setIndex(ind); setShowEdit(true); }}>
                            <BiSolidPencil size={10} />
                          </button>
                        </p>
                        <p className="mt-4">{val?.address}</p>
                      </div>
                    </td>
                    <td>
                      <div className="tableContent">
                        {/* <p className="mt-2">Personal</p> */}
                        <div className="qr_img_div">
                          {/* <img src={qrcode} alt="address" className="img-fluid" /> */}
                          <QRCode className="qr_bar_code" value={val?.address}/>
                          {/* <QRCode
              title="GeeksForGeeks"
              // value={walletData.walletAddress}
              className="qr_bar_code"
            // bgColor={back}
            // fgColor={fore}
            // size={size === '' ? 0 : size}
            /> */}
                        </div>

                      </div>
                    </td>


                    <td>
                      <div className="tableContent dif_clr">
                        <p> {(DateTimeForm(val?.createdAt, true, false, false)).split('/')[0]} {month[new Date(val?.createdAt)?.getMonth()]} {DateTimeForm(new Date(val?.createdAt), false, true, true)}</p>
                      </div>
                    </td>

                  </tr>

                </tbody>
              </table>

            </div> )}


            {/* END LOOP HERE`` */}

            <div className="text_left my-4">
              {(depositdata && depositdata?.addressInfo?.length > 0) && <button onClick={() => { setShowAdd(true) }} className="bluebtn_theme">Add New Deposit Address</button>}
            </div>

            <div className="notes_card mb-5">
              <b>Notes:</b>   Personal addressed have no deposit fee. Commercial addresses have the standard Coinpayments {assetdata?.DepositFee} % deducted.
            </div>
          </div>
        </div>
      </section>
      {showAdd && <AddnewAddress onDismiss={() => setShowAdd(false)} label={depositdata && depositdata?.addressInfo?.length > 0 ? depositdata : ""} index={index} onCheck={() => {setCheck(!check)}}/>}
      {showEdit && <EditnewAddress onDismissedit={() => setShowEdit(false)} label={depositdata && depositdata?.addressInfo?.length > 0 ? depositdata : ""} index={index} onCheck={() => {setCheck(!check)}}/>}

      <Footer />
    </>
  );
};

export default Deposits;
