import React, { useEffect, useState } from "react";
// import "../../Styles/WalletStyle.css";

import FilterCoins from "../utils/FilterCoins";
import Heading from "../utils/Heading";
import { Link } from "react-router-dom";
import topimg from "../../asset/IMG/top-img.png";
import send from "../../asset/IMG/send.png";
import receive from "../../asset/IMG/receive.png";
import transTop from "../../asset/IMG/transaction-top.png";
import transBot from "../../asset/IMG/transaction-bottom.png";
import star from "../../asset/IMG/star2.png";
import halfPoly from "../../asset/IMG/Polygon8.png";
import doubleBar from "../../asset/IMG/double_rec.png";
import Footer from "../Layout/Footer";
import halfstar from "../../asset/IMG/star3.png";
import polygon7 from "../../asset/IMG/polygon7.png";
import { getuserdatabytoken, getuserwalletdetails } from "../../hooks/UseUser";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Accountmodal from "../Modals/accountmodal"
import { DateTimeFormat1 } from "../../hooks/UseValidation";


const Wallet = () => {


  const [accountmodal, setAccountmodal] = useState(false);


  const [userToken, setUserToken] = useState();
  const [id, setId] = useState("");
  const [walletData, setWalletData] = useState("");
  const [searchData, setSearchData] = useState("");
  const [walletcoin, setWalletCoin] = useState([]);
  let usergetcurrentassetdata = useSelector((state) => state.reducer.usergetcurrentassetdata);
  const [userdetails, setUserDetails] = useState({});



  useEffect(() => {
    let UserToken = sessionStorage.getItem("accessToken");
    setUserToken(UserToken);
    if (
      UserToken != "" &&
      UserToken != null &&
      UserToken != undefined &&
      UserToken != "undefined"
    ) {
      getData(UserToken);
      getUserData(UserToken)
    }
  }, [userToken]);

  

  const getUserData = async (token) => {
    try {
      let reqdata = { token: token, filter: "userprofiledata" }
      let res = await getuserdatabytoken(reqdata);
      if (res?.data?.success == true) {
        let data = res?.data?.record;
        let obj = {
          email: data?.email,
          confirmemail: data?.email,
          firstName: data?.first_name,
          lastName: data?.last_name,
          gender: data?.gender,
          mobileNo: data?.mobile_no,
          username: data?.user_name,
          userId: data?.userId,
          logoURI: data?.logoURI,
          timezone: data?.Timezone != "" ? data?.Timezone : DateTimeFormat1(new Date(), true),
          timeformat: data?.TimeFormat != "" ? data?.TimeFormat : DateTimeFormat1(new Date(), true),
          dateformat: data?.DateFormat != "" ? data?.DateFormat : new Date().toISOString().substring(0, 10),
          accounttype: data?.accounttype,
          merchantsetting: data?.merchantSetting,
          isKYCVerify : data?.isKYCVerify,
          KYCFile : data?.KYCFile
        }
        console.log("data?.merchantSetting", obj, data?.isKYCVerify)
        let obj1 = {
          accounttype: data?.accounttype,
          ipnsecret: data?.merchantSetting[0]?.IpnSecret,
          ipnurl: data?.merchantSetting[0]?.IpnUrl,
          logemail: data?.merchantSetting[0]?.LogEmail,
          ipnfiatcurrency: data?.merchantSetting[0]?.IpnFiatCurrency,
          receiveemail: data?.merchantSetting[0]?.ReceiveEmail,
        }
        let obj2 = {
          publicname: data?.PublicInfo[0]?.PublicName,
          publicemail: data?.PublicInfo[0]?.PublicEmail,
          publicurl: data?.PublicInfo[0]?.PublicUrl,
          usegravata: data?.PublicInfo[0]?.UseGravata,
        }
        setUserDetails({ ...userdetails, ...obj });
      
        // setRecoveryEmail(data?.email)
      }
    } catch (e) {
      console.log('getUserData_err', e)
    }
  }

  const getData = async (token) => {
    try {
      let reqdata = { token: token, filter: "userIddata" };
      let res = await getuserdatabytoken(reqdata);
      if (res?.data?.success == true) {
        let data = res?.data?.record;
        
        setId(data?._id);
      }
    } catch (e) {
      console.log("getData_err", e);
    }
  };

  useEffect(() => {
    async function fetchData() {
     
      if (searchData == "") {
        let reqData = { _id: id, search: "" };
        if (id != "" && searchData == "") {
          let res = await getuserwalletdetails(reqData);
          
       
          let rec = res?.data?.records
          setWalletData(rec);
          let arr = ["ALL"];
          let fil =
            (await rec) &&
            rec?.length > 0 &&
            rec?.map((val) => {
              arr.push(val?.symbol);
            });
          console.log("arrrr", arr);
          if (rec?.length > 0) {
            setWalletCoin(arr);
          } else {
            setWalletCoin([])
          }

        }
      } else if (searchData != "" && id != "") {
        let reqData = { _id: id, search: searchData };
      
        if (id != "") {
          let res = await getuserwalletdetails(reqData);
        
          if (searchData == "ALL") {
            // let rec = res?.data?.records[0]?.assets;
            let rec = res?.data?.records;
            setWalletData(rec);
          } else {
            setWalletData(res?.data?.records);
          }

        
        }
      }
      console.log("serachdafasdfasd", searchData);
    }
    fetchData();
  }, [id, searchData, usergetcurrentassetdata]);


  return (
    <>

      {accountmodal && <Accountmodal onDismiss={() => setAccountmodal(false)} />}
      {(userdetails?.accounttype == "Merchant" &&  userdetails?.isKYCVerify === false) ? <div className="blur_custom_wallet">
        <div className="blur_sec_wallet">
          <section className="paddingY walletSection">
            <div className="wallet_top_container position-relative">
     

              <div>

              </div>

              <div className="top_bg">
                <div className="container">
                  <div className="walletTop">
                    <p className="titleP">Wallet</p>
                    <p className="subtitleP">
                      One wallet. Countless features on the <br /> go through{" "}
                      <span>COINPAYMATE</span>{" "}
                    </p>
                  </div>

                </div>
              </div>
            </div>
            <div
              className="container-fluid  walletBottom wallet_margin_top background_img"
              style={{ zIndex: "100" }}
            >
         
              <div className="container">
                <div className="wallet_content ">
                  <div className="position-relative">
                    <div className="position-relative">
            
                      <Heading
                        name="coin wallets"
                        walletCoinData={walletcoin}
                        getSearchData={(data) => {
                         
                          setSearchData(data);
                        }}
                      />
                    </div>
                  </div>
                  {walletData && walletData?.length > 0 ? <div className="content mt-5 ">
                    <FilterCoins walletdata={walletData} />
                  </div> : <>
                    <div className="empty_rec">
                      <p >No Records</p>
                    </div>
                  </>}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="custom_locked_cnt">
          <h6>Please wait until admin  verify kyc document.....</h6>
        </div>
      </div> : <section className="paddingY walletSection">
            <div className="wallet_top_container position-relative">
             

              <div>

              </div>

              <div className="top_bg">
                <div className="container">
                  <div className="walletTop">
                    <p className="titleP">Wallet</p>
                    <p className="subtitleP">
                      One wallet. Countless features on the <br /> go through{" "}
                      <span>COINPAYMATE</span>{" "}
                    </p>
                  </div>

            
                </div>
              </div>
            </div>
            <div
              className="container-fluid  walletBottom wallet_margin_top background_img"
              style={{ zIndex: "100" }}
            >
        
              <div className="container">
                <div className="wallet_content ">
                  <div className="position-relative">
                    <div className="position-relative">
                    
                      <Heading
                        name="coin wallets"
                        walletCoinData={walletcoin}
                        getSearchData={(data) => {
                          console.log("sdfasdfsda", data);
                          setSearchData(data);
                        }}
                      />
                    </div>
                  </div>
                  {walletData && walletData?.length > 0 ? <div className="content mt-5 ">
                    <FilterCoins walletdata={walletData} />
                  </div> : <>
                    <div className="empty_rec">
                      <p >No Records</p>
                    </div>
                  </>}
                </div>
              </div>
            </div>
          </section>}
      <Footer />
    </>
  );
};

export default Wallet;


