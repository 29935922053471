import React, { useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Container, Row } from "react-bootstrap";
import "../../Styles/HomeStyle.css";

import gift from "../../asset/IMG/gift.png";
import arrow from "../../asset/IMG/arrow-updown.png";
import profile from "../../asset/IMG/profile.png";
import googleBtn from "../../asset/IMG/googleBtn.png";
import logo from "../../asset/IMG/logo.png";
import logodark from "../../asset/IMG/bannerLightMobLogo.png";
import Features from "../utils/Features";
import Account from "../utils/Account";
import Install from "../utils/Install";
import Support from "../utils/Support";
import GetStarted from "../utils/GetStarted";
import fullstar from "../../asset/IMG/fullstar.png";
import polygon4 from "../../asset/IMG/polygon3.png";
import homepagePoly from "../../asset/IMG/homepage-poly.png";
import bannerDoubleRec from "../../asset/IMG/banner-double-rec-bar.png";
import bannerRightBotRec from "../../asset/SVG/banner-right-bot-rec.svg";
import bannerArrowLiteOne from "../../asset/IMG/liteCurveArrow.png";
import bitcoinYellow from "../../asset/IMG/bitcoin_yellow.png";
import bitcoinLite from "../../asset/IMG/bitcoin_lite.png";
import homeRight from "../../asset/IMG/homeright.jpg";
import homeRightDark from "../../asset/IMG/homerightdark.jpg";

import light_slide_one from "../../asset/IMG/lite_home_slide1.jpg";
import light_slide_two from "../../asset/IMG/lite_home_slide2.jpg";
import light_slide_three from "../../asset/IMG/lite_home_slide3.jpg";
import dark_slide_one from "../../asset/IMG/dark_home_slide1.jpg";
import dark_slide_two from "../../asset/IMG/dark_home_slide2.jpg";
import dark_slide_three from "../../asset/IMG/dark_home_slide3.jpg";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Home = ({ darkTheme, setDarkTheme }) => {
  return (
    <>
      <section className="homepage">
        <div className="banner_container">
          <div className="position-absolute banner_arrow_One">
            <img src={bannerArrowLiteOne} alt="arrow" className="img-fluid" />
          </div>
          <div className="position-absolute bitcoin_yellow">
            <img src={bitcoinYellow} alt="bitcoin" className="img-fluid" />
          </div>
          <div className="position-absolute bitcoin_lite">
            <img src={bitcoinLite} alt="bitcoin" className="img-fluid" />
          </div>
          <div className="position-absolute banner_arrow_Two">
            <img src={bannerArrowLiteOne} alt="arrow" className="img-fluid" />
          </div>
          <div className="position-absolute banner_right_bot_rec">
            <img
              src={bannerRightBotRec}
              alt="rectangle"
              className="img-fluid"
            />
          </div>
          <div className="position-absolute banner_double_rec">
            <img src={bannerDoubleRec} alt="rectangle" className="img-fluid" />
          </div>
          <div className="position-absolute banner_rec_Top">
            <img
              src={require("../../asset/SVG/rectangleTop.svg").default}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="homepage-poly position-absolute">
            <img src={homepagePoly} alt="Polygon" className="img-fluid" />
          </div>
          <div className="bannerStar">
            <img src={fullstar} alt="full star" className="img-fluid" />
          </div>
          <div className="container banner_section_margin_top">
            <div className="row">
              <div className="col-md-6 col-lg-5 col-12 bannerLeft z-2 ">
                <div className="bannerLeftTop">
                  <p>
                    <span className="bannerLeftTopSpan1">Crypto</span>
                    &nbsp;payments made
                    <br /> easy though <br />
                    <span className="bannerLeftTopSpan2">COINPAYMATE</span>
                  </p>
                </div>

                <div className="d-flex align-items-center bannerLeftCenter">
                  <img src={gift} alt="gift" className="img-fluid giftImg" />
                  <p> trade limited Coins for free</p>
                  <img
                    src={arrow}
                    alt="arrow-updown"
                    className="img-fluid arrowImg"
                  />
                </div>

                <div className="bannerLeftBottom d-flex align-items-center">
                  <div className="commonBtn left-signup-btn">
                    <div className="d-flex align-items-center z-2 gap-2 ">
                      <img
                        src={profile}
                        alt="profile"
                        className="img-fluid img-size"
                      />
                      <p className="para1 para-size">
                        Signup With Email or phone
                      </p>
                    </div>
                  </div>
                  {/* <div className="signupBg d-flex align-items-center">
                  <img src={profile} alt="profile" className="img-fluid" />
                  <p className="para1">Signup With Email or phone</p>
                </div> */}

                  <p className="para2">or</p>
                  <div className="googleBg">
                    <img
                      src={googleBtn}
                      alt="googleBtn"
                      className="img-fluid"
                    />
                    <p>google</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-6 col-lg-7 col-12 bannerRight">
              <div className="bannerRightMainBg">
                <img src={logo} alt="logo" className="img-fluid logoImg" />
                <Container className="bannerRightSeconBg">
                  <div className="bannerRightTop">
                    <p>
                      <span className="bannerRightTopSpan1">Buy,</span>
                      &nbsp;trade , sell and hold 1o+
                      <br /> cryptocurrencies on <br />
                      <span className="bannerRightTopSpan2">COINPAYMATE</span>
                    </p>
                  </div>

                  <div className="bannerRightCenter">
                    <img src={gift} alt="gift" className="img-fluid giftImg" />
                    <p> trade limited Coins for free</p>
                    <img
                      src={arrow}
                      alt="arrow-updown"
                      className="img-fluid arrowImg"
                    />
                  </div>
                  <div className="bannerRightBottom d-flex align-items-center">
                    <div className="signupBg d-flex align-items-center">
                      <img src={profile} alt="profile" className="img-fluid" />
                      <p className="para1">Signup With Email or phone</p>
                    </div>
                    <p className="para2">or</p>
                    <div className="googleBg">
                      <img
                        src={googleBtn}
                        alt="googleBtn"
                        className="img-fluid"
                      />
                      <p>google</p>
                    </div>
                  </div>
                </Container>
              </div>
            </div> */}
              <div className="col-md-6 col-lg-7 col-12 z-2 home_right_margin_top ">
                {/* <div className="bannerRight">
                  <div className="bannerRightImgContainer">
                    <img
                      src={darkTheme ? logo : logodark}
                      alt="logo"
                      className="img-fluid logoImg"
                    />
                  </div>

                  <Container className="bannerRightSeconBg">
                    <div className="bannerRightPoly d-none d-lg-block">
                      <img src={polygon4} alt="polygon" className="img-fluid" />
                    </div>
                    <div className="bannerRightTop">
                      <p>
                        <span className="bannerRightTopSpan1">Buy,</span>
                        &nbsp;trade , sell and hold 1o+
                        <br /> cryptocurrencies on <br />
                        <span className="bannerRightTopSpan2">COINPAYMATE</span>
                      </p>
                    </div>

                    <div className="bannerRightCenter">
                      <img
                        src={gift}
                        alt="gift"
                        className="img-fluid giftImg"
                      />
                      <p> trade limited Coins for free</p>
                      <img
                        src={arrow}
                        alt="arrow-updown"
                        className="img-fluid arrowImg"
                      />
                    </div>
                    <div className="bannerRightBottom d-flex align-items-center">
                      <div className="commonBtn padding-size left-signup-btn">
                        <div className="d-flex align-items-center z-2 gap-2">
                          <img
                            src={profile}
                            alt="profile"
                            className="img-fluid img-size"
                          />
                          <p className="para1 para-size">
                            Signup With Email or phone
                          </p>
                        </div>
                      </div>
                      <p className="para2">or</p>
                      <div className="googleBg">
                        <img
                          src={googleBtn}
                          alt="googleBtn"
                          className="img-fluid"
                        />
                        <p>google</p>
                      </div>
                    </div>
                  </Container>
                </div> */}
                <div className="homeright_sliders">
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper width_height"
                    breakpoints={{
                      1920: {
                        slidesPerView: 1,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <img
                        src={darkTheme ? dark_slide_one : light_slide_one}
                        alt=""
                        className="img-fluid banner_slider_img"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      {" "}
                      <img
                        src={darkTheme ? dark_slide_two : light_slide_two}
                        alt=""
                        className="img-fluid banner_slider_img"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      {" "}
                      <img
                        src={darkTheme ? dark_slide_three : light_slide_three}
                        alt=""
                        className="img-fluid banner_slider_img"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={darkTheme ? homeRightDark : light_slide_one}
                        alt=""
                        className="img-fluid banner_slider_img"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      {" "}
                      <img
                        src={darkTheme ? homeRightDark : homeRight}
                        alt=""
                        className="img-fluid banner_slider_img"
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
          <Features darkTheme={darkTheme} setDarkTheme={setDarkTheme} />
        </div>
        <Account darkTheme={darkTheme} setDarkTheme={setDarkTheme} />
        <Install darkTheme={darkTheme} />
        <Support />
        <GetStarted />
      </section>
      <Footer />
    </>
  );
};

export default Home;
