import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

import cartIcon from "../../asset/SVG/cart.svg";
import { useLocation, useParams } from "react-router-dom";
import qrcode from "../../asset/IMG/qrcode.png";
import PaymentSuccess from "../Modals/PaymentSuccess";
import PaymentFailed from "../Modals/PaymentFailed";
import { ConvertusdPrice, useCreateOrder } from "../../hooks/UseBackend";
import { position, iconTheme, style } from "../../hooks/UseToast";
import QRCode from "react-qr-code";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Select from "react-select";
import { getallassetdatahooks, getuserdatabytoken, getuserwalletdetails } from "../../hooks/UseUser";

const PaymentGateway = () => {
  
  const [successShow, setSuccessShow] = useState(false);
  const [failedShow, setFailedShow] = useState(false);
  const [orderid,setoderid] = useState("");
  const [amount,setamount]= useState("");
  const [successurl,setsuccessurl] = useState("")
  const [cancleurl,setcancleurl] = useState("")
  const [email,setemail]= useState("");
  const [currency,setcurrency] = useState('');
  const [key,setkey] = useState('');
  const [convertedUsdtprice,setconvertedUsdtprice] = useState('');
  const [outputurl,setoutputurl] = useState('');
  const [depositAddress,setdepositAddress] = useState('');
  const [walletdata,setWalletData] = useState('')
  

  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([])
  const [convertedselectcurrency,setconvertedselectcurrency] = useState('')
console.log('selectedOption', selectedOption)
  // const handleSelect = (eventKey, event) => {
  //   const id = event.target.id;

  //   var Form = Formdata;
  //   if (id == "FilterCoin") {
  //     Form = { ...Form, ...{ [id]: eventKey } };
  //     setFormdata(Form);
  //   } else if (id == "vaccine") {
  //     Form = { ...Form, ...{ [id]: eventKey } };
  //     setFormdata(Form);
  //   }

  // };
  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#fff",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#06038D"
          : isSelected
          ? "#06038D"
          : isFocused
          ? "#06038D"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    menu:(provided,state) => ({
     width:"180px",
     border: "1px solid lightgray",
     borderRadius: 10,


    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid lightgray",
      borderRadius: 10,
      fontSize: "13px",
      width:"180px"
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      width:"180px"

    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      // width:"180px"

    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",

    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      width:"178px",
      borderRadius:10

    }),
  };
const getAssetData = async() => {
  let reqdata = {
    filter: "all",
    search : "" 
  }
  let res = await getallassetdatahooks(reqdata);
  if(res?.data?.success == true){
    setWalletData(res?.data?.record)
  }

  let data = res?.data?.record;
let opt = []
  data && data?.length > 0 && data?.map((val, ind) => {
    opt.push({ label : val?.symbol, value : val?.symbol})
  });
  
  setOptions(opt)
  


}
 

 
  
  useEffect(() =>{

    getAssetData()
      let orderid = window.location.pathname.split("/")[2].split("&")[7].split("=")[1]
      setoderid(orderid);
      let amount = window.location.pathname.split("/")[2].split("&")[24].split("=")[1]
     
      setamount(amount);
      let email = window.location.pathname.split("/")[2].split("&")[12].split("=")[1]
      setemail(decodeURIComponent(email));
      let currency = window.location.pathname.split("/")[2].split("&")[3].split("=")[1]
      setcurrency(currency)
      let successurl =  window.location.pathname.split("/")[2].split("&")[5].split("=")[1]
      let finalSurl =  decodeURIComponent(successurl)
      setsuccessurl(finalSurl)
      let canclesurl =  window.location.pathname.split("/")[2].split("&")[6].split("=")[1]
      let finalCurl =  decodeURIComponent(canclesurl)
      setcancleurl(finalCurl)
      let key = window.location.pathname.split("/")[2].split("&")[1].split("=")[1]
      setkey(key)
      convertprice(currency,amount);
     
      
    },[selectedOption])
    
const UsecreateOrder = async()=>{

  const data = {
    order :orderid,
    amount :convertedselectcurrency,
    currency : selectedOption?.value,
    email :email,
    key:key,
    successurl:successurl,
    cancelurl : cancleurl
  }
  
  var result = await useCreateOrder(data);

  setoutputurl(result?.url)
  if(result?.status === true){
    setdepositAddress(result?.data?.Address)

    toast.success(`${result.message}`, {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
  })

  }else{
    toast.error(`${result.message}`, {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
  })

  }
 


}


const convertprice = async(currency,amount)=>{

  
  let selectcurrencyvalue = await ConvertusdPrice(currency,selectedOption?.value);
  setconvertedselectcurrency(Object.values(selectcurrencyvalue)[0])
  let convertedUsdtvalue = await ConvertusdPrice(selectedOption?.value,"USD");
  let finalamount = parseFloat(Object.values(selectcurrencyvalue)[0]) * parseFloat(Object.values(convertedUsdtvalue)[0])
  setconvertedUsdtprice(finalamount)
  


  

}



  return (
    <>
      <section className="paddingY walletSection">
        <div className="wallet_top_container position-relative">
          <div className="container">
            <div className="pb-5">
              <p className="titleP">Payment Gateway</p>
              <p className="subtitleP">
                Secure <span>TRANSACTION</span>{" "}
              </p>

            </div>
          </div>
        </div>
        <div className="tabContainerFluid">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9} xxl={8}>
                <Card>
                  <Card.Body className="p-3 p-sm-4 p-lg-5">
                    <Row>
                      <Col xs={4} sm={2} md={3}>
                        <div className="pm_cart_wrap">
                          <img
                            src={cartIcon}
                            alt="product"
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                      <Col xs={8} sm={5} md={5} className="">
                        <div>
                          <h6 className="pm_title">Product ID</h6>
                          <p className="pm_subtle mb-2">{orderid}</p>
                         
                        </div>
                        <div className="mt-3">
                          <h6 className="pm_title ">Payment Amount</h6>
                          <p className="pm_subtle mb-2">{amount} {currency}</p>
                        </div>
                        {selectedOption?.value == undefined ? <></> : 
                        <div className="mt-3">
                          <h6 className="pm_title ">Converted Amount </h6>
                          <p className="pm_subtle mb-2">{convertedselectcurrency} {selectedOption?.value}</p>
                          <h6 className="pm_highlights">$ {convertedUsdtprice}</h6>

                         
                        </div> }
                      </Col>
                      
                      <Col xs={12} sm={5} md={4} className="mt-3 mt-sm-0 pay_with_tetx_margin">
                        <div>
                          <h6 className="pm_title">Pay with</h6>
                           <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    styles={stylesgraybg}
                    className="react_select"
                    // menuIsOpen={true}
                  />
                          {/* <p className="pm_subtle mb-2">{currency}</p> */}
                        
                        </div>
                      </Col>
                    </Row>
                    <div className="text-right">
                    <Button
                            className="accept-crypto mt-2 new_common_btn pm_btn float-right"
                            onClick={() => UsecreateOrder()}
                          >
                            Pay Now
                          </Button>
                          </div>
                  </Card.Body>
                  
                </Card>
              <div className={outputurl == "" ? "blur_sec" : ""}>
                <h4 className="pm_highlights mt-5">Confirm Payment</h4>
                <Card>
                  <Card.Body className="p-3 p-sm-4 p-lg-5">
                    <Row className="align-items-sm-center">
                      <Col
                        xs={12}
                        sm={6}
                        md={5}
                        xl={4}
                        className="d-flex justify-content-center"
                      >
                        <div className="pm_qr_wrp">
                       
                           <QRCode size="200" value={depositAddress} />

                        </div>
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        md={7}
                        xl={8}
                        className="mt-4 mt-sm-0"
                      >
                        <div>
                          <h6 className="pm_title">{convertedselectcurrency} {selectedOption?.value}</h6>
                          <h6 className="pm_highlights">$ {convertedUsdtprice}</h6>
                        
                          <Link to={outputurl}
                          target="blank"
                            className="accept-crypto mt-2 new_common_btn pm_btn link_btn_width"
                            onClick={() => setSuccessShow(true)}
                          >
                            Check Status
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <PaymentSuccess
        successShow={successShow}
        setSuccessShow={setSuccessShow}
      />
      <PaymentFailed failedShow={failedShow} setFailedShow={setFailedShow} />
    </>
  );
};

export default PaymentGateway;
