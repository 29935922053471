import React, { useState } from "react";
import "../../Styles/Fees.css";
import userCollection from "../../asset/IMG/fees-headsec-img.png";
import businessimg from "../../asset/IMG/Fees/business1.png";
import solarwallet from "../../asset/IMG/Fees/solar_wallet-money-bold-duotone.png";
import aspCloud from "../../asset/IMG/Fees/aspCloud.png";
import coin from "../../asset/IMG/Fees/ph_coins-fill.png";
import faSolid from "../../asset/IMG/Fees/fa-solid_coins.png";
import nightlyCloud from "../../asset/IMG/Fees/nightlyCloud.png";
import icon from "../../asset/IMG/Fees/icon.png";
import searchicon from "../../asset/IMG/Fees/icon-park-outline_search.png";
import btnBg from "../../asset/IMG/Fees/btn.png";
import { FaFilter } from "react-icons/fa";
import vectorimg from "../../asset/IMG/Fees/Vector.png";
import cryptocurrency from "../../asset/IMG/Fees/cryptocurrency-color_btc.png";
import dollar from "../../asset/IMG/Fees/dollar.png";
import polygon1 from "../../asset/IMG/Fees/Polygon 1(1).png";
import polygon2 from "../../asset/IMG/Fees/Polygon 2.png";
import star from "../../asset/IMG/Fees/Star 1(1).png";
import line from "../../asset/IMG/Fees/Group 3(1).png";
// import headSecline from "../../asset/IMG/Fees/line_logo.png";
import headSecline from "../../asset/IMG/Fees/line_logo.png";
import bitcollectionBg from "../../asset/IMG/Fees/Group 109(1).png";
import eclipseicon from "../../asset/IMG/Fees/Ellipse .png";

import shortline from "../../asset/IMG/Fees/left-top-icon.png";
import bigline from "../../asset/IMG/Fees/big-line-bar.png";
import Footer from "../Layout/Footer";
import depositBg from "../../asset/IMG/Fees/deposit-bg.png";
import conversionnBg from "../../asset/IMG/Fees/conversionlogo.png";
function Fees() {
  const [wallet, setWallet] = useState(true);

  const handleCheck = (e) => {
    setWallet(e.target.checked);
  };

  return (
    <>
      <div className="fees-page">
        <div
          className="head-section position-relative fees_padding_top"
          style={{ overflowX: "clip" }}
        >
          {/* <div className="polygon_one z-1  position-absolute">
            <img src={polygon1} alt="" style={{ opacity: "0.3" }} />
          </div> */}
          {/* <div className="star-img d-none d-lg-block">
            <img src={star} alt="" style={{ opacity: "0.3" }} />
          </div> */}
          <div className="line-img d-none d-lg-block">
            <img src={headSecline} alt="" style={{ opacity: "0.3" }} />
          </div>
          <div className="bitcollection-img">
            <img src={bitcollectionBg} alt="s" style={{ opacity: "0.3" }} />
          </div>

          <div className="container position-relative">
            <div className="row">
              <div className="col-xl-7 col-lg-6 col-md-5 col-sm-12 d-flex align-items-center">
                <div className="first-sec">
                  <h2>
                    <span className="one">ONE</span> SIMPLE TRANSACTION FEE{" "}
                    <br></br> <span className="loreum">COINPAYMATE</span>
                  </h2>
                  <p>
                    * A 1% fee is charged on incoming token payments instead.
                    Fee adjustments may <br></br>apply to customers in high-risk
                    industries
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12">
                <div className="snd-section">
                  <img src={userCollection} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="incoming-payments position-relative py-5"
          style={{ overflowX: "clip" }}
        >
          <div className="container">
            <div className="d-flex justify-content-between">
              <div>
                <h2>
                  <span className="incoming">
                    {wallet ? "INCOMING" : "WALLET"}
                  </span>{" "}
                  {wallet ? "PAYMENTS" : "SERVICE"}
                </h2>
                <p>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <p>Lorem ipsum has been industry</p>
              </div>

              <div className="custom-toggle-bar my-3">
                <label class="toggle">
                  <input
                    type="checkbox"
                    checked={wallet}
                    onClick={handleCheck}
                  />
                  <span className="slider"></span>
                  <span
                    className="labels"
                    data-on="WALLET"
                    data-off="PAYMENT"
                  ></span>
                </label>
              </div>
            </div>
            {wallet ? (
              <>
                <div className="mt-5">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-4">
                      <div className="to-balance-section position-relative overflow-hidden">
                        {/* <div className="top-left-logo position-absolute">
                          <img src={shortline} />
                        </div> */}
                        <div className="d-flex justify-content-between flex-column">
                          <div className="position-relative d-flex justify-content-center">
                            <div
                            // style={{ width: "250px", height: "200px" }}
                            >
                              <img
                                src={businessimg}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                            <div
                              className="position-absolute"
                              style={{ top: "41px" }}
                            >
                              <img src={solarwallet} />
                            </div>
                            <div
                              style={{ top: "0px", right: "72px" }}
                              className="position-absolute"
                            >
                              <img
                                src={eclipseicon}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </div>
                          </div>
                          <div className="">
                            <h4>TO BALANCE</h4>
                            <h6>0.5% FEE</h6>
                            <p>
                              Lorem ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem ipsum has been the
                              industry's standard dummy text ever since.
                            </p>
                          </div>
                        </div>
                        {/* <div className="bottom-right-logo position-absolute">
                          <img src={bigline} />
                        </div> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                      <div className="to-balance-section position-relative overflow-hidden">
                        {/* <div className="top-left-logo position-absolute">
                          <img src={shortline} />
                        </div> */}
                        <div className="d-flex justify-content-between flex-column">
                          <div className="mt-5">
                            <h4>ASAP</h4>
                            <h6>0.5% + NETWORK FEE</h6>
                            <p>
                              Lorem ipsum is simply dummy text of the printing
                              and typesetting industry.
                            </p>
                          </div>
                          <div className="position-relative d-flex justify-content-center">
                            <div
                            // style={{ width: "250px", height: "200px" }}
                            >
                              <img
                                src={aspCloud}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                            <div
                              className="position-absolute"
                              style={{ top: "80px" }}
                            >
                              <img src={coin} />
                            </div>
                          </div>
                        </div>
                        {/* <div className="bottom-right-logo position-absolute">
                          <img src={bigline} />
                        </div> */}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-4">
                      <div className="to-balance-section position-relative overflow-hidden">
                        {/* <div className="top-left-logo position-absolute">
                          <img src={shortline} />
                        </div> */}
                        <div className="d-flex justify-content-between flex-column">
                          <div className="position-relative d-flex justify-content-center">
                            <div
                            // style={{ width: "250px", height: "200px" }}
                            >
                              <img
                                src={nightlyCloud}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                            <div
                              className="position-absolute"
                              style={{ top: "105px" }}
                            >
                              <img src={faSolid} />
                            </div>
                          </div>

                          <div className="mt-5">
                            <h4>NIGHTLY</h4>
                            <h6>0.5% + NETWORK FEE</h6>
                            <p>
                              Lorem ipsum is simply dummy text of the printing
                              and
                            </p>
                          </div>
                        </div>
                        {/* <div className="bottom-right-logo position-absolute">
                          <img src={bigline} />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mt-5">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-3">
                      <div className="to-balance-section position-relative overflow-hidden">
                        {/* <div className="top-left-logo position-absolute">
                          <img src={shortline} />
                        </div> */}
                        <div className="d-flex justify-content-between flex-column">
                          <div className="position-relative d-flex justify-content-center">
                            <div
                              style={
                                {
                                  // width: "288px",
                                  // height: "186px",
                                  // objectFit: "contain",
                                }
                              }
                            >
                              <img
                                src={depositBg}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                            <div
                              style={{ top: "0px", right: "58px" }}
                              className="position-absolute"
                            >
                              <img
                                src={eclipseicon}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </div>
                          </div>
                          <div className="mt-5">
                            <h4>DEPOSITS</h4>
                            <h6>LOREUM</h6>
                            <p>
                              Lorem ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem ipsum has been the
                              industry's standard dummy text ever since.
                            </p>
                          </div>
                        </div>
                        {/* <div className="bottom-right-logo position-absolute">
                          <img src={bigline} />
                        </div> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                      <div className="to-balance-section position-relative overflow-hidden">
                        {/* <div className="top-left-logo position-absolute">
                          <img src={shortline} />
                        </div> */}
                        <div className="d-flex justify-content-between flex-column">
                          <div className="mt-5">
                            <h4>CONVERSIONS</h4>
                            <h6>LOREUM IPSUM OGUIT</h6>
                            <p>
                              Lorem ipsum is simply dummy text of the printing
                              and typesetting industry.
                            </p>
                          </div>
                          <div className="position-relative d-flex justify-content-center">
                            <div
                              style={
                                {
                                  // width: "280px",
                                  // height: "230px",
                                  // objectFit: "contain",
                                }
                              }
                            >
                              <img
                                src={conversionnBg}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="bottom-right-logo position-absolute">
                          <img src={bigline} />
                        </div> */}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-3">
                      <div className="to-balance-section position-relative overflow-hidden">
                        {/* <div className="top-left-logo position-absolute">
                          <img src={shortline} />
                        </div> */}
                        <div className="d-flex justify-content-between flex-column">
                          <div className="position-relative d-flex justify-content-center">
                            <div
                            // style={{ width: "250px", height: "200px" }}
                            >
                              <img
                                src={nightlyCloud}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                            <div
                              className="position-absolute"
                              style={{ top: "105px" }}
                            >
                              <img src={faSolid} />
                            </div>
                          </div>

                          <div className="mt-5">
                            <h4>WITHDRAWALS</h4>
                            <h6>0.5% + NETWORK FEE</h6>
                            <p>
                              Lorem ipsum is simply dummy text of the printing
                              and
                            </p>
                          </div>
                        </div>
                        {/* <div className="bottom-right-logo position-absolute">
                          <img src={bigline} />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <div className="line-img">
            <img src={line} alt="" />
          </div> */}
          {/* <div className="incoming-polycon-snd-img">
            <img src={polygon2} />
          </div> */}
        </div>

        <div className="about-networking position-relative py-5">
          {/* <div className="star-img">
            <img src={star} />
          </div> */}
          {/* <div className="star-img-big">
            <img src={star} />
          </div> */}
          {/* <div className="polycon-snd-img">
            <img src={polygon2} />
          </div> */}

          {/* <div className="line-img">
            <img src={line} />
          </div> */}

          {/* <div className="polycon-fst-img-about z-1 d-none d-lg-block">
            <img src={polygon1} />
          </div> */}

          <div className="container position-relative z-2">
            <div className="head-sec position-relative">
              <h2>
                <span className="about">ABOUT</span> NETWORK FEES
              </h2>
              <p>
                Lorem ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <p>Lorem ipsum has been the industry</p>
            </div>

            <div className="filter-sec position-relative my-5">
              <div className="d-flex align-items-center">
                <div className="img-section">
                  <img src={icon} />
                </div>
                <h4 className="m-0 ps-3">LOREUM IPSUM</h4>
              </div>
              <div className="d-flex filter-box-search">
                <div className="filter-search position-relative">
                  <div className="input-filter">
                    <input
                      type="text"
                      className="form-control search"
                      placeholder="FILTER COIN NAME"
                    />
                  </div>
                  <div className="search-icon">
                    <img src={searchicon} />
                  </div>
                </div>
                <div class="dropdown ms-4">
                  <button
                    type="button"
                    class="btn commonBtn btn-primary dropdown-toggle d-flex align-items-center"
                    data-bs-toggle="dropdown"
                  >
                    <FaFilter /> <span className="ps-1">FILTER COINS</span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#">
                        Link 1
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="balance-section">
              <div className="fst-sec d-flex mb-4 position-relative overflow-hidden">
                {/* <div className="top-left-logo position-absolute">
                    <img src={shortline} />
                  </div> */}
                <div className="fst-col d-flex align-items-center justify-content-center p-4">
                  <div className="img-div">
                    <div className="coin">
                      <div className="coin-img-div">
                        <img src={cryptocurrency} />
                      </div>
                    </div>
                    <h5 className="m-0 bitcoin">BITCOIN</h5>
                  </div>
                </div>

                <div className="snd-col text-center">
                  <p className="p-3">CODE</p>
                  <hr className="m-0 p-0"></hr>
                  <h6 className="m-0 p-3">BTC</h6>
                </div>

                <div className="third-col text-center">
                  <p className="p-3">BALANCE</p>
                  <hr className="m-0 p-0"></hr>
                  <h6 className="m-0 p-3 d-flex align-items-center justify-content-center">
                    <img src={dollar} />
                    <span className="ps-2">0.00040000</span>
                  </h6>
                </div>
                {/* <div className="bottom-right-logo position-absolute">
                  <img src={bigline} />
                </div> */}
              </div>
              <div className="fst-sec d-flex position-relative overflow-hidden mb-4">
                {/* <div className="top-left-logo position-absolute">
                  <img src={shortline} />
                </div> */}
                <div className="fst-col d-flex align-items-center justify-content-center p-4">
                  <div className="img-div">
                    <div className="coin">
                      <div className="coin-img-div">
                        <img src={cryptocurrency} />
                      </div>
                    </div>
                    <h5 className="m-0 bitcoin">BITCOIN</h5>
                  </div>
                </div>

                <div className="snd-col text-center">
                  <p className="p-3">CODE</p>
                  <hr className="m-0 p-0"></hr>
                  <h6 className="m-0 p-3">BTC</h6>
                </div>

                <div className="third-col text-center">
                  <p className="p-3">BALANCE</p>
                  <hr className="m-0 p-0"></hr>
                  <h6 className="m-0 p-3 d-flex align-items-center justify-content-center">
                    <img src={dollar} alt="" />
                    <span className="ps-2">0.00040000</span>
                  </h6>
                </div>
                {/* <div className="bottom-right-logo position-absolute">
                  <img src={bigline} alt="" />
                </div> */}
              </div>
              <div className="fst-sec d-flex position-relative overflow-hidden mb-4">
                {/* <div className="top-left-logo position-absolute">
                  <img src={shortline} alt="" />
                </div> */}
                <div className="fst-col d-flex align-items-center justify-content-center p-4">
                  <div className="img-div">
                    <div className="coin">
                      <div className="coin-img-div">
                        <img src={cryptocurrency} alt="" />
                      </div>
                    </div>
                    <h5 className="m-0 bitcoin">BITCOIN</h5>
                  </div>
                </div>

                <div className="snd-col text-center">
                  <p className="p-3">CODE</p>
                  <hr className="m-0 p-0"></hr>
                  <h6 className="m-0 p-3">BTC</h6>
                </div>

                <div className="third-col text-center">
                  <p className="p-3">BALANCE</p>
                  <hr className="m-0 p-0"></hr>
                  <h6 className="m-0 p-3 d-flex align-items-center justify-content-center">
                    <img src={dollar} alt="" />
                    <span className="ps-2">0.00040000</span>
                  </h6>
                </div>
                {/* <div className="bottom-right-logo position-absolute">
                  <img src={bigline} alt="" />
                </div> */}
              </div>
            </div>
          </div>

          {/* <div className="line-img-left-bottom">
            <img src={line} />
          </div> */}
          {/* <div className="line-img-right-bottom">
            <img src={line} />
          </div> */}
        </div>

        <div className="footer-section overflow-hidden position-relative text-center py-5">
          {/* <div className="polycon-fst-img">
            <img src={polygon1} alt="" />
          </div> */}
          {/* <div className="star-img">
            <img src={star} alt="" />
          </div> */}

          {/* <div className="polycon-snd-img">
            <img src={polygon2} alt="" />
          </div> */}

          {/* <div className="line-img">
            <img src={line} alt="" />
          </div> */}

          {/* <div className="line-bottom-img">
            <img src={line} alt="" />
          </div> */}

          <div className="container position-relative">
            <h2>
              <span>LOREM</span> IPSUM IS SIMPLY
            </h2>
            <p>Start your first trade with these easy steps.</p>
            <div className="d-flex justify-content-center get-started-btn-sec">
              <button className="commonBtn">
                <img src={vectorimg} alt="" />{" "}
                <span className="ms-2">GET STARTED</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Fees;
