import React, { useState } from "react";
import "../../Styles/ForgotPassword.css";
import { BiHide, BiShow } from "react-icons/bi";
import { requestemailhooks } from "../../hooks/UseUser";
function ForgotPassword() {
  const [showIcon, setShowIcon] = useState(false);
  const [username, setUserName] = useState("");
  const [error, setError] = useState("")

  const forgotPassword = async () => {
    if (username != "") {
      let reqData = { user_name: username?.toLowerCase(), filter : "forgotpassword"};
      let res = await requestemailhooks(reqData);
      setError({})
      if (res?.data?.success == "success") {
        setError(res?.data?.errors);
      }
    } else {
      setError({ username: "UserName is required" });
    }


  }
  return (
    <div className="forgot-password-page">
      <div className="container second_div">
        <div className="row">
          <div className="col-lg-6 col-md-6 p-0 border-sec-left">
            <div className="forgot-section my-3 p-4">
              <h2>
                <span className="forgotten">FORGOTTEN</span> ACCOUNT
              </h2>
              <p>
                LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE{" "}
                <span className="printing">PRINTING</span>
              </p>
            </div>

            <div className="forgot-section-text mt-3 ps-4 pe-4 pb-4">
              <p>
                Lorem ipsum is simply dummy text of the printing and typesetting
                industry, Lorem ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled.
              </p>
              <p className="mt-3">
                Lorem ipsum is simply dummy text of the printing and typesetting
                industry, Lorem ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 border-sec">
            <div className="row mb-3 pt-3 mt-5">
              <label for="inputusername3" className="col-sm-4 col-form-label">
                USERNAME
              </label>
              <div className="col-sm-8">
                <input
                  type="email"
                  className="form-control custom-input"
                  id="inputusername3"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <span className="text-danger">{error?.username}</span>
            </div>
            {/* <div className="row mb-3 mt-4 pt-3 mt-md-5">
              <label for="inputPassword3" className="col-sm-4 col-form-label">
                PASSWORD
              </label>
              <div className="col-sm-8">
                <div className="password_show_hide">
                  <input
                    type={showIcon ? "text" : "password"}
                    className="form-control custom-input"
                    id="inputPassword3"
                  />
                  <div className="icon_sec">
                    {showIcon ? (
                      // <BiSolidShow onClick={() => setShowIcon(!showIcon)} />
                      <BiShow onClick={() => setShowIcon(!showIcon)} />
                    ) : (
                      // <BiSolidHide onClick={() => setShowIcon(!showIcon)} />
                      <BiHide onClick={() => setShowIcon(!showIcon)} />
                    )}
                  </div>
                </div>
              </div>
            </div> */}

            <div className="login-sec d-flex justify-content-end align-items-center">
              {/* <div className="login-btn">Login</div> */}
              <div className="commonBtn">
                <button onClick={() => forgotPassword()}
                  className="z-2"
                  style={{
                    cursor: "pointer",
                    textTransform: "none",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
