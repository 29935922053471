import React, { useEffect, useState } from "react";
import "../../../../Styles/Dashboard.css";
import icon from "../../../../asset/IMG/Dashboard/icon.png";
import { FaFilter } from "react-icons/fa";
import searchicon from "../../../../asset/IMG/Dashboard/icon-park-outline_search.png";
import bitcoin from "../../../../asset/IMG/Dashboard/cryptocurrency-color_btc.png";
import detaillogo from "../../../../asset/IMG/Dashboard/support-coins-detail-logo.png";

import transactionlogo from "../../../../asset/IMG/Dashboard/Group 61.png";
import receivedlogo from "../../../../asset/IMG/Dashboard/cryptocurrency-color_eth.png";
import cnfmlogo from "../../../../asset/IMG/Dashboard/flat-color-icons_ok.png";
import convertlogo from "../../../../asset/IMG/Dashboard/Group.png";

import binance from "../../../../asset/IMG/Dashboard/binance.png";
import tether from "../../../../asset/IMG/Dashboard/tether.png";
import shortline from "../../../../asset/IMG/Dashboard/secondbox-lite-top.png";
import bigline from "../../../../asset/IMG/Dashboard/left-top-icon.png";
import Dropdown from "react-bootstrap/Dropdown";
import SettingLogo from "../../../../asset/IMG/Dashboard/setting.png";
import { getuserdatabytoken, getuserwalletdetails } from "../../../../hooks/UseUser";
import { BACKURL } from "../../../../config/env";
import { UseCoinAcceptance } from "../../../../hooks/UseBackend";

function CoinAcceptance() {

const [userToken,setUserToken] = useState("")
const [id, setId] = useState("");
const [walletData, setWalletData] = useState("");
const [searchData, setSearchData] = useState("");
const [currency, setcurrency] = useState("");
const [status, setstatus] = useState("Enabled");
const [maxValuePerTx, setmaxValuePerTx] = useState("");
const [payoutMode, setpayoutMode] = useState("");
const [paymentAddress, setpaymentAddress] = useState("");
const [discount, setdiscount] = useState("");
const [valueCurrency, setvalueCurrency] = useState("");




  useEffect(() => {
    let UserToken = sessionStorage.getItem("accessToken");
    setUserToken(UserToken);
    if (
      UserToken != "" &&
      UserToken != null &&
      UserToken != undefined &&
      UserToken != "undefined"
    ) {
      getData(UserToken);
      // getUserData(UserToken)
    }
  }, [userToken]);



  
  const getData = async (token) => {
    try {
      let reqdata = { token: token, filter: "userIddata" };
      let res = await getuserdatabytoken(reqdata);
     
      if (res?.data?.success == true) {
        let data = res?.data?.record;
        
        setId(data?._id);
      }
    } catch (e) {
      console.log("getData_err", e);
    }
  };


  useEffect(() => {
   
    async function fetchData() {
     
      if (searchData == "") {
        let reqData = { _id: id, search: "" };
        if (id != "" && searchData == "") {
          let res = await getuserwalletdetails(reqData);
          
       
          let rec = res?.data?.records
          setWalletData(rec);
         
          console.log("serachdafasdfasd", res?.data?.records);
      }
      }
    }
    fetchData();
  }, [id])


 

  const onSubmit = async () => {

    const Data = {
      currency: currency,
      status: status,
      payoutMode: payoutMode,
      paymentAddress: paymentAddress,
      discount : discount,
      maxValuePerTx : maxValuePerTx,
      valueCurrency: valueCurrency
    };


 
 await UseCoinAcceptance(Data)
}

  return (
    <div className="coin-acceptance">
      <div className="recent-sections">
        <div className="filter-sec position-relative my-5">
          <div className="d-flex align-items-center">
            <div className="img-section">
              <img src={icon} />
            </div>
            <h4 className="m-0 ps-3">COINS</h4>
          </div>
         
        </div>

        {walletData && walletData.map((item) => {
          return (
            <>
              <div className="recent-table overflow-hidden mb-4">
                <div class="table-responsive ">
                  <table
                    class="table m-0 p-0 position-relative overflow-hidden table-line"
                    style={{ height: "110px" }}
                  >
                  
                    <tr className="">
                      <td rowspan="2" className="bdr-right text-white">
                        <div className="fst-sec-border">
                          <div className="d-flex align-items-center justify-content-center bitcoin-bg">
                            <div className="coin-img">
                              <img   src={`${BACKURL}/Images/${item?.logoURI}`} />
                            </div>
                            <div className="coin-txt">
                              <p className="coin-name-title">COIN NAME</p>
                              <p className="coin-name">{item?.symbol}</p>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="head-title bdr-right bdr-head-btm">
                        YOUR PAYMENT ADDRESS
                      </td>
                      <td className="head-title bdr-right bdr-head-btm">
                        PAYOUT MODE
                      </td>
                      <td className="head-title bdr-right bdr-head-btm">
                        DISCOUNT
                      </td>
                      <td className="head-title bdr-right bdr-head-btm z-2 position-relative">
                        MAXIMUM VALUE PER TRANSACTION
                      </td>
                      <td className="head-title bdr-head-btm z-2 position-relative">
                        ACTION
                      </td>
                    </tr>
                    <tr>
                      <td className="bdr-right px-3">
                        <div className="py-2">
                          <input  type="text"  onChange={(e) => {  setpaymentAddress(e.target.value) }} class="form-control" />
                        </div>
                      </td>
                      <td className="bdr-right px-3">
                        <Dropdown className="py-2">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="w-100 d-flex justify-content-between align-items-center custom-drop-btn"
                          >
                            {payoutMode == "" ? "Select"  : payoutMode}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-100 custom-coin-acceptance-drop">
                            <Dropdown.Item eventKey="To BALANCE"  onClick={()=>setpayoutMode("To BALANCE")} >
                              To BALANCE
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="ASAP" onClick={() => setpayoutMode("ASAP")}>
                             ASAP
                            </Dropdown.Item>
                            
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td className="bdr-right px-3">
                        <div className="py-2">
                          <input
                            type="text"
                            class="form-control percentage-placeholder"
                            placeholder="0 %"
                            onChange={(e) => {  setdiscount(e.target.value) }} 
                          />
                        </div>
                      </td>
                      <td className="bdr-right px-3 position-relative ">

                      <div className="py-2">
                          <input
                            type="text"
                            class="form-control percentage-placeholder"
                            placeholder="0"
                            onChange={(e) => {  setmaxValuePerTx(e.target.value) }} 
                          />
                        </div>
                        <Dropdown className="py-2 z-2">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="w-100 custom-drop-btn d-flex justify-content-between align-items-center"
                            style={{ height: "40px" }}
                          >
                             {valueCurrency == "" ? "Select"  : valueCurrency} 
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-100 custom-coin-acceptance-drop">
                            <Dropdown.Item onClick={()=> setvalueCurrency("USD")}>
                              USD
                            </Dropdown.Item>
                            <Dropdown.Item onClick={()=> setvalueCurrency("BTC")}>
                              BTC
                            </Dropdown.Item>
                           
                          </Dropdown.Menu>
                        </Dropdown>
                      
                      </td>
                      <td className="bdr-righ px-3 position-relative ">
                        <div className="d-flex align-items-center justify-content-center my-5">
                          <button className="new_btn" onClick={()=>onSubmit()}>
                            Update Coin Preferences
                          </button>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </>
          );
        })}
      </div>
      {/* <div className="d-flex align-items-center justify-content-center my-5">
        <button className="new_btn">Update Coin Preferences</button>
      </div> */}
    </div>
  );
}

export default CoinAcceptance;
