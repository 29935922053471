import React, { useEffect, useState } from "react";
import "../../Styles/Landing.css";
import Slider from "react-slick";
import Crypto from "../../asset/IMG/NewLanding/Home_Crypto.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Coindesk from "../../asset/IMG/NewLanding/coindesk.png";
import Reuters from "../../asset/IMG/NewLanding/reuters.png";
import Yahoo from "../../asset/IMG/NewLanding/yahoo.png";
import Cointelegraph from "../../asset/IMG/NewLanding/cointelegraph.png";
import Forbes from "../../asset/IMG/NewLanding/forbes.png";
import MultiCoin from "../../asset/IMG/NewLanding/multi-coin.png";
import GlobalPayments from "../../asset/IMG/NewLanding/global-payment.png";
import AutoCoin from "../../asset/IMG/NewLanding/forbes.png";
import CryptocurrencyVault from "../../asset/IMG/NewLanding/cryptocurrency_vault.png";
import UserLogo from "../../asset/IMG/NewLanding/userLogo.png";
import {  useNavigate } from "react-router-dom";
// import UserLogo from "../../asset/IMG/NewLanding/userBg.png";
import OtpInput from "react-otp-input";
import Magento from "../../asset/IMG/NewLanding/Magento.png";
import Shopify from "../../asset/IMG/NewLanding/shopify.png";
import Woocommerce from "../../asset/IMG/NewLanding/woocommerce.png";
import Opencart from "../../asset/IMG/NewLanding/opencart.png";
import {  useParams } from "react-router-dom";
import { BsCheck } from "react-icons/bs";
import Footer from "../Layout/Footer";


import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import QrCodeImg from "../../asset/IMG/qrcode.png";
import GetDemoModal from "./GetDemoModal";
import { getuserdetailsbyname, getusermerchantId } from "../../hooks/UseUser";
import { useDispatch } from "react-redux";
import { SET_SHOW_HEADER } from "../../redux/action";
function LandingPage() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showpage, setShowPage] = useState(true);
  console.log("showpagesta", showpage)
  let { merchantId } = useParams();
  let localstorage = localStorage.getItem('merchantId')
  console.log("merchantIddata", merchantId, localstorage)
  let dispatch = useDispatch();
let navigate = useNavigate()

  useEffect(() => {
    async function fetchdata() {
      if (merchantId != "" && merchantId != undefined) {
        let res = await getusermerchantId(merchantId);
        console.log("respond_data", res);
        if (res?.data?.success == true) {
          localStorage.setItem("merchantId", merchantId)
          dispatch({ type : SET_SHOW_HEADER , payload : true})
          setShowPage(true)
        } else {
          if (res?.data?.success == false) {
            dispatch({ type : SET_SHOW_HEADER , payload : false})
            setShowPage(false)
          }
        }
      }
    }
    fetchdata()
  }, [])

  useEffect(() => {
    if(localstorage != "" && localstorage != null && localstorage != "undefined" && localstorage != undefined) {
      navigate(`/${localstorage}`)
    }
  }, [localstorage])


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const userContent = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const UserInformation = [
    {
      name: "Coin Market",
      role: "Manager",
      message:
        "“I can’t imagine it could get much easier to manage incoming payments as a merchant - especially with features like auto conversions for incoming payments which is super handy for accounting purposes.”",
      logo: UserLogo,
    },
    {
      name: "Coin Market",
      role: "Manager",
      message:
        "“I can’t imagine it could get much easier to manage incoming payments as a merchant - especially with features like auto conversions for incoming payments which is super handy for accounting purposes.”",
      logo: UserLogo,
    },
    {
      name: "Coin Market",
      role: "Manager",
      message:
        "“I can’t imagine it could get much easier to manage incoming payments as a merchant - especially with features like auto conversions for incoming payments which is super handy for accounting purposes.”",
      logo: UserLogo,
    },
  ];

  const ManageCrypto = [
    {
      icon: MultiCoin,
      name: "Multi-Coin Wallet",
      content: "One wallet. 2,315+ coins. Countless features on the go.",
    },
    {
      icon: GlobalPayments,
      name: "Global Payments",
      content:
        "Grow your business globally with borderless, instant and low-cost crypto transactions.",
    },
    {
      icon: MultiCoin,
      name: "Auto Coin Conversion*",
      content: "Avoid volatility by automatically converting coins.",
    },
    {
      icon: CryptocurrencyVault,
      name: "Cryptocurrency Vault*",
      content:
        "Safeguard your coins in our vault and lock them for as long as you want.",
    },
    {
      icon: MultiCoin,
      name: "Multi-Coin Wallet",
      content: "One wallet. 2,315+ coins. Countless features on the go.",
    },
    {
      icon: GlobalPayments,
      name: "Global Payments",
      content:
        "Grow your business globally with borderless, instant and low-cost crypto transactions.",
    },
  ];

  const options = {
    loop: true,
    // center: true,
    items: 4,
    margin: 10,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      800: {
        items: 3,
      },
      1000: {
        items: 3,
      },
      1200: {
        items: 3,
      },
      1400: {
        items: 4,
      },
    },
  };

  const optionss = {
    loop: true,
    // center: true,
    items: 1,
    margin: 10,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 1,
      },
      450: {
        items: 1,
      },

      1000: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  return (
    <>

      {showpage ? <div className="landing-page">
        <div className="home-section">
          <div className="container sec-pad">
            <div className="row m-0">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="crypto-payment-img">
                  <img src={Crypto} alt="crypto-img" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-end">
                <div className="home-content ps-2 ps-lg-5 ps-xl-5 ps-md-5">
                  <h3>
                    CRYPTO PAYMENTS <br />
                    <span className="made-easy">MADE EASY</span>
                  </h3>
                  <p className="my-3">
                    Take advantage of our global crypto payment gateway made
                    easy and accessible for everyone — whether you’re a business
                    owner, crypto user, or even from another planet.
                  </p>
                  <div className="btn-sec d-flex">
                    <button className="get-started new_common_btn">
                      GET STARTED
                    </button>
                    <button
                      className="get-demo new_common_btn_hvr ms-4"
                      onClick={handleShow}
                    >
                      GET DEMO
                    </button>
                  </div>
                  <div className="users-count d-flex mt-2 mt-xl-5 mt-lg-5 justify-content-center align-items-start mt-md-5 mt-sm-5 gap-2 gap-sm-2 gap-md-5 gap-lg-5 gap-xl-5">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <h5 className="m-0">$10B+</h5>
                      <p>Over $10 Billion In Crypto Payments Since 2013</p>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <h5 className="m-0">100K+</h5>
                      <p>Serving 100,000+ Merchants Globally</p>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <h5 className="m-0">190+</h5>
                      <p>Active Worldwide In 190+ Countries</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="you-deserve-easy">
          <div className="container sec-pad">
            <div className="">
              <h4 className="common_heading text-center">
                You Deserve Easy Access to Crypto Payments Gateway
              </h4>
              <p className="crypto common_heading_des text-center">
                Crypto payment gateway is for you if you want to
              </p>
            </div>
            <div className="row mt-3 mx-0 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5 px-2">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4 mb-sm-4 mb-md-0 mb-lg-0 mb-xl-0">
                <div className="business-sec px-5 py-5">
                  <div>
                    <h6 className="common_heading_small">Business</h6>
                    <p className="serving common_heading_des text-start">
                      Serving 100,000 + merchants globally
                    </p>
                  </div>
                  <div>
                    <div className="d-flex align-items-center my-4">
                      <span className="check_icon">
                        <BsCheck />
                      </span>
                      <p className="common_heading_des ps-2 bus_content">
                        Real-time gobal payments
                      </p>
                    </div>
                    <div className="d-flex align-items-center my-4">
                      <span className="check_icon">
                        <BsCheck />
                      </span>
                      <p className="common_heading_des ps-2 bus_content">
                        Industry-low processing fees of only 0.5%
                      </p>
                    </div>
                    <div className="d-flex align-items-center my-4">
                      <span className="check_icon">
                        <BsCheck />
                      </span>
                      <p className="common_heading_des ps-2 bus_content">
                        Reduced fraud risk with no chargebacks
                      </p>
                    </div>
                    <div className="d-flex align-items-center my-4">
                      <span className="check_icon">
                        <BsCheck />
                      </span>
                      <p className="common_heading_des ps-2 bus_content">
                        Fiat conversion options
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    {" "}
                    <button className="accept-crypto mt-4 new_common_btn">
                      Accept crypto now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="business-sec px-5 py-5">
                  <div>
                    <h6 className="common_heading_small">Personal</h6>
                    <p className="serving common_heading_des text-">
                      1,000,000+ Wallet users love these features
                    </p>
                  </div>
                  <div>
                    <div className="d-flex align-items-center my-4">
                      <span className="check_icon">
                        <BsCheck />
                      </span>
                      <p className="common_heading_des ps-2 bus_content">
                        Hold 2,315+ cryptocurrencies on one platform
                      </p>
                    </div>
                    <div className="d-flex align-items-center my-4">
                      <span className="check_icon">
                        <BsCheck />
                      </span>
                      <p className="common_heading_des ps-2 bus_content">
                        Convert your crypto
                      </p>
                    </div>
                    <div className="d-flex align-items-center my-4">
                      <span className="check_icon">
                        <BsCheck />
                      </span>
                      <p className="common_heading_des ps-2 bus_content">
                        Purchase gift cards
                      </p>
                    </div>
                    <div className="d-flex align-items-center my-4">
                      <span className="check_icon">
                        <BsCheck />
                      </span>
                      <p className="common_heading_des ps-2 bus_content">
                        All services provided by UAB Star Ventures or its
                        partners
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <button className="accept-crypto mt-4 new_common_btn">
                      Set Up Your Free Wallet
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="crypto_payments_partners">
          <div className="container sec-pad">
            <div className="">
              <h4 className="common_heading text-center">
                The World's Most Trusted Crypto Payments Partner
              </h4>
              <p className="common_heading_des text-center">
                Take advantage of our global crypto payment gateway made easy
                and accessible for everyone
              </p>
            </div>
            <div className="pt-5 d-flex justify-content-center row m-0">
              <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 mb-3">
                <div className="crypto_partner_logo">
                  <img src={Coindesk} alt="crypto_Partner_logo" />
                </div>
              </div>
              <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 mb-3">
                <div className="crypto_partner_logo">
                  <img src={Reuters} alt="crypto_Partner_logo" />
                </div>
              </div>
              <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 mb-3">
                <div className="crypto_partner_logo">
                  <img src={Yahoo} alt="crypto_Partner_logo" />
                </div>
              </div>
              <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 mb-3">
                <div className="crypto_partner_logo">
                  <img src={Cointelegraph} alt="crypto_Partner_logo" />
                </div>
              </div>
              <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 mb-3">
                <div className="crypto_partner_logo">
                  <img src={Forbes} alt="crypto_Partner_logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="manage-crypto">
          <div className="container sec-pad">
            <div className="head-sec text-center">
              <h4 className="common_heading">Manage Crypto Like a Pro</h4>
              <p className="common_heading_des mb-3">
                Take advantage of our global crypto payment gateway made easy
                and accessible for everyone
              </p>
            </div>
            <div className="slide-section mt-5">
              {/* <Slider {...settings}> */}

              <OwlCarousel
                className="owl-theme"
                // items={4}
                // loop
                // margin={10}
                // nav
                {...options}
              >
                {ManageCrypto.map((item) => {
                  return (
                    <>
                      <div className="content-sec p-4 mx-2">
                        <div className="img-sec">
                          <img
                            src={item.icon}
                            alt="manage-crypto"
                            className="conten-sec-img"
                          />
                        </div>
                        <h6 className="mt-4 mb-2 content-sec-name">
                          {item.name}
                        </h6>
                        <p className="content-sec-content">{item.content}</p>
                      </div>
                    </>
                  );
                })}
              </OwlCarousel>

              {/* </Slider> */}
            </div>

            <div className="mt-3 mt-sm-3 mt-md-4 mt-lg-5 mt-xl-5 d-flex justify-content-center">
              <button className="manage-get-started">GET STARTED</button>
            </div>
          </div>
        </div>
        <div className="user-content">
          <div className="container sec-pad">
            <div className="row d-flex justify-content-center m-0">
              <div className="col-lg-5 col-md-7 col-sm-8 col-8">
                {/* <Slider {...userContent}> */}
                <OwlCarousel
                  className="owl-theme"
                  // items={1}
                  // loop
                  // margin={10}
                  // nav
                  {...optionss}
                >
                  {UserInformation.map((item) => {
                    return (
                      <>
                        <div className="user-content-bg">
                          <p className="message">{item.message}</p>
                          <div className="user-info mt-4 d-flex flex-column justify-content-center align-items-center">
                            <img src={item.logo} alt="user-logo" />
                            <h6 className="mt-3">{item.name}</h6>
                            <h6>{item.role}</h6>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {/* </Slider> */}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
        <div className="crypto_payments">
          <div className="container sec-pad">
            <div className="">
              <h4 className="common_heading text-center">
                Start Accepting Crypto Payments
              </h4>
              <p className="common_heading_des text-center">
                Set up CryptoPayMate seamlessly on the most popular Ecommerce
                platforms.
              </p>
            </div>
            <div className="row my-5 p-0 mx-0 d-flex justify-content-center">
              <div className="col-lg-3 col-4 col-sm-3 col-md-3 d-flex justify-content-center">
                <div className="img-sec">
                  <img src={Magento} alt="payment mode" />
                </div>
              </div>
              <div className="col-lg-3 col-4 col-sm-3 col-md-3 d-flex justify-content-center">
                <div className="img-sec">
                  <img src={Shopify} alt="payment mode" />
                </div>
              </div>
              <div className="col-lg-3 col-4 col-sm-3 col-md-3 d-flex justify-content-center">
                <div className="img-sec">
                  <img src={Woocommerce} alt="payment mode" />
                </div>
              </div>
              <div className="col-lg-3 col-4 col-sm-3 col-md-3 d-flex justify-content-center">
                <div className="img-sec">
                  <img src={Opencart} alt="payment mode" />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button className="Accept_crypto_now new_common_btn">
                Accept crypto now
              </button>
            </div>
          </div>
        </div>
        <div className="upgrade">
          <div className="container sec-pad text-center">
            <h4 className="common_heading">Upgrade Today, It’s Easy</h4>
            <p className="common_heading_des">
              Join the millions already using CryptoPayMate globally.
            </p>

            <button className="signup new_common_btn mt-4">Singup Now</button>
          </div>
        </div>
      </div> : <></>}

      {showpage ?

        <Footer />

        : <></>}

      {/* Modal Popup */}
      {/* <Modal
        show={show}
        onHide={handleClose}
        centered
        className="get_demo_popup"
      >
        <Modal.Body>
          <div className="get_demo_modal_popup">
            <div className="d-flex flex-column">
              <p className="mb-2">Scan Your QR</p>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={QrCodeImg}
                  alt="qr_img"
                  className="qr_img img-fluid"
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <p className="mb-3">Enter Your OTP</p>

              <div className="otp_input d-flex justify-content-start align-items-center">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-center align-items-center mt-3">
            <Button
              onClick={handleClose}
              className="new_common_btn modal_submit_btn"
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal> */}
      {showpage ? <GetDemoModal show={show} handleClose={handleClose} /> : <></>}
    </>
  );
}

export default LandingPage;
