import bitcoin from "../asset/IMG/cryptocurrency-color_btc.png";
import tether from "../asset/IMG/cryptocurrency-color_usdt.png";
import ethereum from "../asset/IMG/ethereum.png";
import success from "../asset/IMG/success.png";
import cancel from "../asset/IMG/cancel.png";
import pending from "../asset/IMG/pending.png";
import up from "../asset/IMG/up.png";
import down from "../asset/IMG/down.png";
import user from "../asset/IMG/user.png";
import binance from "../asset/IMG/Binance.png";
import dogecoin from "../asset/IMG/Dogecoin.png";
import tron from "../asset/IMG/Tron.png";

export const coins = [
  {
    id: 1,
    img: bitcoin,
    name: "bitcoin",
    coinType: "BTC",
    balance: " 546,75,ooo ",
    value: " 546,75,ooo ",
  },
  {
    id: 2,
    img: tether,
    name: "tether",
    coinType: "USDT",
    balance: " 546,75,ooo ",
    value: " 546,75,ooo ",
  },
  {
    id: 3,
    img: bitcoin,
    name: "Ethereum",
    coinType: "ETH",
    balance: " 546,75,ooo ",
    value: " 546,75,ooo ",
  },
];

export const depositTable = [
  {
    id: 1,
    date: "23",
    month: "oct",
    time: "12:30 pm",
    address: "124yhfg.jei",
    amount: "35,546,OOO",
    status: "sent",
    color: "#4caf50",
    statusImg: success,
    converTo: "BTC",
    otherWallets: "ETH",
  },
  {
    id: 2,
    date: "24",
    month: "oct",
    time: "12:30 pm",
    address: "124yhfg.jei",
    amount: "35,546,OOO",
    status: "pending",
    color: "#cf8d29",
    statusImg: pending,
    converTo: "BTC",
    otherWallets: "ETH",
  },
  {
    id: 1,
    date: "26",
    month: "oct",
    time: "12:30 pm",
    address: "124yhfg.jei",
    amount: "35,546,OOO",
    status: "cancel",
    color: "#EE5F67",

    statusImg: cancel,
    converTo: "BTC",
    otherWallets: "ETH",
  },
];
export const withdrawTable = [
  {
    id: 1,
    date: "23",
    month: "oct",
    time: "12:30 pm",
    address: "124yhfg.jei",
    amount: "35,546,OOO",
    status: "sent",
    color: "#4caf50",
    statusImg: success,
    converTo: "BTC",
    otherWallets: "ETH",
  },
  {
    id: 2,
    date: "24",
    month: "oct",
    time: "12:30 pm",
    address: "124yhfg.jei",
    amount: "35,546,OOO",
    status: "pending",
    color: "#cf8d29",
    statusImg: pending,
    converTo: "BTC",
    otherWallets: "ETH",
  },
  {
    id: 1,
    date: "26",
    month: "oct",
    time: "12:30 pm",
    address: "124yhfg.jei",
    amount: "35,546,OOO",
    status: "cancel",
    color: "#EE5F67",

    statusImg: cancel,
    converTo: "BTC",
    otherWallets: "ETH",
  },
];
export const transferTable = [
  {
    id: 1,
    date: "23",
    month: "oct",
    time: "12:30 pm",
    address: "124yhfg.jei",
    amount: "35,546,OOO",
    arrow: up,
    userImg: user,
    userName: "David",
    status: "sent",
    color: "#4caf50",
    statusImg: success,
  },
  {
    id: 2,
    date: "23",
    month: "oct",
    time: "12:30 pm",
    address: "124yhfg.jei",
    amount: "35,546,OOO",
    arrow: down,
    userImg: user,
    status: "pending",
    userName: "saul",
    color: "#4caf50",
    statusImg: pending,
  },
  {
    id: 3,
    date: "23",
    month: "oct",
    time: "12:30 pm",
    address: "124yhfg.jei",
    amount: "35,546,OOO",
    arrow: up,
    userImg: user,
    userName: "David",
    status: "sent",
    color: "#4caf50",
    statusImg: cancel,
  },
];
export const conversionTable = [
  {
    id: 1,
    date: "23",
    month: "oct",
    time: "12:30 pm",
    amount: "35,546,OOO",
    exchangeRate: "35,546,OOO",
    amountReceived: "35,546,OOO",
    statusImg: success,
    status: "sent",
  },
  {
    id: 2,
    date: "23",
    month: "oct",
    time: "12:30 pm",
    amount: "35,546,OOO",
    exchangeRate: "35,546,OOO",
    amountReceived: "35,546,OOO",
    statusImg: success,
    status: "sent",
  },
  {
    id: 3,
    date: "23",
    month: "oct",
    time: "12:30 pm",
    amount: "35,546,OOO",
    exchangeRate: "35,546,OOO",
    amountReceived: "35,546,OOO",
    statusImg: success,
    status: "sent",
  },
];
export const p2pTable = [
  {
    id: 1,
    date: "23",
    month: "oct",
    time: "12:30 pm",
    amount: "35,546,OOO",
    exchangeRate: "35,546,OOO",
    amountReceived: "35,546,OOO",
    statusImg: success,
    status: "send",
  },
  {
    id: 2,
    date: "23",
    month: "oct",
    time: "12:30 pm",
    amount: "35,546,OOO",
    exchangeRate: "35,546,OOO",
    amountReceived: "35,546,OOO",
    statusImg: success,
    status: "send",
  },
  {
    id: 3,
    date: "23",
    month: "oct",
    time: "12:30 pm",
    amount: "35,546,OOO",
    exchangeRate: "35,546,OOO",
    amountReceived: "35,546,OOO",
    statusImg: success,
    status: "send",
  },
];

export const popularCoin = [
  {
    id: 1,
    img: bitcoin,
    name: "bitcoin",
    code: "btc",
  },
  {
    id: 2,
    img: binance,
    name: "binance",
    code: "btc",
  },
  {
    id: 3,
    img: dogecoin,
    name: "dogecoin",
    code: "Doge",
  },
  {
    id: 4,
    img: tether,
    name: "tether",
    code: "USDT",
  },
  {
    id: 5,
    img: ethereum,
    name: "ethereum",
    code: "btc",
  },
  {
    id: 6,
    img: tron,
    name: "Tron",
    code: "trc",
  },
];

export const allCrypto = [
  {
    id: 1,
    coinImg: bitcoin,
    coinName: "Bitcoin",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
  {
    id: 2,
    coinImg: tether,
    coinName: "tether",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
  {
    id: 1,
    coinImg: ethereum,
    coinName: "ethereum",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
];
export const stableCoin = [
  {
    id: 1,
    coinImg: bitcoin,
    coinName: "Bitcoin",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
  {
    id: 2,
    coinImg: tether,
    coinName: "tether",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
  {
    id: 1,
    coinImg: ethereum,
    coinName: "ethereum",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
];
export const token = [
  {
    id: 1,
    coinImg: bitcoin,
    coinName: "Bitcoin",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
  {
    id: 2,
    coinImg: tether,
    coinName: "tether",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
  {
    id: 1,
    coinImg: ethereum,
    coinName: "ethereum",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
];
export const walletOnly = [
  {
    id: 1,
    coinImg: bitcoin,
    coinName: "Bitcoin",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
  {
    id: 2,
    coinImg: tether,
    coinName: "tether",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
  {
    id: 1,
    coinImg: ethereum,
    coinName: "ethereum",
    code: "BTC",
    paymentFee: "0.5%",
    confirms: "BTc",
    usd: "25984.58",
    btc: "1.ooooooo",
  },
];
