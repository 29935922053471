import React, { useEffect, useState } from "react";
import "../../../../Styles/Dashboard.css";
import icon from "../../../../asset/IMG/Dashboard/icon.png";
import { FaFilter } from "react-icons/fa";
import searchicon from "../../../../asset/IMG/Dashboard/icon-park-outline_search.png";
import bitcoin from "../../../../asset/IMG/Dashboard/cryptocurrency-color_btc.png";
import detaillogo from "../../../../asset/IMG/Dashboard/support-coins-detail-logo.png";

import transactionlogo from "../../../../asset/IMG/Dashboard/Group 61.png";
import receivedlogo from "../../../../asset/IMG/Dashboard/cryptocurrency-color_eth.png";
import cnfmlogo from "../../../../asset/IMG/Dashboard/flat-color-icons_ok.png";
import convertlogo from "../../../../asset/IMG/Dashboard/Group.png";

import binance from "../../../../asset/IMG/Dashboard/binance.png";
import tether from "../../../../asset/IMG/Dashboard/tether.png";
import shortline from "../../../../asset/IMG/Dashboard/secondbox-lite-top.png";
import bigline from "../../../../asset/IMG/Dashboard/left-top-icon.png";
import { BiSolidDownArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { style, position, iconTheme } from "../../../../hooks/UseToast";
import toast from "react-hot-toast";
import { getallassetdatahooks, getuserdatabytoken, sleep } from "../../../../hooks/UseUser";
import { BACKURL } from "../../../../config/env";
import { DateTimeForm } from "../../../../hooks/UseValidation";
import Accountmodal from "../../../Modals/accountmodal"


function Dashboards() {
  const [accountmodal, setAccountmodal] = useState(false);
  const [userToken, setUserToken] = useState();
  const [walletData, setWalletData] = useState("");
  const [allassetdata, setAllAssetData] = useState([]);
  const [allcrytokdata, setAllCrypTokData] = useState([]);
  const [searchalldata, setSearchAllData] = useState("");
  const [accountType, setaccountType] = useState("");
  const [KYCStatus, setKYCStatus] = useState();
  const [searchcryptokdata, setSearchCrypoTokData] = useState("")
  const [recentcoin, setRecentCoin] = useState([]);
  const [supportcoin, setSupportCoin] = useState([])
 
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const UserToken = sessionStorage.getItem("accessToken")
      if (!(UserToken != '' && UserToken != null && UserToken != undefined && UserToken != "undefined")) {
        navigate("/")
        toast.error("Please login first !", {
          position: position.position,
          style: style,
          iconTheme: iconTheme,
        })
        await sleep(1000)
      } else {
        // getAuthToken()
      }
    })();

  }, []);;

  useEffect(() => {
    let UserToken = sessionStorage.getItem("accessToken");
    setUserToken(UserToken)
    if ((UserToken != '' && UserToken != null && UserToken != undefined && UserToken != "undefined")) {
      getData(UserToken)
    }
  }, [userToken, searchalldata]);





  useEffect(() => {
    let UserToken = sessionStorage.getItem("accessToken");
    setUserToken(UserToken);
    if (
      UserToken != "" &&
      UserToken != null &&
      UserToken != undefined &&
      UserToken != "undefined"
    ) {
      // GetuserAccountType(UserToken);
    }
  }, [userToken]);


  const GetuserAccountType = async (token) => {
    try {
      let reqdata = { token: token, filter: "userAccountType" };
      let res = await getuserdatabytoken(reqdata);
      if (res?.data?.success == true) {
        let data = res?.data?.record;
      
        setaccountType(data?.accounttype)
        setKYCStatus(data?.isKYCVerify)
        if(data?.isKYCVerify == null && data?.accounttype === "Merchant" && data?.KYCFile?.length < 0){
          setAccountmodal(true)
        }
      
      }
    } catch (e) {
      console.log("getData_err", e);
    }
  };




  //recent - all
  const getData = async (token) => {
    try {
      console.log("222 enter this api", searchalldata)
      if ((userToken != '' && userToken != null && userToken != undefined && userToken != "undefined")) {
        let res
        if (searchalldata == "") {
          console.log("11 enter this api", searchalldata)
          let reqdata = { filter: "all", search: "" };
          res = await getallassetdatahooks(reqdata);
          let data = res?.data?.record;
          let arr = ["ALL"];
          let filt = await data && data?.length > 0 && data?.map((val, i) => {
            arr.push(val?.name)
          })
          setRecentCoin(arr);
        } else if (searchalldata != "") {
          console.log("enter this api", searchalldata)
          let reqdata = { filter: "all", search: searchalldata };
          res = await getallassetdatahooks(reqdata);
        }
        console.log("resdatastasdfasdf", res?.data)
        if (res?.data?.success == true) {
          let data = res?.data?.record;
          console.log("resdatastasdfasdf", data)
          setAllAssetData(data);

        }
      }
    } catch (e) {
      console.log("getData_err", e)
    }
  }


  //supportcoin - crypto & token
  useEffect(() => {
    async function fetchData() {
      if ((userToken != '' && userToken != null && userToken != undefined && userToken != "undefined")) {
        let res
        if (searchcryptokdata == "") {
          let reqData = { filter: "cryptoken", search: "" }
          res = await getallassetdatahooks(reqData);
          let rec = res?.data?.record
          if (rec?.length > 0) {
            setAllCrypTokData(rec)
            let arr = ["ALL"];
            console.log("recfetchData", rec)
            let filt = await rec && rec?.length > 0 && rec?.map((val, i) => {     
              arr.push(val?.name)
            })
            console.log("")
            if (rec?.length > 0) {
              setSupportCoin(arr);
            } else {
              setSupportCoin([]);
            }

          }
        } else if (searchcryptokdata != "") {
          let reqData = { filter: "cryptoken", search: searchcryptokdata }
          res = await getallassetdatahooks(reqData);
          let rec = res?.data?.record
          setAllCrypTokData(rec)
        }

      }
    };
    fetchData();
  }, [userToken, searchcryptokdata])



  const month = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUGUST", "SEP", "OCT", "NOV", "DEC"];

  const d = new Date();
  let name = month[d.getMonth()];


  

  const Recent = [
    {
      date: 23,
      month: "OCT",
      time: "12 : 30 PM",
      timeLogo: detaillogo,
      transactionNo: "124YHFG.JEI",
      transactionLogo: transactionlogo,
      amount: "35,546,000",
      status: "SENT",
      statusLogo: cnfmlogo,
      conevertTo: "BTC",
      convertLogo: convertlogo,
      receive: "ETH",
      receiveLogo: receivedlogo,
      shortline: shortline,
      bigline: bigline,
    },
    {
      date: 23,
      month: "OCT",
      time: "12 : 30 PM",
      timeLogo: detaillogo,
      transactionNo: "124YHFG.JEI",
      transactionLogo: transactionlogo,
      amount: "35,546,000",
      status: "SENT",
      statusLogo: cnfmlogo,
      conevertTo: "BTC",
      convertLogo: convertlogo,
      receive: "ETH",
      receiveLogo: receivedlogo,
      shortline: shortline,
      bigline: bigline,
    },
  ];

  return (
    <div>
        {accountmodal && <Accountmodal onDismiss={() => setAccountmodal(false)} />}
      <div className="support-coins-section">
        <div className="filter-sec position-relative my-5">
          <div className="d-flex align-items-center">
            <div className="img-section">
              <img src={icon} />
            </div>
            <h4 className="m-0 ps-3">SUPPORT COINS</h4>
          </div>
          <div className="d-flex filter-box-search">
            <div className="filter-search position-relative">
              <div className="filter-box">
                <input
                  type="text"
                  className="form-control search"
                  placeholder="FILTER COIN NAME"
                  onChange={(e) => setSearchCrypoTokData(e?.target?.value)}
                />
              </div>
              <div className="search-icon">
                <img src={searchicon} />
              </div>
            </div>
            <div class="dropdown custom-dropdown-col ms-4">
              <button
                type="button"
                class="btn btn-primary custom-filter-btn new_common_btn d-flex align-items-center"
                data-bs-toggle="dropdown"
              >
                <div className="filter-text-hover-effect d-flex align-items-center"><FaFilter /> <span className="px-1 text-bolder fw-bolder">{searchcryptokdata != "" ? searchcryptokdata : "FILTER"}</span><BiSolidDownArrow size={10} /></div>
              </button>
              <ul class="dropdown-menu" data-popper-placement="bottom-start" >
                <li >
                  {
                    supportcoin && supportcoin?.length > 0 ? supportcoin?.map((val) => {
                      return (
                        <><a class="dropdown-item" onClick={(e) => setSearchCrypoTokData(val)}> {val}
                        </a></>
                      )
                    })
                      : <> <div className="empty_rec">
                        <p>No Records</p>
                      </div>
                      </>}
                </li>

              </ul>
            </div>
          </div>
        </div>
        <div className="support-icons-coins p-2">
          <div className="row">
            {allcrytokdata && allcrytokdata?.length > 0 && allcrytokdata?.map((item) => {
              return (
                <>
                {accountmodal && <Accountmodal onDismiss={() => setAccountmodal(false)} />}
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 col-section mb-3 position-relative overflow-hidden fst-col">
                    <div className="fst-collection overflow-hidden position-relative">
                      <div>
                        <div className="row fst-section coin-collections overflow-hidden position-relative">
                          {/* <div className="top-left-logo position-absolute">
                            <img src={item.shortline} />
                          </div> */}
                          <div className="col-5 border-right d-flex justify-content-center align-items-center fst-sec-br">
                            <div className="fst-sec-border">
                              <div className="d-flex align-items-center bitcoin-bg">
                                <div className="coin-img">
                                <img src={`${BACKURL}/Images/${item?.logoURI}`} className="img-fluid" />
                                </div>

                                <div className="coin-txt">
                                  <p className="coin-name-title">COIN NAME</p>
                                  <p className="coin-name">{item?.name}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 border-right d-flex justify-content-center align-items-center snd-sec-border">
                            <div className="">
                              <div className="">
                                <div className="coin-txt snd-sec">
                                  <p className="coin-name-title code-heading-text">CODE</p>
                                  <p className="coin-name code-heading-text">{item?.symbol}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-3 d-flex justify-content-center align-items-center">
                            <div>
                              <div className="third-sec">
                              <img
                                  src={Recent[0].timeLogo}
                                  className="time-logo-img"
                                />
                                {/* <img src={`${BACKURL}/Images/${item?.logoURI}`} className="img-fluid" /> */}
                              </div>
                            </div>
                          </div>
                          {/* <div className="bottom-right-logo position-absolute">
                            <img src={item.bigline} />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <div className="recent-sections">
        <div className="filter-sec position-relative my-5">
          <div className="d-flex align-items-center">
            <div className="img-section">
              <img src={icon} />
            </div>
            <h4 className="m-0 ps-3">RECENT</h4>
          </div>
          <div className="d-flex filter-box-search">
            <div className="filter-search position-relative">
              <div className="filter-box">
                <input
                  type="text"
                  className="form-control search"
                  placeholder="FILTER COIN NAME"
                  onChange={(e) => setSearchAllData(e?.target?.value)}
                />
              </div>
              <div className="search-icon">
                <img src={searchicon} />
              </div>
            </div>
            <div class="dropdown custom-dropdown-col ms-4">
              <button
                type="button"
                class="btn btn-primary custom-filter-btn new_common_btn d-flex align-items-center justify-content-center"
                data-bs-toggle="dropdown"
              >
                <div className="filter-text-hover-effect d-flex align-items-center justify-content-center"><FaFilter onSelect={(e) => console.log("eeeeeeeee", e)} /> <span className="px-1 text-bolder fw-bolder">{searchalldata != "" ? searchalldata : "FILTER"}</span><BiSolidDownArrow size={10} onSelect={(e) => console.log("eeeeeeeee", e)} /></div>
              </button>
              <ul class="dropdown-menu" data-popper-placement="bottom-start" >
                <li >
                  {
                    recentcoin && recentcoin?.length > 0 ? recentcoin?.map((val) => {
                      return (
                        <><a class="dropdown-item" onClick={(e) => setSearchAllData(val)}> {val}
                        </a></>
                      )
                    })
                      :
                      <>  <div className="empty_rec">
                        <p>No Records</p>
                      </div>
                      </>

                  }
                </li>
              </ul>
            </div>
          </div>
        </div>

        {allassetdata && allassetdata?.length > 0 && allassetdata?.map((item) => {
          return (
            <>
              <div className="recent-table overflow-hidden mb-4">
                <div class="table-responsive">
                  <table
                    class="table m-0 p-0 position-relative table-line"
                    style={{ height: "150px" }}
                  >

                    <tr className="">
                      <td rowspan="2" className="bdr-right text-white">
                        <div className="fst-sec-border d-flex justify-content-center">
                          <div className="recent-date-section d-flex align-items-center justify-content-center bitcoin-bg">
                            <div className="fst-sec position-relative">
                              <div className="text-danger"></div>
                              <h5 className="date px-3 py-2">{(DateTimeForm(item?.createdAt, true, false, false)).split('/')[0]}</h5>
                              <p className="p-2 month">{month[new Date(item?.createdAt)?.getMonth()]}</p>
                            </div>

                            <div>
                              <div className="d-flex justify-content-center align-items-center px-3 py-2">
                                <img
                                  src={Recent[0].timeLogo}
                                  className="time-logo-img"
                                />
                                <p className="ps-2 pb-2 time">{DateTimeForm(new Date(item?.createdAt), false, true, true)}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="head-title bdr-right bdr-head-btm">
                        COINNAME
                      </td>
                      <td className="head-title bdr-right bdr-head-btm">
                        SYMBOL
                      </td>
                      <td className="head-title bdr-right bdr-head-btm">
                        IMAGE
                      </td>
                      {/* <td className="head-title bdr-right bdr-head-btm">
                        CONVERT TO
                      </td>
                      <td className="head-title bdr-head-btm"
  
                      >
                        RECEIVE IN OTHER WALLETS
                      </td> */}
                    </tr>
                    <tr>
                      <td className="bdr-right">
                        <div className="d-flex justify-content-center align-items-center">
                          <img src={item.transactionLogo} />
                          <span className="ms-2 value">
                            {item?.name}
                          </span>
                        </div>
                      </td>
                      <td className="bdr-right">
                        <span className="value d-flex justify-content-center ">
                          {item?.symbol}
                        </span>
                      </td>
                      <td className="bdr-right">
                        <div className="d-flex justify-content-center">
                          <img src={item.statusLogo} className="i" />
                          {/* <span className="ms-2 value table-custom-value-dash">{item.status}</span> */}
                          <img src={`${BACKURL}/Images/${item?.logoURI}`} className="img-fluid image_inside_tab" />
                        </div>
                      </td>
                      {/* <td className="bdr-right">
                        <div className="d-flex justify-content-center">
                          <img src={item.convertLogo} />
                          <span className="ms-2 value table-custom-value-dash">{item.conevertTo}</span>
                        </div>
                      </td>
                      <td className="position-relative" style={{ minWidth: '200px' }}>
                        <div className="d-flex justify-content-center">
                          <img src={item.receiveLogo} />
                          <span className="ms-2 value table-custom-value-dash">{item.receive}</span>
                        </div>

                      </td> */}
                    </tr>
                  </table>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Dashboards;
