import React from "react";
import historyImg from "../../asset/IMG/historyimg.png";

const WalletTop = ({ name }) => {
  return (
    <div className="walletTop z-2">
      <p className="titleP common_header">{name}</p>
      <p className="subtitleP">
        Discover hundreds of merchants who <br />
        accept payments through <span>COINPAYMATE</span>{" "}
      </p>

      {/* {name === "History" && (
        <div className="historyAvatar">
          <img src={historyImg} alt="History Avatar" className="img-fluid" />
        </div>
      )} */}
    </div>
  );
};

export default WalletTop;
