import React, { useEffect, useState } from "react";
import { coins } from "../../Data/data";
import dollar from "../../asset/IMG/grey-dollar.png";
import tableTop from "../../asset/IMG/table-top.png";
import tableBot from "../../asset/IMG/table-bot.png";
import { BACKURL } from "../../config/env";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { Link } from "react-router-dom";
import { ConvertusdPrice } from "../../hooks/UseBackend";


const FilterCoins = (props) => {
  const [WalletData, setWalletData] = useState([]);
  const [price, setprice] = useState([]);
  console.log("price datasgal", price)
  const [selsymbol, setSelSymbol] = useState([{index : 0, symbol : "USD"}, {index : 1, symbol : "BTC"},
  {index : 2, symbol : "USD"}, {index : 3, symbol : "USD"}]);
  console.log("selsymbogal", selsymbol)
  useEffect(() => {
    if (props) {
      setWalletData(props?.walletdata);
    }
  }, [props]);

  useEffect(() => {
    console.log('WalletData_WalletData', WalletData);
    async function fetchData() {
      if (WalletData?.length > 0) {
        let data = [];
        if(WalletData && WalletData?.length > 0) {
          await Promise.all(WalletData?.map(async(val, i) => {
            console.log('walletdata_val', val, i);
            if (val?.symbol) {
             let price = await usdConvert(val?.symbol, "USD", val?.balance);
             console.log('valsdfasdfasd', price, val?.symbol);
             let pushdata = { "index" : i, "price" : price};
             console.log("pushdatasssss", pushdata)
             data.push(pushdata)
            }
          }));
          console.log('price datsssss pushdatasssss', data);
          if(data?.length > 0){
            console.log('111 price datsssss pushdatasssss', data);
        
            data.sort((a, b) => a["index"] - b["index"]);
           console.log('asdfasdfasdfasdf', data)
            setprice(data)

          }
        }


      }
    }
    fetchData()

  }, [props, WalletData]);
  

  const usdConvert = async (from, to, balance) => {

    var data = await ConvertusdPrice(from, to);
    let price = parseFloat(Object.values(data)[0]) * parseFloat(balance);
    console.log("datassssssssss", from, to, Object.values(data)[0], parseFloat(Object.values(data)[0]),parseFloat(balance),parseFloat(Object.values(data)[0]) * parseFloat(balance),price);
    return price;

  }


  const SelectSymbol = async (ind, symbol) => {
    try {
      let data = [...selsymbol];
      console.log('selected symbol data', selsymbol);
      let checkstatus = true;
      if (selsymbol?.length > 0) {
        data && data?.map((val) => {
          console.log("enter this selesy splitdatasgal", val, val["index"] == ind);
          if (val["index"] == ind) {
            console.log('asdsfadfsd')
            if (checkstatus) {
              val['symbol'] = symbol
              checkstatus = false;
            }
          }
        });
        if (selsymbol?.length > 0) {
          if (checkstatus) {
            console.log('111 asdsfadfsd')
            let spiritdata = { "index": ind, "symbol": symbol };
            data.push(spiritdata)
            console.log("splitdatasgal", spiritdata)
          }
        }
      } else {
        console.log("000 splitdatasgal")
        data.push({ "index": ind, "symbol": symbol })
      }
      console.log("splitdatasgal", data);
      setSelSymbol(data)
    } catch (e) {
      console.log('SelectSymbol_err', e)
    }
  }

  const SelectPrice = async (ind, selsymbol, symbol, balance) => {
    try {
      let data = [...price];
      console.log('selected symbol data', price);
      let checkstatus = true;
      if (data && data?.length > 0) {
        await Promise.all(data?.map(async(val) => {
          console.log("enter this selesy splitdatasgal", val, val["index"] == ind);
          if (val["index"] == ind) {
            console.log('asdsfadfsd', val)
            if (checkstatus) {
              let pricedata = await usdConvert(selsymbol, symbol, balance);
              console.log("pricedatsssss", pricedata)
              val['price'] = pricedata;
              checkstatus = false;
            }
          }
        }));
        if (price?.length > 0) {
          if (checkstatus) {
            console.log('111 asdsfadfsd')
            let spiritdata = { "index": ind, "price":  balance};
            data.push(spiritdata)
            console.log("splitdatasgal", spiritdata)
          }
        }
      } else {
        console.log("000 splitdatasgal")
        data.push({ "index": ind, "symbol": balance })
      }
      console.log("splitdatasgal", data);
      setprice(data)
    } catch (e) {
      console.log("SelectPrice_err", e)
    }
  }
  return (
    <div className="tableContainer ">
      {console.log("WalletDatsgal", price)}
      {WalletData &&
        WalletData?.length > 0 &&
        WalletData.map((item, ind) => (
          <div key={item?._id}>
            <div className="table-responsive wl_tab_cont">
              <table class="table table-bordered table_wallet">
                <tbody>
                  <tr className="headerRow">
                    <td rowSpan={2} className="firstTd">
                      {/* <div className="position-absolute table_toprec">
                    <img
                      src={tableTop}
                      alt="rectangle"
                      className="img-fluid"
                    />
                  </div> */}
                      <div className="coin-type d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center coin-div">
                          <div className="img-div">
                            <img
                              src={`${BACKURL}/Images/${item?.logoURI}`}
                              // alt={item.name}
                              className="img-fluid"
                            />
                          </div>
                          <div className="text-div">
                            <p>{item?.name}</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="tableTitle">code</td>
                    <td className="tableTitle">balance</td>
                    <td
                      className="tableTitle d-flex align-items-center justify-content-center gap-3"
                      style={{ borderLeft: "none" }}
                    >
                      value{" "}
                      <div className="dropdown">
                        <button
                          className="btn commonBtn dropdown-toggle p-1"
                          style={{
                            fontSize: "10px",
                            background: "#65fce1",
                            fontWeight: "bold",
                          }}
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          
                        >
                          {console.log('selectedsymbol', selsymbol[ind],selsymbol[ind] && Object.values(selsymbol[ind]) !=undefined ? Object.values(selsymbol[ind])[1] : "")}
                          {selsymbol[ind] && Object.values(selsymbol[ind]) !=undefined ? Object.values(selsymbol[ind])[1] : ""}
                        </button>
                        <ul
                          className="dropdown-menu scroll_style"
                          data-popper-placement="top-start"
                        >
                          {(item?.symbol).toLowerCase() != "btc" && <li> 
                            <a className="dropdown-item"  onClick={() => { SelectSymbol(ind, "BTC"); SelectPrice(ind, item?.symbol, "BTC", item?.balance)}}>
                              BTC
                            </a>
                          </li>}
                         {(item?.symbol).toLowerCase() != "usd" && <li>
                         <a className="dropdown-item"  onClick={() => { SelectSymbol(ind, "USD"); SelectPrice(ind, item?.symbol, "USD", item?.balance)}}>
                              usd
                            </a>
                          </li>}
                        </ul>
                      </div>{" "}
                    </td>
                    <td className="tableTitle">Actions</td>
                  </tr>
                  <tr className="wl_tab_cont_row">
                    <td className="coinText">{item?.symbol}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center dollarValue">
                        <img src={dollar} alt="dollar" className="img-fluid" />
                        <p className="dollarValueP">{item?.balance}</p>
                      </div>
                    </td>
                    <td className="table_lastSec">
                      {/* <div className="table_botrec">
                    <img
                      src={tableBot}
                      alt="rectanble"
                      className="img-fluid"
                    />
                  </div> */}
                      <div className="d-flex align-items-center justify-content-center dollarValue">
                        <img src={dollar} alt="dollar" className="img-fluid" />
                        
                        {price && price?.length > 0 && price?.map((val) => {
                          {console.log("pricdatasdfas", price, price[ind], Object.values(price[ind])[1])}
                          if(val["index"] == ind)
                          return(
                            <p className="dollarValueP">{price[ind] && Object.values(price[ind]) !=undefined ? Object.values(price[ind])[1] : ""}</p>
                          )
                          
                        })
                        
                       }
                      </div>
                    </td>
                    <td>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title="BTC Options"
                        className="wl_drp_dwn_btn"
                      >
                        <Dropdown.Item href="javascript:void(0)">
                          <Link to={`/deposits/${item?._id}`}>Deposit / Receive</Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="javascript:void(0)">
                        <Link to={`/withdraw/${item?._id}`}>Send / Withdraw</Link></Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item href="javascript:void(0)"><Link to="/history" state={{ symbol: item?.symbol, key : "first", _id : item?._id}}>Deposit History</Link></Dropdown.Item>
                        <Dropdown.Item href="javascript:void(0)"><Link to="/history" state={{ symbol:  item?.symbol, key : "second", _id : item?._id}}>Withdraw History</Link></Dropdown.Item>
                        <Dropdown.Item href="javascript:void(0)"><Link to="/history" state={{ symbol: item?.symbol, key : "third", _id : item?._id}}>Transfer History</Link></Dropdown.Item>
                      </DropdownButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
    </div>
  );
};

export default FilterCoins;
