import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import OtpInput from "react-otp-input";
import Modal from "react-bootstrap/Modal";
import QrCodeImg from "../../asset/IMG/qrcode.png";

function GetDemoModal({show , handleClose}) {

  const [otp, setOtp] = useState("");

  return (
    <div>
      {/* Modal Popup */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="get_demo_popup"
      >
        <Modal.Body>
          <div className="get_demo_modal_popup">
            <div className="d-flex flex-column">
              <p className="mb-2">Scan Your QR</p>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={QrCodeImg}
                  alt="qr_img"
                  className="qr_img img-fluid"
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <p className="mb-3">Enter Your OTP</p>

              <div className="otp_input d-flex justify-content-start align-items-center">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-center align-items-center mt-3">
            <Button
              onClick={handleClose}
              className="new_common_btn modal_submit_btn"
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GetDemoModal;
